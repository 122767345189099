define("stock-control-front/validators/existences-for-sale", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const ExistencesForSale = _base.default.extend({
    intl: Ember.inject.service('intl'),

    validate(value, options, model) {
      let quantity = model.get('saleTotalQuantity');
      let saleUnitId = model.get('unit').get('id');
      let productId = model.get('productToSell').get('product').get('id');

      if (quantity > 0 && saleUnitId !== undefined && productId !== undefined) {
        let productExistences = model.get('productToSell').get('product').get('existences');
        const saleExistence = productExistences.filter(productExistence => productExistence.get('unit').get('id') == saleUnitId);
        let existence = 0;

        if (saleExistence.length > 0) {
          existence = saleExistence.get('firstObject').get('existence');
        }

        if (existence > 0 && existence >= quantity) {
          return true;
        } else {
          return this.get('intl').t('sale.unit.stockEmpty.message.label');
        }
      } else {
        return true;
      }
    }

  });

  ExistencesForSale.reopenClass({
    /**
     * Define attribute specific dependent keys for your validator
     *
     * [
     * 	`model.array.@each.${attribute}` --> Dependent is created on the model's context
     * 	`${attribute}.isValid` --> Dependent is created on the `model.validations.attrs` context
     * ]
     *
     * @param {String}  attribute   The attribute being evaluated
     * @param {Unknown} options     Options passed into your validator
     * @return {Array}
     */
    getDependentsFor()
    /* attribute, options */
    {
      return [];
    }

  });
  var _default = ExistencesForSale;
  _exports.default = _default;
});