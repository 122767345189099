define("stock-control-front/services/menu-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    adminLink: function () {
      return {
        icon_class: 'icon-home',
        title: 'home.label',
        linkTo: 'admin',
        isActive: false
      };
    },
    organizationsIndexLink: function () {
      return {
        icon_class: 'fa fa-users',
        title: 'organization.list.label',
        linkTo: 'admin.organizations',
        isActive: false
      };
    },
    clientsAddLink: function (params) {
      return {
        icon_class: 'fa fa-plus-square',
        title: 'client.menu.add.label',
        isActive: false,
        linkTo: 'admin.organizations.clients.add',
        params: params
      };
    },
    clientsListLink: function (params) {
      return {
        icon_class: 'icon-moon-file-text',
        title: 'client.menu.list.label',
        isActive: false,
        linkTo: 'admin.organizations.clients',
        params: params
      };
    },
    clientsAccountStatementLink: function (params) {
      return {
        icon_class: 'icon-moon-account-statement',
        title: 'client.menu.accountStatement.label',
        isActive: false,
        linkTo: 'admin.organizations.clients.account_statement',
        params: params
      };
    },
    clientsPaymentLink: function (params) {
      return {
        icon_class: 'icon-moon-payment',
        title: 'client.menu.payment.label',
        isActive: false,
        linkTo: 'admin.organizations.clients.payment',
        params: params
      };
    },
    clientsBalanceLink: function (params) {
      return {
        icon_class: 'icon-moon-balance',
        title: 'client.menu.balance.label',
        isActive: false,
        linkTo: 'admin.organizations.clients.balance',
        params: params
      };
    },
    clientsIndexLink: function (params) {
      return {
        icon_class: 'icon-moon-client',
        title: 'client.list.label',
        isActive: false,
        subMenus: {
          add: this.clientsAddLink(params),
          index: this.clientsListLink(params),
          payment: this.clientsPaymentLink(params),
          account_statement: this.clientsAccountStatementLink(params),
          balance: this.clientsBalanceLink(params)
        }
      };
    },
    saleNotesAddLink: function (params) {
      return {
        icon_class: 'fa fa-plus-square',
        title: 'saleNote.menu.add.label',
        isActive: false,
        linkTo: 'admin.organizations.sale_notes.add',
        params: params
      };
    },
    saleNotesListLink: function (params) {
      return {
        icon_class: 'icon-moon-file-text',
        title: 'saleNote.menu.list.label',
        isActive: false,
        linkTo: 'admin.organizations.sale_notes',
        params: params
      };
    },
    saleNotesIndexLink: function (params) {
      return {
        icon_class: 'icon-moon-invoice',
        title: 'sale.list.label',
        isActive: false,
        subMenus: {
          add: this.saleNotesAddLink(params),
          index: this.saleNotesListLink(params)
        }
      };
    },
    productsLink: function (params) {
      return {
        icon_class: 'fa fa-clone',
        title: 'product.list.label',
        linkTo: 'admin.organizations.products',
        isActive: false,
        params: params
      };
    },
    unitsLink: function (params) {
      return {
        icon_class: 'fa fa-cogs',
        title: 'unit.menu.list.label',
        linkTo: 'admin.organizations.units',
        isActive: false,
        params: params
      };
    },
    suppliersAddLink: function (params) {
      return {
        icon_class: 'fa fa-plus-square',
        title: 'supplier.menu.add.label',
        isActive: false,
        linkTo: 'admin.organizations.suppliers.add',
        params: params
      };
    },
    suppliersListLink: function (params) {
      return {
        icon_class: 'icon-moon-file-text',
        title: 'supplier.menu.list.label',
        isActive: false,
        linkTo: 'admin.organizations.suppliers',
        params: params
      };
    },
    suppliersAccountStatementLink: function (params) {
      return {
        icon_class: 'icon-moon-account-statement',
        title: 'supplier.menu.accountStatement.label',
        isActive: false,
        linkTo: 'admin.organizations.suppliers.account_statement',
        params: params
      };
    },
    suppliersPaymentLink: function (params) {
      return {
        icon_class: 'icon-moon-payment',
        title: 'supplier.menu.payment.label',
        isActive: false,
        linkTo: 'admin.organizations.suppliers.payment',
        params: params
      };
    },
    suppliersIndexLink: function (params) {
      return {
        icon_class: 'icon-moon-truck',
        title: 'supplier.list.label',
        isActive: false,
        subMenus: {
          add: this.suppliersAddLink(params),
          index: this.suppliersListLink(params),
          payment: this.suppliersPaymentLink(params),
          account_statement: this.suppliersAccountStatementLink(params)
        }
      };
    },
    purchasesAddLink: function (params) {
      return {
        icon_class: 'fa fa-plus-square',
        title: 'purchase.menu.add.label',
        isActive: false,
        linkTo: 'admin.organizations.purchases.add',
        params: params
      };
    },
    purchasesListLink: function (params) {
      return {
        icon_class: 'icon-moon-file-text',
        title: 'purchase.menu.list.label',
        isActive: false,
        linkTo: 'admin.organizations.purchases',
        params: params
      };
    },
    purchasesIndexLink: function (params) {
      return {
        icon_class: 'icon-moon-bill',
        title: 'purchase.list.label',
        isActive: false,
        subMenus: {
          add: this.purchasesAddLink(params),
          index: this.purchasesListLink(params)
        }
      };
    },
    productsInventory: function (params) {
      return {
        icon_class: 'icon-moon-inventory-reports-products',
        title: 'inventory.reports.products.label',
        isActive: false,
        linkTo: 'admin.organizations.inventory.products_inventory',
        params: params
      };
    },
    inventoryValuation: function (params) {
      return {
        icon_class: 'icon-moon-inventory-valuation',
        title: 'inventory.reports.valuation.label',
        isActive: false,
        linkTo: 'admin.organizations.inventory.inventory_valuation',
        params: params
      };
    },
    inventoryIndexLink: function (params) {
      return {
        icon_class: 'icon-moon-clipboard-edit2',
        title: 'inventory.reports.label',
        isActive: false,
        subMenus: {
          products_inventory: this.productsInventory(params),
          inventory_valuation: this.inventoryValuation(params)
        }
      };
    }
  });

  _exports.default = _default;
});