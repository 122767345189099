define("stock-control-front/templates/components/select-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Jk5vtnTN",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[7,\"select\"],[11,\"class\",\"form-control m-input m-input--square\"],[12,\"onchange\",[29,\"action\",[[24,0,[]],\"updateSelect\"],[[\"value\"],[\"target.value\"]]]],[12,\"disabled\",[23,\"disabled\"]],[9],[0,\"\\n  \"],[7,\"option\"],[11,\"selected\",\"\"],[11,\"value\",\"\"],[9],[1,[23,\"noSelected\"],false],[10],[0,\"\\n\"],[4,\"each\",[[25,[\"data\"]]],null,{\"statements\":[[0,\"    \"],[7,\"option\"],[12,\"value\",[29,\"get\",[[24,1,[]],[25,[\"optionKey\"]]],null]],[12,\"selected\",[29,\"eq\",[[29,\"get\",[[24,1,[]],[25,[\"optionKey\"]]],null],[25,[\"selected\"]]],null]],[9],[1,[29,\"get\",[[24,1,[]],[25,[\"optionValue\"]]],null],false],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "stock-control-front/templates/components/select-input.hbs"
    }
  });

  _exports.default = _default;
});