define("stock-control-front/admin/organizations/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    navigationMenuService: Ember.inject.service('navigation-menu'),

    activate() {
      this.get('navigationMenuService').setMenuItems(this.get('routeName'));
    },

    model: function () {
      return this.store.query('organization', {});
    },

    renderTemplate() {
      this.render({
        into: 'admin'
      });
    }

  });

  _exports.default = _default;
});