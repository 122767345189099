define("stock-control-front/models/transaction", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    concept: _emberData.default.attr('string'),
    amount: _emberData.default.attr('number'),
    debit: _emberData.default.attr('number'),
    credit: _emberData.default.attr('number'),
    balance: _emberData.default.attr('number'),
    transactionDate: _emberData.default.attr('date'),
    reference: _emberData.default.attr('string'),
    referenceType: _emberData.default.attr('string'),
    referenceId: _emberData.default.attr('string')
  });

  _exports.default = _default;
});