define("stock-control-front/templates/components/sale-note-register", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "4eyLtZtH",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[29,\"and\",[[29,\"get\",[[25,[\"saleNote\",\"validations\"]],\"isValid\"],null],[25,[\"showPreview\"]]],null]],null,{\"statements\":[[0,\"  \"],[1,[29,\"sale-note-preview\",null,[[\"saleNote\",\"showSaleNoteForm\",\"saveSaleNote\"],[[25,[\"saleNote\"]],[29,\"action\",[[24,0,[]],\"showSaleNoteForm\"],null],[29,\"action\",[[24,0,[]],\"saveSaleNote\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[29,\"sale-note-form\",null,[[\"saleNote\",\"units\",\"products\",\"organization\",\"showSaleNotePreview\"],[[25,[\"saleNote\"]],[25,[\"units\"]],[25,[\"products\"]],[25,[\"organization\"]],[29,\"action\",[[24,0,[]],\"showSaleNotePreview\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "stock-control-front/templates/components/sale-note-register.hbs"
    }
  });

  _exports.default = _default;
});