define("stock-control-front/serializers/client", ["exports", "stock-control-front/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    serialize() {
      var json = this._super(...arguments);

      json.data.attributes.client_addresses = [];
      json.data.relationships.addresses.data.forEach(function (item) {
        json.data.attributes.client_addresses.push({
          "address_id": item.id
        });
      });
      delete json.data.relationships.addresses;
      return json;
    }

  });

  _exports.default = _default;
});