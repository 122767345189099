define("stock-control-front/adapters/application", ["exports", "ember-data", "stock-control-front/config/environment", "ember-simple-auth/mixins/data-adapter-mixin", "ember-cli-file-saver/mixins/adapter-arraybuffer-mixin"], function (_exports, _emberData, _environment, _dataAdapterMixin, _adapterArraybufferMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPIAdapter.extend(_dataAdapterMixin.default, _adapterArraybufferMixin.default, {
    namespace: 'api',
    host: _environment.default.host,
    session: Ember.inject.service('session'),

    authorize(xhr) {
      let {
        access_token
      } = this.get('session.data.authenticated');

      if (access_token !== undefined && access_token !== "") {
        xhr.setRequestHeader('Authorization', "Bearer ".concat(access_token));
      }
    }

  });

  _exports.default = _default;
});