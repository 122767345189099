define("stock-control-front/components/sale-note-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['row'],
    actions: {
      showSaleNoteForm: function () {
        this.showSaleNoteForm();
      },
      saveNote: function () {
        this.saveSaleNote();
      }
    },
    totalAmount: Ember.computed(function () {
      let sales = this.get('saleNote').get('sales');
      return sales.reduce((previous, sale) => {
        return sale.get('productToSell').get('total') + previous;
      }, 0);
    })
  });

  _exports.default = _default;
});