define("stock-control-front/admin/organizations/suppliers/route", ["exports", "stock-control-front/mixins/url-validation"], function (_exports, _urlValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_urlValidation.default, {
    navigationMenuService: Ember.inject.service('navigation-menu'),

    activate() {
      var model = this.modelFor(this.routeName);
      this.get('navigationMenuService').setMenuItems(this.get('routeName'), model.query.organization);
    },

    model: function (params) {
      return this.store.query('supplier', {
        organization: params.organization_id,
        page: 1,
        page_size: 10
      });
    },
    actions: {
      refreshSuppliers: function () {
        this.refresh();
      }
    },

    renderTemplate() {
      this.render({
        into: 'admin'
      });
    },

    resetController: function (controller, isExiting, transition) {
      if (isExiting) {
        const path = transition.to.name.replace("index", "");

        if (this.get('routeName').indexOf(path) !== -1) {
          this.get('navigationMenuService').setMenuItems(transition.to.name);
        }
      }
    }
  });

  _exports.default = _default;
});