define("stock-control-front/admin/organizations/clients/balance/route", ["exports", "stock-control-front/mixins/url-validation", "moment"], function (_exports, _urlValidation, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_urlValidation.default, {
    navigationMenuService: Ember.inject.service('navigation-menu'),
    queryParams: {
      date: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      }
    },

    activate() {
      let model = this.modelFor('admin.organizations.clients');
      this.get('navigationMenuService').setMenuItems(this.get('routeName'), model.query.organization);
    },

    model: function (params) {
      const organizationId = this.paramsFor('admin.organizations.clients').organization_id;
      const pageSize = this.controllerFor(this.get('routeName')).get('pageSize');
      return Ember.RSVP.hash({
        clientBalances: this.store.query('client-balance', {
          organization: organizationId,
          to_date: params.date,
          page: params.page,
          page_size: pageSize
        })
      });
    },
    resetController: function (controller, isExiting, transition) {
      if (isExiting) {
        controller.set('date', (0, _moment.default)(new Date()).format('YYYY-MM-DD'));
        const path = transition.to.name.replace('.index', '');

        if (this.get('routeName').indexOf(path) > -1) {
          let model = this.modelFor('admin.organizations.clients');
          this.get('navigationMenuService').setMenuItems(transition.to.name, model.query.organization);
        }
      }
    },

    renderTemplate() {
      this.render({
        into: 'admin'
      });
    }

  });

  _exports.default = _default;
});