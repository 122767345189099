define("stock-control-front/adapters/supplier-payment", ["exports", "stock-control-front/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    pathForType: function () {
      return 'payments';
    }
  });

  _exports.default = _default;
});