define("stock-control-front/components/pagination-buttons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    firstPage: 1,
    lastPage: Ember.computed.or('meta.pagination.last.number', 'meta.pagination.self.number'),
    currentPage: Ember.computed.alias('meta.pagination.self.number'),
    maxPagesInList: 5,
    currentPositionInList: Ember.computed('currentPage', function () {
      return (this.get('currentPage') - 1) % this.get('maxPagesInList');
    }),
    from: Ember.computed('currentPage', 'currentPositionInList', function () {
      return this.get('currentPage') - this.get('currentPositionInList');
    }),
    to: Ember.computed('from', 'lastPage', function () {
      if (this.get('from') + this.get('maxPagesInList') - 1 <= this.get('lastPage')) {
        return this.get('from') + this.get('maxPagesInList') - 1;
      } else {
        return this.get('lastPage');
      }
    }),
    showPreviousPagesButton: Ember.computed('from', 'firstPage', function () {
      return this.get('from') > this.get('firstPage');
    }),
    showNextPagesButton: Ember.computed('to', 'lastPage', function () {
      return this.get('to') < this.get('lastPage');
    }),
    firstPreviousRangePage: Ember.computed('from', function () {
      return this.get('from') - this.get('maxPagesInList');
    }),
    firstNextRangePage: Ember.computed('to', function () {
      return this.get('to') + 1;
    }),
    page_size: 10,
    pages: Ember.computed('meta.pagination.self.number', function () {
      const pageNumber = this.get('lastPage');

      if (!pageNumber) {
        return [];
      }

      let pages = [];

      for (let n = this.get('from'); n <= this.get('to'); n++) {
        pages.push(n);
      }

      return pages;
    }),
    actions: {
      changePage: function (page) {
        if (page != this.get('currentPage')) {
          this.findRecords({
            page: page,
            page_size: this.get('page_size')
          });
        }
      }
    }
  });

  _exports.default = _default;
});