define("stock-control-front/templates/components/menu-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "YQGE6P3b",
    "block": "{\"symbols\":[\"subMenu\",\"key\",\"&default\"],\"statements\":[[4,\"if\",[[25,[\"item\",\"subMenus\"]]],null,{\"statements\":[[0,\"  \"],[7,\"a\"],[11,\"class\",\"m-menu__link m-menu__toggle\"],[11,\"role\",\"button\"],[9],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"m-menu__item-here\"],[9],[10],[0,\"\\n    \"],[7,\"i\"],[12,\"class\",[30,[\"m-menu__link-icon \",[25,[\"item\",\"icon_class\"]]]]],[9],[10],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"m-menu__link-text\"],[9],[1,[29,\"t\",[[25,[\"item\",\"title\"]]],null],false],[10],[0,\"\\n    \"],[7,\"i\"],[11,\"class\",\"m-menu__ver-arrow fa fa-angle-right\"],[9],[10],[0,\"\\n  \"],[3,\"action\",[[24,0,[]],\"showSubItems\"]],[10],[0,\"\\n\\n  \"],[7,\"div\"],[11,\"class\",\"m-menu__submenu\"],[9],[0,\"\\n    \"],[7,\"span\"],[11,\"class\",\"m-menu__arrow\"],[9],[10],[0,\"\\n    \"],[7,\"ul\"],[11,\"class\",\"m-menu__subnav\"],[9],[0,\"\\n\"],[4,\"each\",[[29,\"-each-in\",[[25,[\"item\",\"subMenus\"]]],null]],null,{\"statements\":[[0,\"        \"],[1,[29,\"menu-link\",null,[[\"item\"],[[24,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"  \\n\"]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[25,[\"item\",\"params\"]]],null,{\"statements\":[[4,\"link-content\",[[25,[\"item\",\"linkTo\"]],[25,[\"item\",\"params\"]]],[[\"item\"],[[25,[\"item\"]]]],{\"statements\":[],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"link-content\",[[25,[\"item\",\"linkTo\"]]],[[\"item\"],[[25,[\"item\"]]]],{\"statements\":[],\"parameters\":[]},null]],\"parameters\":[]}]],\"parameters\":[]}],[0,\"\\n\"],[15,3],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "stock-control-front/templates/components/menu-link.hbs"
    }
  });

  _exports.default = _default;
});