define("stock-control-front/components/purchase-invoices-list", ["exports", "ember-cp-validations", "stock-control-front/utils/filter-dates-validations", "moment"], function (_exports, _emberCpValidations, _filterDatesValidations, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'filter.startDate': _filterDatesValidations.startDate,
    'filter.endDate': _filterDatesValidations.endDate
  });

  var _default = Ember.Component.extend(Validations, {
    store: Ember.inject.service(),
    page_size: 10,
    didValidate: false,
    hasBeenFiltered: false,
    loadedList: false,

    init() {
      this._super(...arguments);

      this.get('store').query('purchaseInvoice', {
        organization: this.get('organization').get('id')
      }).then(purchaseInvoices => {
        Ember.set(this, 'loadedList', true);
        Ember.set(this, 'purchaseInvoices', purchaseInvoices);
      });
      Ember.defineProperty(this, 'filter', Ember.computed(() => {
        return {
          startDate: null,
          endDate: null
        };
      }));
      Ember.defineProperty(this, 'today', Ember.computed(() => {
        return new Date();
      }));
    },

    actions: {
      setSupplier: function (selectedSupplier) {
        if (selectedSupplier !== undefined && selectedSupplier !== null) {
          Ember.set(this, 'selectedSupplier', selectedSupplier);
          Ember.set(this, 'supplier_id', selectedSupplier.id);
        } else {
          Ember.set(this, 'supplier_id', null);
          Ember.set(this, 'selectedSupplier', null);
        }
      },
      doSearch: function (term) {
        return new Ember.RSVP.Promise((resolve, reject) => {
          this._search = Ember.run.debounce(this, this._performSearch, term, resolve, reject, 300);
        });
      },
      findSuppliers: function (resolve, reject, params) {
        const organization = this.get('purchaseInvoices').query.organization;
        return this.get('store').query('supplier', {
          organization: organization,
          name: params.name
        }).then(suppliers => {
          let suppliersList = suppliers.map(supplier => {
            return {
              id: supplier.get('id'),
              name: supplier.get('name')
            };
          });
          resolve(suppliersList);
        }, reject);
      },
      filterPurchaseInvoices: function () {
        if (this.get('validations.isValid')) {
          this.send('findRecords', {}, () => {
            Ember.set(this, 'hasBeenFiltered', true);
          });
        } else {
          Ember.set(this, 'didValidate', true);
        }
      },
      findRecords: function (params, callback) {
        callback = callback || function () {};

        params.organization = this.get('organization').get('id');
        ['supplier_id'].forEach(key => {
          if (Ember.get(this, key) !== undefined && Ember.get(this, key) !== null && Ember.get(this, key) !== "") {
            params[key] = Ember.get(this, key);
          }
        });

        if (this.isValidDate(Ember.get(this, "filter.startDate")) && this.isValidDate(Ember.get(this, "filter.endDate"))) {
          params['start_date'] = (0, _moment.default)(Ember.get(this, 'filter.startDate')).format('YYYY-MM-DD');
          params['end_date'] = (0, _moment.default)(Ember.get(this, 'filter.endDate')).format('YYYY-MM-DD');
        }

        this.get('store').query('purchaseInvoice', params).then(purchaseInvoices => {
          Ember.set(this, 'purchaseInvoices', purchaseInvoices);
          callback();
        });
      }
    },

    _performSearch(term, resolve, reject) {
      this.send('findSuppliers', resolve, reject, {
        name: term
      });
    },

    isValidDate: function (date) {
      return date !== null && date !== undefined;
    }
  });

  _exports.default = _default;
});