define("stock-control-front/validators/unique-product-name", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const UniqueProductName = _base.default.extend({
    store: Ember.inject.service('store'),
    intl: Ember.inject.service('intl'),

    validate(value, options, model) {
      return this.get('store').query('product', {
        filter: {
          organization: model.get('organization').get('id'),
          name: value.trim()
        }
      }).then(result => {
        if (result.get('length') == 0) {
          return true;
        } else {
          let isSameProduct = result.any(product => {
            return product.id === model.id;
          });

          if (isSameProduct) {
            return true;
          }

          return this.get('intl').t('product.constraint.unique.name.organization');
        }
      });
    }

  });

  UniqueProductName.reopenClass({
    getDependentsFor()
    /* attribute, options */
    {
      return [];
    }

  });
  var _default = UniqueProductName;
  _exports.default = _default;
});