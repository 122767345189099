define("stock-control-front/components/ember-power-select", ["exports", "ember-power-select/components/power-select"], function (_exports, _powerSelect) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _powerSelect.default.extend({
    allowClear: true,
    noMatchesMessage: Ember.computed('intl.locale', function () {
      return this.get('intl').t('searchInput.notFound.message');
    }),
    loadingMessage: Ember.computed('intl.locale', function () {
      return this.get('intl').t('searchInput.loading.message');
    }),
    searchMessage: Ember.computed('intl.locale', function () {
      return this.get('intl').t('searchInput.search.message');
    })
  });

  _exports.default = _default;
});