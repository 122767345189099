define("stock-control-front/helpers/current-date", ["exports", "moment", "stock-control-front/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.currentDate = currentDate;
  _exports.default = void 0;

  function currentDate(params
  /*, hash*/
  ) {
    let [date, format] = params;
    let navigationLanguage = _environment.default.intl.defaultLocale;

    if (window && window.navigator) {
      navigationLanguage = navigator.language || navigator.userLanguage;
    }

    navigationLanguage = navigationLanguage.substring(0, 2).toLowerCase();

    _moment.default.locale(navigationLanguage);

    return (0, _moment.default)(date).format(format);
  }

  var _default = Ember.Helper.helper(currentDate);

  _exports.default = _default;
});