define("stock-control-front/components/sale-note-register", ["exports", "sweetalert"], function (_exports, _sweetalert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    showPreview: false,
    actions: {
      showSaleNotePreview: function () {
        this.set('showPreview', true);
      },
      showSaleNoteForm: function () {
        this.set('showPreview', false);
      },
      saveSaleNote: function () {
        this.showLoader();
        this.get('saleNote').save().then(() => {
          this.get('saleNote').set('status', 'created');
          this.didSave();
        }, response => {
          if (response.errors !== undefined) {
            const {
              errors
            } = response;

            if (errors !== undefined && errors.length > 0) {
              let insufficientStockError = errors.any(error => {
                return error.id === 'INSUFFICIENT STOCK';
              });

              if (insufficientStockError) {
                (0, _sweetalert.default)('', this.intl.t('saleNote.errors.insufficientStock'), 'error');
              }
            }
          }
        }).finally(() => {
          this.get('saleNote').get('sales').clear();
          this.hideLoader();
        });
      }
    }
  });

  _exports.default = _default;
});