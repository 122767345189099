define("stock-control-front/components/masked-input", ["exports", "inputmask"], function (_exports, _inputmask) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    digits: 4,

    didInsertElement() {
      this._super(...arguments);

      (0, _inputmask.default)('currency', {
        digits: this.get('digits')
      }).mask(this.$('input'));
    }

  });

  _exports.default = _default;
});