define("stock-control-front/models/product-record", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    recordDate: _emberData.default.attr('date'),
    recordType: _emberData.default.attr('string'),
    recordSubtype: _emberData.default.attr('string'),
    quantity: _emberData.default.attr('number'),
    productExistence: _emberData.default.attr('number'),
    recordExistence: _emberData.default.attr('number'),
    recordQuantity: _emberData.default.attr('number'),
    recordUnit: _emberData.default.attr('string'),
    recordValue: _emberData.default.attr('number'),
    inventoryValue: _emberData.default.attr('number'),
    recordReference: _emberData.default.attr('string'),
    recordUuid: _emberData.default.attr('string'),
    total: _emberData.default.attr('number')
  });

  _exports.default = _default;
});