define("stock-control-front/helpers/math", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.math = math;
  _exports.default = void 0;

  function math(params) {
    var numberA = params[0],
        operator = params[1],
        numberB = params[2];
    return {
      "+": numberA + numberB,
      "-": numberA - numberB,
      "*": numberA * numberB,
      "/": numberA / numberB,
      "%": numberA % numberB
    }[operator];
  }

  var _default = Ember.Helper.helper(math);

  _exports.default = _default;
});