define("stock-control-front/utils/user-validations", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.password = _exports.email = void 0;
  const email = [(0, _emberCpValidations.validator)('presence', {
    presence: true,
    message: function () {
      return '';
    }
  }), (0, _emberCpValidations.validator)('format', {
    type: 'email',
    message: function () {
      let intl = this.model.get('intl');
      let message = intl.t('login.email.error.label');
      return message.toString();
    }
  })];
  _exports.email = email;
  const password = [(0, _emberCpValidations.validator)('presence', {
    presence: true,
    message: function () {
      return '';
    }
  }), (0, _emberCpValidations.validator)('length', {
    min: 6,
    max: 40,
    message: function () {
      let intl = this.model.get('intl');
      let message = intl.t('login.password.error.length.label');
      return message.toString();
    }
  })];
  _exports.password = password;
  var _default = {
    email,
    password
  };
  _exports.default = _default;
});