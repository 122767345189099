define("stock-control-front/components/select-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      updateSelect: function (selectedValue) {
        this.updated(selectedValue);
      }
    }
  });

  _exports.default = _default;
});