define("stock-control-front/admin/organizations/inventory/products-inventory/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OXAY5cY4",
    "block": "{\"symbols\":[],\"statements\":[[4,\"is-admin-user\",null,[[\"user\"],[[25,[\"currentUser\"]]]],{\"statements\":[[0,\"  \"],[1,[29,\"page-title\",null,[[\"section\",\"sub-section\"],[[29,\"t\",[\"inventory.reports.label\"],null],[29,\"t\",[\"inventory.reports.products.label\"],null]]]],false],[0,\"\\n\"],[0,\"  \\n\"],[0,\"  \"],[7,\"div\"],[11,\"class\",\"m-content\"],[9],[0,\"\\n    \"],[1,[29,\"products-inventory\",null,[[\"products\",\"productInventorySearch\",\"searchProductInventory\",\"selectedProduct\",\"notFound\",\"productRecords\"],[[25,[\"model\",\"products\"]],[25,[\"model\",\"productInventorySearch\"]],[29,\"action\",[[24,0,[]],\"searchProductInventory\"],null],[25,[\"model\",\"selectedProduct\"]],[25,[\"model\",\"notFound\"]],[25,[\"model\",\"productRecords\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "stock-control-front/admin/organizations/inventory/products-inventory/template.hbs"
    }
  });

  _exports.default = _default;
});