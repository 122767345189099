define("stock-control-front/validators/product-available", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const ProductAvailable = _base.default.extend({
    store: Ember.inject.service('store'),
    intl: Ember.inject.service('intl'),

    validate(value, options, model) {
      if (model.get('sale').get('productTotalQuantity') > 0) {
        let productUnitId = value.get('unit').get('id');
        let productExistences = value.get('existences');
        const productExistence = productExistences.filter(productExistence => productExistence.get('unit').get('id') == productUnitId);
        const existence = productExistence.get('firstObject').get('existence');
        let quantity = model.get('sale').get('productTotalQuantity');

        if (existence > 0 && existence >= quantity) {
          return true;
        } else {
          return this.get('intl').t('sale.product.stockEmpty.message.label');
        }
      } else {
        return true;
      }
    }

  });

  ProductAvailable.reopenClass({
    /**
     * Define attribute specific dependent keys for your validator
     *
     * [
     * 	`model.array.@each.${attribute}` --> Dependent is created on the model's context
     * 	`${attribute}.isValid` --> Dependent is created on the `model.validations.attrs` context
     * ]
     *
     * @param {String}  attribute   The attribute being evaluated
     * @param {Unknown} options     Options passed into your validator
     * @return {Array}
     */
    getDependentsFor()
    /* attribute, options */
    {
      return [];
    }

  });
  var _default = ProductAvailable;
  _exports.default = _default;
});