define("stock-control-front/templates/components/organization-register", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "OgjSkbQs",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"m-content\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"m-portlet\"],[9],[0,\"\\n\"],[0,\"    \"],[7,\"div\"],[11,\"class\",\"portlet-title-text\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"col-lg-12\"],[9],[0,\"\\n          \"],[7,\"h2\"],[9],[1,[29,\"t\",[\"organization.add.title\"],null],false],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[0,\"\\n    \"],[1,[29,\"organization-form\",null,[[\"organization\",\"address\",\"didValidate\"],[[25,[\"organization\"]],[25,[\"address\"]],[25,[\"didValidate\"]]]]],false],[0,\"\\n\\n    \"],[1,[29,\"address-form\",null,[[\"address\",\"didValidate\"],[[25,[\"address\"]],[25,[\"didValidate\"]]]]],false],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"m-portlet__foot\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"m-form__actions\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"offset-lg-6 col-lg-4 m--align-right\"],[9],[0,\"\\n            \"],[7,\"button\"],[11,\"class\",\"btn btn-info m-btn--wide\"],[9],[0,\"\\n              \"],[7,\"span\"],[9],[0,\"\\n                \"],[7,\"span\"],[9],[0,\"\\n                  \"],[1,[29,\"t\",[\"button.save.label\"],null],false],[0,\"\\n                \"],[10],[0,\"\\n              \"],[10],[0,\"\\n            \"],[3,\"action\",[[24,0,[]],\"addNew\"]],[10],[0,\"\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[15,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "stock-control-front/templates/components/organization-register.hbs"
    }
  });

  _exports.default = _default;
});