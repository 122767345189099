define("stock-control-front/helpers/has-danger-class", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.hasDangerClass = hasDangerClass;
  _exports.default = void 0;

  function hasDangerClass(params
  /*, hash*/
  ) {
    let [condition] = params;
    return condition == true ? 'has-danger' : '';
  }

  var _default = Ember.Helper.helper(hasDangerClass);

  _exports.default = _default;
});