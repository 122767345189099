define("stock-control-front/admin/organizations/sale-notes/show/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      cancelSaleNote: function () {
        let saleNote = this.get('model').saleNote;
        saleNote.set('status', 'canceled');
        saleNote.save().then(() => {
          this.transitionToRoute('admin.organizations.sale_notes', saleNote.get('organization').get('id'));
        }, error => {
          console.log(error);
        });
      }
    }
  });

  _exports.default = _default;
});