define("stock-control-front/translations/en-us", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "accountStatement": {
      "client": {
        "empty": {
          "message": "Client must be selected from the list"
        },
        "search": {
          "label": "Client"
        },
        "title": "Account Statement by client"
      },
      "date": {
        "from": {
          "label": "From"
        },
        "placeholder": "Select the date",
        "to": {
          "label": "To"
        }
      },
      "detail": {
        "header": {
          "balance": {
            "label": "Balance"
          },
          "concept": {
            "label": "Concept"
          },
          "credit": {
            "label": "Credit"
          },
          "date": {
            "label": "Date"
          },
          "debit": {
            "label": "Debit"
          },
          "reference": {
            "label": "Reference"
          }
        }
      },
      "filter": {
        "client": {
          "placeholder": "Select the client"
        },
        "supplier": {
          "placeholder": "Select the supplier"
        }
      },
      "label": "Account Statement",
      "notFound": {
        "message": "There aren't transactions for the account statement in the period."
      },
      "supplier": {
        "empty": {
          "message": "Supplier must be selected from the list"
        },
        "search": {
          "label": "Supplier"
        },
        "title": "Account Statement by supplier"
      }
    },
    "address": {
      "city": {
        "label": "City"
      },
      "colony": {
        "label": "Colony"
      },
      "cp": {
        "label": "C.P",
        "placeholder": {
          "label": "Postal Code"
        }
      },
      "list": {
        "empty": {
          "label": "There aren't registered addresses yet."
        },
        "label": "Addresses"
      },
      "state": {
        "label": "State"
      },
      "street": {
        "label": "Street"
      },
      "title": {
        "label": "Address"
      }
    },
    "balance": {
      "client": {
        "title": "Balances"
      },
      "export": {
        "all": {
          "pdf": "Export all"
        }
      },
      "label": "Balance"
    },
    "button": {
      "back": {
        "label": "Back"
      },
      "cancel": {
        "label": "Cancel"
      },
      "confirm": {
        "save": {
          "label": "Confirm and save"
        }
      },
      "continue": {
        "label": "Continue"
      },
      "delete": {
        "label": "Delete"
      },
      "edit": {
        "label": "Edit"
      },
      "filter": {
        "label": "Filter"
      },
      "next": {
        "label": "Next"
      },
      "previous": {
        "label": "Previous"
      },
      "remove": {
        "label": "Remove"
      },
      "save": {
        "label": "Save"
      },
      "search": {
        "label": "Search"
      },
      "select": {
        "label": "Select"
      },
      "show": {
        "label": "Show"
      },
      "update": {
        "label": "Update"
      }
    },
    "client": {
      "add": {
        "title": "Register Client"
      },
      "all": {
        "label": "All"
      },
      "balance": {
        "label": "Balances"
      },
      "detail": {
        "label": "Detail",
        "title": "Client Detail"
      },
      "email": {
        "label": "Email",
        "placeholder": {
          "label": "mail@domain.com"
        }
      },
      "initialBalance": {
        "amount": {
          "label": "Amount",
          "placeholder": "Amount"
        },
        "title": "Initial Balance"
      },
      "list": {
        "empty": {
          "label": "There aren't registered clients yet."
        },
        "label": "Clients"
      },
      "menu": {
        "accountStatement": {
          "label": "Account Statement"
        },
        "add": {
          "label": "Add new"
        },
        "balance": {
          "label": "Balances"
        },
        "list": {
          "label": "List"
        },
        "payment": {
          "label": "Payments"
        }
      },
      "modal": {
        "delete": {
          "header": "Delete client",
          "text": "Are you sure you want to delete the client {clientName}?"
        }
      },
      "name": {
        "label": "Name"
      },
      "new": {
        "label": "New Client"
      },
      "payment": {
        "add": {
          "title": "Register Payment"
        },
        "amountAndConcept": {
          "label": "Amount and Concept"
        },
        "concept": {
          "placeholder": "Concept"
        },
        "label": "New Payment",
        "saleNote": {
          "date": {
            "label": "Date",
            "placeholder": "Select the date"
          },
          "label": "Sale Note",
          "number": "Note #",
          "search": {
            "period": {
              "message": "Search by period",
              "notFound": "There aren't registered sale notes in the period."
            }
          }
        },
        "steps": {
          "first": {
            "message": "Select Client"
          },
          "second": {
            "message": "Amount and Concept"
          },
          "third": {
            "message": "Confirmation"
          }
        }
      },
      "personType": {
        "label": "Person Type"
      },
      "phone": {
        "label": "Phone"
      },
      "register": {
        "detail": {
          "accountStatement": {
            "initialBalance": {
              "label": "Initial Balance"
            },
            "label": "Account Statement"
          },
          "information": {
            "label": "Client Information",
            "title": "Client Details"
          }
        },
        "steps": {
          "first": {
            "message": "Client Information"
          },
          "second": {
            "message": "Account Statement"
          },
          "third": {
            "message": "Confirmation"
          }
        }
      },
      "rfc": {
        "label": "RFC"
      },
      "title": {
        "label": "Client"
      }
    },
    "date": {
      "from": {
        "label": "From"
      },
      "placeholder": "Select the date",
      "to": {
        "label": "To"
      }
    },
    "errors": {
      "accepted": "{description} must be accepted",
      "after": "{description} must be after {after}",
      "before": "{description} must be before {before}",
      "blank": "{description}",
      "collection": "{description} must be a collection",
      "confirmation": "{description} doesn't match {on}",
      "date": "{description} must be a valid date",
      "description": "This field",
      "email": "{description} must be a valid email address",
      "empty": "{description} can't be empty",
      "equalTo": "{description} must be equal to {is}",
      "even": "{description} must be even",
      "exclusion": "{description} is reserved",
      "greaterThan": "{description} must be greater than {gt}",
      "greaterThanOrEqualTo": "{description} must be greater than or equal to {gte}",
      "inclusion": "{description} is not included in the list",
      "invalid": "{description} is invalid",
      "lessThan": "{description} must be less than {lt}",
      "lessThanOrEqualTo": "{description} must be less than or equal to {lte}",
      "notANumber": "{description} must be a number",
      "notAnInteger": "{description} must be an integer",
      "odd": "{description} must be odd",
      "otherThan": "{description} must be other than {value}",
      "phone": "{description} must be a valid phone number",
      "positive": "{description} must be positive",
      "present": "{description} must be blank",
      "rfc": {
        "unique": "The RFC must be unique"
      },
      "singular": "{description} can't be a collection",
      "tooLong": "{description} is too long (maximum is {max} characters)",
      "tooShort": "{description} is too short (minimum is {min} characters)",
      "url": "{description} must be a valid url",
      "wrongDateFormat": "{description} must be in the format of {format}",
      "wrongLength": "{description} is the wrong length (should be {is} characters)"
    },
    "home": {
      "label": "Home"
    },
    "inventory": {
      "reports": {
        "label": "Inventory",
        "products": {
          "date": {
            "from": {
              "label": "From"
            },
            "placeholder": {
              "label": "Select the date"
            },
            "to": {
              "label": "To"
            }
          },
          "entry": {
            "label": "Entry"
          },
          "existence": {
            "label": "Existence"
          },
          "label": "Products inventory",
          "output": {
            "label": "Output"
          },
          "product": {
            "label": "Product",
            "notFound": {
              "message": {
                "label": "Product must be selected from the list"
              }
            }
          },
          "record": {
            "date": {
              "label": "Date"
            },
            "notFound": {
              "message": {
                "label": "There aren't inventory records for the product in the period."
              }
            },
            "purchase": {
              "label": "Purchase"
            },
            "reference": {
              "label": "Reference"
            },
            "return": {
              "reference": {
                "label": "Devolution"
              }
            },
            "sale": {
              "label": "Sale"
            }
          },
          "search": {
            "placeholder": {
              "label": "Search product"
            }
          },
          "title": "Inventory by product",
          "unit": {
            "label": "Unit"
          }
        },
        "valuation": {
          "cost": {
            "header": {
              "label": "Cost"
            }
          },
          "date": {
            "header": {
              "label": "Date"
            }
          },
          "entries": {
            "empty": {
              "label": "There aren't product entries with existences."
            }
          },
          "entry": {
            "quantity": {
              "header": {
                "label": "Entry"
              }
            }
          },
          "existences": {
            "label": "Existences"
          },
          "inventoryValue": {
            "header": {
              "label": "Inventory value"
            }
          },
          "label": "Inventory valuation",
          "productExistences": {
            "header": {
              "label": "Existences"
            }
          },
          "products": {
            "empty": {
              "label": "There aren't registered products yet."
            },
            "notFound": {
              "label": "No results found for the search."
            }
          },
          "quantity": {
            "label": "Quantity"
          },
          "title": "Valuation by product",
          "unit": {
            "header": {
              "label": "Unit"
            },
            "label": "Unit"
          },
          "value": {
            "label": "Inventory value"
          }
        }
      }
    },
    "login": {
      "box": {
        "button": "Login",
        "placeholder": {
          "password": "Password",
          "username": "Username"
        },
        "title": "Login to your account"
      },
      "email": {
        "error": {
          "label": "This field must be a valid email address"
        }
      },
      "error": {
        "label": "An error has ocurred in the login"
      },
      "password": {
        "error": {
          "length": {
            "label": "The password must have at least 6 characters"
          }
        }
      },
      "user": {
        "notFound": {
          "error": {
            "label": "Incorrect user or password"
          }
        }
      }
    },
    "modal": {
      "accept": {
        "button": {
          "label": "Accept"
        }
      },
      "cancel": {
        "button": {
          "label": "Cancel"
        }
      }
    },
    "organization": {
      "add": {
        "title": "Register Organization"
      },
      "all": {
        "label": "All"
      },
      "detail": {
        "label": "Detail",
        "title": "Organization Detail"
      },
      "email": {
        "label": "Email",
        "placeholder": {
          "label": "mail@domain.com"
        }
      },
      "list": {
        "empty": {
          "label": "There aren't registered organizations yet."
        },
        "label": "Organizations"
      },
      "name": {
        "label": "Name"
      },
      "new": {
        "label": "New Organization"
      },
      "personType": {
        "label": "Person Type"
      },
      "phone": {
        "label": "Phone"
      },
      "rfc": {
        "label": "RFC"
      },
      "show": {
        "label": "View"
      }
    },
    "payment": {
      "amount": {
        "label": "Amount"
      },
      "button": {
        "confirm": "Confirm"
      },
      "concept": {
        "label": "Concept"
      },
      "detail": {
        "label": "Payment Detail"
      },
      "reference": {
        "label": "Reference"
      },
      "saved": {
        "message": "The payment has been registered."
      }
    },
    "personType": {
      "legal": {
        "label": "Legal entity"
      },
      "natural": {
        "label": "Individual"
      }
    },
    "product": {
      "add": {
        "title": "Register new product"
      },
      "all": {
        "label": "All"
      },
      "constraint": {
        "unique": {
          "name": {
            "organization": "The product already exists in the organization."
          }
        }
      },
      "description": {
        "label": "Description",
        "placeholder": {
          "label": "Add a description"
        }
      },
      "list": {
        "empty": {
          "label": "There aren't registered products yet."
        },
        "label": "Products"
      },
      "modal": {
        "text": "Are you sure you want to delete the product {productName}?"
      },
      "name": {
        "label": "Name",
        "placeholder": {
          "label": "Product name"
        }
      },
      "notFound": {
        "message": {
          "label": "Products not found."
        }
      },
      "search": {
        "placeholder": {
          "label": "Search"
        }
      },
      "show": {
        "label": "Show"
      },
      "unit": {
        "label": "Unit",
        "select": {
          "label": "-Select Unit-"
        }
      }
    },
    "purchase": {
      "add": {
        "title": "Register Purchase"
      },
      "all": {
        "label": "All"
      },
      "invoice": {
        "detail": {
          "label": "Detail"
        },
        "label": "Purchase Invoice",
        "total": {
          "label": "Total"
        }
      },
      "invoiceDate": {
        "label": "Date"
      },
      "invoiceNumber": {
        "label": "Invoice Number",
        "placeholder": {
          "label": "Invoice #"
        }
      },
      "list": {
        "empty": {
          "label": "There aren't registered purchases yet."
        },
        "label": "Purchases",
        "ribbon": {
          "label": "Products List"
        }
      },
      "menu": {
        "add": {
          "label": "Add new"
        },
        "list": {
          "label": "List"
        }
      },
      "new": {
        "label": "New Purchase"
      },
      "product": {
        "add": {
          "button": {
            "label": "Add new"
          },
          "message": {
            "label": "Add a product to the purchase"
          }
        },
        "label": "Product",
        "notFound": {
          "message": {
            "label": "Product must be selected from the list"
          }
        },
        "quantity": {
          "label": "Quantity",
          "placeholder": {
            "label": "Qty"
          }
        },
        "search": {
          "placeholder": {
            "label": "Write product's name..."
          }
        },
        "unit": {
          "label": "Unit"
        },
        "unitCost": {
          "label": "Unit Cost"
        }
      },
      "quantity": {
        "label": "Purchase Quantity",
        "placeholder": {
          "label": "Purchase Qty"
        }
      },
      "supplier": {
        "add": {
          "label": "Add"
        },
        "label": "Supplier",
        "notFound": {
          "message": {
            "label": "Supplier not found"
          }
        },
        "required": {
          "message": {
            "label": "Supplier must be selected"
          }
        },
        "search": {
          "label": "Search..."
        }
      },
      "title": {
        "label": "Purchase"
      },
      "total": {
        "label": "Total"
      },
      "unit": {
        "label": "Purchase Unit",
        "notSelected": {
          "label": "-Select Unit-"
        }
      }
    },
    "purchaseInvoice": {
      "add": {
        "title": "Register Purchase Invoice"
      },
      "date": {
        "label": "Date"
      },
      "filter": {
        "endDate": {
          "label": "To"
        },
        "header": {
          "label": "Filter"
        },
        "invoiceNumber": {
          "label": "Invoice Number",
          "placeholder": {
            "label": "Invoice #"
          }
        },
        "startDate": {
          "label": "From"
        },
        "supplier": {
          "label": "Supplier",
          "search": {
            "placeholder": {
              "label": "Type supplier's name"
            }
          }
        }
      },
      "list": {
        "notFound": {
          "message": {
            "label": "Purchases not found."
          }
        }
      },
      "number": {
        "label": "Invoice Number"
      },
      "save": {
        "error": "An error has occurred when registering the purchase invoice"
      },
      "title": {
        "label": "Purchase Invoice"
      }
    },
    "sale": {
      "all": {
        "label": "All"
      },
      "list": {
        "label": "Sales",
        "ribbon": {
          "label": "Sold Products"
        }
      },
      "product": {
        "add": {
          "message": {
            "label": "Add a product to the sale"
          }
        },
        "label": "Product",
        "notFound": {
          "message": {
            "label": "Product must be selected from the list"
          }
        },
        "quantity": {
          "label": "Quantity",
          "placeholder": {
            "label": "Qty"
          }
        },
        "search": {
          "placeholder": {
            "label": "Write the product's name"
          }
        },
        "stockEmpty": {
          "message": {
            "label": "Insufficient product in the Stock"
          }
        },
        "unit": {
          "label": "Unit"
        },
        "unitPrice": {
          "label": "Unit Price"
        }
      },
      "quantity": {
        "label": "Sale Quantity",
        "placeholder": {
          "label": "Sale Qty"
        }
      },
      "total": {
        "label": "Total"
      },
      "unit": {
        "label": "Sale Unit",
        "notSelected": {
          "label": "-Select Unit-"
        },
        "stockEmpty": {
          "message": {
            "label": "Insufficient existences"
          }
        }
      }
    },
    "saleNote": {
      "add": {
        "title": "Register Sale Note"
      },
      "cancel": {
        "confirm": {
          "label": "Are you sure you want to cancel the Sale Note?"
        },
        "modal": {
          "header": {
            "label": "Cancel Sale Note"
          }
        }
      },
      "client": {
        "add": {
          "label": "Add"
        },
        "label": "Client",
        "notFound": {
          "message": {
            "label": "Client not found"
          }
        },
        "required": {
          "message": {
            "label": "Client must be selected"
          }
        },
        "search": {
          "label": "Write the name of the client"
        }
      },
      "detail": {
        "label": "Detail"
      },
      "errors": {
        "insufficientStock": "There aren't enough products in stock."
      },
      "filter": {
        "client": {
          "label": "Client",
          "search": {
            "placeholder": {
              "label": "Type client's name"
            }
          }
        },
        "date": {
          "placeholder": {
            "label": "Select the date"
          }
        },
        "endDate": {
          "label": "To"
        },
        "header": {
          "label": "Filter"
        },
        "startDate": {
          "label": "From"
        }
      },
      "invoiceNumber": {
        "label": "Sale Note #"
      },
      "list": {
        "empty": {
          "label": "There aren't registered sale notes yet."
        },
        "label": "Sale Notes",
        "notFound": {
          "message": {
            "label": "Sale notes not found."
          }
        }
      },
      "menu": {
        "add": {
          "label": "Add new"
        },
        "list": {
          "label": "Sale Notes"
        }
      },
      "new": {
        "label": "New Sale Note"
      },
      "organization": {
        "email": {
          "label": "Email"
        },
        "phone": {
          "label": "Phone"
        }
      },
      "product": {
        "add": {
          "button": {
            "label": "Add new"
          }
        }
      },
      "saleDate": {
        "label": "Date"
      },
      "status": {
        "canceled": {
          "label": "Canceled"
        },
        "created": {
          "label": "Created"
        },
        "label": "Status"
      },
      "title": {
        "label": "Sale Note"
      },
      "total": {
        "label": "Total"
      }
    },
    "searchInput": {
      "loading": {
        "message": "Loading options..."
      },
      "notFound": {
        "message": "No results found"
      },
      "search": {
        "message": "Type to search"
      }
    },
    "supplier": {
      "add": {
        "title": "Register Supplier"
      },
      "all": {
        "label": "All"
      },
      "detail": {
        "label": "Detail",
        "title": "Supplier Detail"
      },
      "email": {
        "label": "Email",
        "placeholder": {
          "label": "mail@domain.com"
        }
      },
      "initialBalance": {
        "amount": {
          "label": "Amount",
          "placeholder": "Amount"
        },
        "title": "Initial Balance"
      },
      "list": {
        "empty": {
          "label": "There aren't registered suppliers yet."
        },
        "label": "Suppliers"
      },
      "menu": {
        "accountStatement": {
          "label": "Account Statement"
        },
        "add": {
          "label": "Add new"
        },
        "list": {
          "label": "List"
        },
        "payment": {
          "label": "Payments"
        }
      },
      "modal": {
        "delete": {
          "header": "Delete supplier",
          "text": "Are you sure you want to delete the supplier {supplierName}?"
        }
      },
      "name": {
        "label": "Name"
      },
      "nationalityType": {
        "foreign": {
          "label": "Foreign"
        },
        "label": "Supplier",
        "national": {
          "label": "National"
        }
      },
      "new": {
        "label": "New Supplier"
      },
      "payment": {
        "add": {
          "title": "Register Payment"
        },
        "amountAndConcept": {
          "label": "Amount and Concept"
        },
        "concept": {
          "placeholder": "Concept"
        },
        "label": "New Payment",
        "purchaseInvoice": {
          "date": {
            "label": "Date",
            "placeholder": "Select the date"
          },
          "label": "Purchase Invoice",
          "number": "Invoice #",
          "search": {
            "period": {
              "message": "Search by period",
              "notFound": "There aren't registered purchase invoices in the period."
            }
          }
        },
        "steps": {
          "first": {
            "message": "Select the Supplier"
          },
          "second": {
            "message": "Amount and Concept"
          },
          "third": {
            "message": "Confirmation"
          }
        }
      },
      "personType": {
        "label": "Person Type"
      },
      "phone": {
        "label": "Phone"
      },
      "register": {
        "detail": {
          "accountStatement": {
            "initialBalance": {
              "label": "Initial Balance"
            },
            "label": "Account Statement"
          },
          "information": {
            "label": "Supplier Information",
            "title": "Supplier Details"
          }
        },
        "steps": {
          "first": {
            "message": "Supplier Information"
          },
          "second": {
            "message": "Account Statement"
          },
          "third": {
            "message": "Confirmation"
          }
        }
      },
      "rfc": {
        "label": "RFC"
      },
      "show": {
        "label": "Show"
      },
      "taxId": {
        "label": "Tax Id"
      },
      "title": {
        "label": "Supplier"
      }
    },
    "transaction": {
      "instance": {
        "canceled": {
          "concept": "Cancellation"
        }
      },
      "payment": "Payment"
    },
    "unit": {
      "add": {
        "title": "Add Unit of Measurement"
      },
      "all": {
        "label": "All"
      },
      "list": {
        "empty": {
          "label": "There aren't registered units yet."
        },
        "label": "Units of Measure"
      },
      "menu": {
        "list": {
          "label": "Units"
        }
      },
      "modal": {
        "delete": {
          "header": "Delete Unit",
          "text": "Are you sure you want to delete the unit {unitName}?"
        }
      },
      "name": {
        "label": "Name"
      }
    }
  };
  _exports.default = _default;
});