define("stock-control-front/templates/components/left-navigation-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bVyjCvpH",
    "block": "{\"symbols\":[\"menuItem\",\"key\",\"&default\"],\"statements\":[[7,\"button\"],[11,\"class\",\"m-aside-left-close m-aside-left-close--skin-dark\"],[11,\"id\",\"m_aside_left_close_btn\"],[9],[0,\"\\n  \"],[7,\"i\"],[11,\"class\",\"fa fa-times-thin\"],[9],[10],[0,\"  \\n\"],[3,\"action\",[[24,0,[]],\"toggleSideBar\"]],[10],[0,\"\\n\"],[7,\"div\"],[11,\"id\",\"m_aside_left\"],[12,\"class\",[30,[[29,\"if\",[[25,[\"isOpen\"]],\"m-aside-left--on\"],null],\" m-grid__item m-aside-left m-aside-left--skin-dark\"]]],[9],[0,\"\\n\"],[0,\"  \"],[7,\"div\"],[11,\"id\",\"m_ver_menu\"],[11,\"class\",\"m-aside-menu m-aside-menu--skin-dark m-aside-menu--submenu-skin-dark\"],[9],[0,\"\\n    \\n    \"],[7,\"ul\"],[11,\"class\",\"m-menu__nav\"],[9],[0,\"\\n\"],[4,\"each\",[[29,\"-each-in\",[[25,[\"menu\"]]],null]],null,{\"statements\":[[0,\"        \"],[1,[29,\"menu-link\",null,[[\"item\"],[[24,1,[]]]]],false],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"div\"],[12,\"class\",[30,[\"m-aside-left-overlay \",[29,\"if\",[[29,\"not\",[[25,[\"isOpen\"]]],null],\"hidden\",\"\"],null]]]],[12,\"onClick\",[29,\"action\",[[24,0,[]],\"toggleSideBar\"],null]],[12,\"touchEnd\",[29,\"action\",[[24,0,[]],\"toggleSideBar\"],null]],[11,\"role\",\"button\"],[9],[10],[0,\"\\n\"],[15,3],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "stock-control-front/templates/components/left-navigation-menu.hbs"
    }
  });

  _exports.default = _default;
});