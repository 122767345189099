define("stock-control-front/admin/organizations/clients/show/route", ["exports", "stock-control-front/mixins/url-validation"], function (_exports, _urlValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_urlValidation.default, {
    model: function (params) {
      return this.store.findRecord('client', params.client_id);
    },

    renderTemplate() {
      this.render({
        into: 'admin'
      });
    }

  });

  _exports.default = _default;
});