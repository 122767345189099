define("stock-control-front/auth/login/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    intl: Ember.inject.service(),

    redirect() {
      if (this.get('session.isAuthenticated')) {
        this.transitionTo('admin');
      }
    },

    model() {
      return {
        email: '',
        password: ''
      };
    }

  });

  _exports.default = _default;
});