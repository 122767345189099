define("stock-control-front/admin/organizations/suppliers/account-statement/route", ["exports", "stock-control-front/mixins/url-validation"], function (_exports, _urlValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_urlValidation.default, {
    navigationMenuService: Ember.inject.service('navigation-menu'),

    activate() {
      let model = this.modelFor('admin.organizations.suppliers');
      this.get('navigationMenuService').setMenuItems(this.get('routeName'), model.query.organization);
    },

    model: function () {
      const organizationId = this.paramsFor('admin.organizations.suppliers').organization_id;
      const organization = this.store.peekRecord('organization', organizationId);
      return Ember.RSVP.hash({
        organization: organization
      });
    },

    renderTemplate() {
      this.render({
        into: 'admin'
      });
    },

    resetController: function (controller, isExiting, transition) {
      if (isExiting) {
        const path = transition.to.name.replace('.index', '');

        if (this.get('routeName').indexOf(path) !== -1) {
          let model = this.modelFor('admin.organizations.suppliers');
          this.get('navigationMenuService').setMenuItems(path, model.query.organization);
        }
      }
    }
  });

  _exports.default = _default;
});