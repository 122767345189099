define("stock-control-front/components/confirm-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      submit: function () {
        this.submit();
      },
      close: function () {
        this.close();
      }
    }
  });

  _exports.default = _default;
});