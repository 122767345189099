define("stock-control-front/admin/organizations/inventory/products-inventory/controller", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentUser: Ember.computed.alias('session.currentUser'),
    actions: {
      searchProductInventory(params) {
        let productInventorySearch = params;
        this.store.findRecord('product', productInventorySearch.product).then(product => {
          Ember.set(this.get('model'), 'selectedProduct', product);
        });
        this.store.query('product-record', {
          filter: {
            product: productInventorySearch.product,
            fromDate: (0, _moment.default)(productInventorySearch.startDate).format("YYYY-MM-DD"),
            toDate: (0, _moment.default)(productInventorySearch.endDate).format("YYYY-MM-DD")
          }
        }).then(productRecords => {
          Ember.set(this.get('model'), 'notFound', !(productRecords.get('length') > 0));
          Ember.set(this.get('model'), 'productRecords', productRecords);
        });
      }

    }
  });

  _exports.default = _default;
});