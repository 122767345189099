define("stock-control-front/components/status-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'span',
    classNames: ['status-label', 'm-badge'],
    statusClasses: Ember.computed(function () {
      return {
        'created': 'm-badge--info',
        'canceled': 'm-badge--danger'
      };
    }),
    classNameBindings: ['currentStatus'],
    currentStatus: Ember.computed('status', 'statusClasses', function () {
      return this.get('statusClasses')[this.get('status')];
    })
  });

  _exports.default = _default;
});