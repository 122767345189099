define("stock-control-front/router", ["exports", "stock-control-front/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('auth', function () {
      this.route('login');
    });
    this.route('admin', {
      path: '/'
    }, function () {
      this.route('organizations', function () {
        this.route('show', {
          path: ':organization_id'
        });
        this.route('add');
        this.route('clients', {
          path: ':organization_id/clients/'
        }, function () {
          this.route('add');
          this.route('show', {
            path: ':client_id'
          });
          this.route('account_statement');
          this.route('payment');
          this.route('balance');
        });
        this.route('products', {
          path: ':organization_id/products/'
        }, function () {});
        this.route('units', {
          path: ':organization_id/units/'
        }, function () {});
        this.route('suppliers', {
          path: ':organization_id/suppliers/'
        }, function () {
          this.route('add');
          this.route('show', {
            path: ':supplier_id'
          });
          this.route('account_statement');
          this.route('payment');
        });
        this.route('purchases', {
          path: ':organization_id/purchases/'
        }, function () {
          this.route('add');
          this.route('show', {
            path: ':purchase_invoice_id'
          });
        });
        this.route('inventory', {
          path: ':organization_id/inventory/'
        }, function () {
          this.route('products_inventory');
          this.route('inventory_valuation');
        });
        this.route('sale_notes', {
          path: ':organization_id/sale_notes/'
        }, function () {
          this.route('add');
          this.route('show', {
            path: ':sale_note_id'
          });
        });
        this.route('client', function () {
          this.route('balance');
        });
      });
    });
  });
  var _default = Router;
  _exports.default = _default;
});