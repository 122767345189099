define("stock-control-front/models/supplier-payment", ["exports", "ember-data", "stock-control-front/models/payment", "ember-cp-validations"], function (_exports, _emberData, _payment, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    supplier: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    }
  });

  var _default = _payment.default.extend(Validations, {
    supplier: _emberData.default.belongsTo('supplier')
  });

  _exports.default = _default;
});