define("stock-control-front/admin/controller", ["exports", "stock-control-front/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    session: Ember.inject.service(),
    isLoading: false,
    actions: {
      logout() {
        let options = {
          url: "".concat(_environment.default.host, "/api/logout"),
          type: 'POST',
          headers: {
            'Content-Type': 'application/vnd.api+json',
            'Accept': 'application/vnd.api+json',
            'Authorization': "Bearer ".concat(this.get('session').get('session.content.authenticated.access_token'))
          },
          success: () => {
            this.get('session').invalidate();
          },
          error: () => {
            this.get('session').invalidate();
          }
        };
        Ember.$.ajax(options);
      },

      showLoader: function () {
        this.set('isLoading', true);
      },
      hideLoader: function () {
        this.set('isLoading', false);
      }
    }
  });

  _exports.default = _default;
});