define("stock-control-front/components/login-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    showValidations: false,
    classNameBindings: ['isValid:has-success', 'hasErrors:has-error'],
    hasErrors: Ember.computed.and('displayValidations', 'errors.length'),
    hasNotErrors: Ember.computed.not('hasErrors'),
    isValid: Ember.computed.and('displayValidations', 'hasNotErrors'),
    displayValidations: Ember.computed.or('showValidations', 'didValidate'),
    _errorMessages: Ember.computed('errors.[]', function () {
      return (this.get('errors') || []).join(', ');
    }),

    focusOut() {
      this._super(...arguments);

      this.set('showValidations', true);
    }

  });

  _exports.default = _default;
});