define("stock-control-front/components/validated-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNameBindings: ['isValid:has-success', 'hasErrors:has-error'],
    isValid: Ember.computed.and('showValidations', 'attr-validation.isTruelyValid', 'hasContent'),
    hasErrors: Ember.computed.and('displayValidations', 'attr-validation.isInvalid'),
    hasContent: Ember.computed.notEmpty('value').readOnly(),
    didValidate: false,
    showValidations: false,
    displayValidations: Ember.computed.or('showValidations', 'didValidate'),
    focusOutAction: () => {},

    init() {
      this._super(...arguments);

      let attribute = this.get('model-attr');
      Ember.defineProperty(this, 'attr-validation', Ember.computed.readOnly("model.validations.attrs.".concat(attribute)));
      Ember.defineProperty(this, 'value', Ember.computed.alias("model.".concat(attribute)));
    },

    focusOut() {
      Ember.set(this, 'showValidations', true);
      this.focusOutAction(this.get('model'), this.get('value'));
    },

    didUpdateAttrs() {
      let instance = this.get('model');

      if (instance instanceof Ember.ObjectProxy) {
        instance.then(instanceRecord => {
          this.checkForChangedAttributes(instanceRecord);
        });
      } else {
        this.checkForChangedAttributes(instance);
      }
    },

    checkForChangedAttributes(instance) {
      if (instance.currentState !== undefined) {
        if (Object.keys(instance.changedAttributes()).length == 0 && Object.keys(instance.getChangedRelationships()).length == 0) {
          this.set('showValidations', false);
        }
      }
    },

    actions: {
      updateSelect: function (selectedValue) {
        let model = this.get('model');
        this.updated(model, selectedValue);
      }
    }
  });

  _exports.default = _default;
});