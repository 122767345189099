define("stock-control-front/components/purchase-invoice-preview", ["exports", "sweetalert"], function (_exports, _sweetalert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['row'],
    actions: {
      showPurchaseInvoiceForm: function () {
        this.showPurchaseInvoiceForm();
      },
      confirmAndSavePurchaseInvoice: function () {
        this.set('isSaving', true);
        this.confirmAndSave().then(() => {
          this.didSave();
        }).catch(() => {
          (0, _sweetalert.default)("", this.get('intl').t('purchaseInvoice.save.error'), "error");
        }).finally(() => {
          this.set('isSaving', false);
        });
      }
    },
    totalAmount: Ember.computed(function () {
      let purchases = this.get('purchaseInvoice').get('purchases');
      return purchases.reduce((previous, purchase) => {
        return purchase.get('productToPurchase').get('total') + previous;
      }, 0);
    })
  });

  _exports.default = _default;
});