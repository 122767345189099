define("stock-control-front/helpers/or-conditional", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.orConditional = orConditional;
  _exports.default = void 0;

  function orConditional(params
  /*, hash*/
  ) {
    var expression1 = params[0],
        expression2 = params[1];
    return expression1 || expression2;
  }

  var _default = Ember.Helper.helper(orConditional);

  _exports.default = _default;
});