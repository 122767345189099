define("stock-control-front/admin/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    navigationMenuService: Ember.inject.service('navigation-menu'),

    redirect() {
      this.transitionTo('admin.organizations');
    },

    activate() {
      this.get('navigationMenuService').setMenuItems(this.get('routeName'));
    }

  });

  _exports.default = _default;
});