define("stock-control-front/admin/organizations/clients/add/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentUser: Ember.computed.alias('session.currentUser'),
    actions: {
      addNew() {
        let address = this.get('model').address;
        let client = this.get('model').client;
        client.get('addresses').addObject(address);
        client.validate().then(({
          validations
        }) => {
          if (validations.get('isValid')) {
            address.save().then(() => {
              client.save().then(() => {
                const owner = Ember.getOwner(this);
                let route = owner.lookup("route:admin.organizations.clients");
                route.refresh();
                this.transitionToRoute('admin.organizations.clients');
              }, function () {
                //TODO: Implement error message window
                console.log('Client could n\'t be saved');
              });
            }, function () {
              //TODO: Implement error message window
              console.log('Address could n\'t be saved');
            });
          }
        });
      }

    }
  });

  _exports.default = _default;
});