define("stock-control-front/templates/components/entry-columns", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "KyEvkcyA",
    "block": "{\"symbols\":[],\"statements\":[[7,\"td\"],[11,\"class\",\"text-center uppercase\"],[9],[0,\"\\n\"],[4,\"if\",[[29,\"eq\",[[25,[\"productRecord\",\"recordSubtype\"]],\"Purchase\"],null]],null,{\"statements\":[[4,\"link-to\",[\"admin.organizations.purchases.show\",[25,[\"productRecord\",\"recordUuid\"]]],null,{\"statements\":[[0,\"      \"],[1,[25,[\"productRecord\",\"recordReference\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},{\"statements\":[[4,\"if\",[[29,\"eq\",[[25,[\"productRecord\",\"recordSubtype\"]],\"Return\"],null]],null,{\"statements\":[[0,\"    \"],[7,\"span\"],[11,\"class\",\"m-badge status-label m-badge--danger\"],[9],[0,\"\\n      \"],[1,[29,\"t\",[\"inventory.reports.products.record.return.reference.label\"],null],false],[0,\"\\n    \"],[10],[0,\"\\n  \"]],\"parameters\":[]},null]],\"parameters\":[]}],[10],[0,\"\\n\"],[7,\"td\"],[11,\"class\",\"text-center\"],[9],[0,\"\\n  \"],[1,[29,\"format-number\",[[25,[\"productRecord\",\"recordQuantity\"]]],[[\"precision\"],[\"2\"]]],false],[0,\" \\n\"],[10],[0,\"\\n\"],[7,\"td\"],[11,\"class\",\"text-center\"],[9],[0,\"\\n  \"],[1,[25,[\"productRecord\",\"recordUnit\"]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"td\"],[11,\"class\",\"text-center\"],[9],[0,\"\\n  \"],[1,[29,\"format-number\",[[25,[\"productRecord\",\"quantity\"]]],[[\"precision\"],[\"2\"]]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[7,\"td\"],[11,\"class\",\"text-center\"],[9],[10],[0,\"\\n\"],[7,\"td\"],[11,\"class\",\"text-center\"],[9],[10],[0,\"\\n\"],[7,\"td\"],[11,\"class\",\"text-center\"],[9],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "stock-control-front/templates/components/entry-columns.hbs"
    }
  });

  _exports.default = _default;
});