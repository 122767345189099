define("stock-control-front/templates/components/purchase-invoice-register", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "5TneVs4m",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[29,\"and\",[[29,\"get\",[[25,[\"purchaseInvoice\",\"validations\"]],\"isValid\"],null],[25,[\"showPreview\"]]],null]],null,{\"statements\":[[0,\"  \"],[1,[29,\"purchase-invoice-preview\",null,[[\"purchaseInvoice\",\"showPurchaseInvoiceForm\",\"confirmAndSave\",\"didSave\"],[[25,[\"purchaseInvoice\"]],[29,\"action\",[[24,0,[]],\"showPurchaseInvoiceForm\"],null],[29,\"action\",[[24,0,[]],\"confirmAndSave\"],null],[29,\"action\",[[24,0,[]],\"didSavePurchaseInvoice\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"  \"],[1,[29,\"purchase-invoice-form\",null,[[\"purchaseInvoice\",\"units\",\"products\",\"suppliers\",\"showPurchaseInvoicePreview\"],[[25,[\"purchaseInvoice\"]],[25,[\"units\"]],[25,[\"products\"]],[25,[\"suppliers\"]],[29,\"action\",[[24,0,[]],\"showPurchaseInvoicePreview\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]}]],\"hasEval\":false}",
    "meta": {
      "moduleName": "stock-control-front/templates/components/purchase-invoice-register.hbs"
    }
  });

  _exports.default = _default;
});