define("stock-control-front/components/purchase-invoice-register", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    showPreview: false,
    actions: {
      showPurchaseInvoiceForm: function () {
        this.set('showPreview', false);
      },
      showPurchaseInvoicePreview: function () {
        this.set('showPreview', true);
      },
      didSavePurchaseInvoice: function () {
        this.didSave();
      },
      confirmAndSave: function () {
        return this.savePurchaseInvoice();
      }
    }
  });

  _exports.default = _default;
});