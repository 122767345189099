define("stock-control-front/admin/route", ["exports", "stock-control-front/config/environment", "fetch"], function (_exports, _environment, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    beforeModel() {
      if (!this.get('session.isAuthenticated')) {
        this.transitionTo('auth.login');
      }
    },

    afterModel() {
      return (0, _fetch.default)("".concat(_environment.default.host, "/api/user/current"), {
        type: 'GET',
        headers: {
          'Content-Type': 'application/vnd.api+json',
          'Accept': 'application/vnd.api+json',
          'Authorization': "Bearer ".concat(this.get('session').get('session.content.authenticated.access_token'))
        }
      }).then(data => {
        if (data.status == 401) {
          this.get('session').invalidate();
        }

        return data.json().then(response => {
          const currentUser = this.store.push(response);
          this.set('session.currentUser', currentUser);
        });
      });
    },

    actions: {
      showLoader: function () {
        this.controllerFor('admin').send('showLoader');
      },
      hideLoader: function () {
        this.controllerFor('admin').send('hideLoader');
      },

      loading(transition) {
        transition.send('showLoader');
      },

      didTransition: function () {
        this.send('hideLoader');
      }
    }
  });

  _exports.default = _default;
});