define("stock-control-front/components/transaction-row", ["exports", "accounting/format-money"], function (_exports, _formatMoney) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'tr',
    linkPaths: Ember.computed('transaction', function () {
      return {
        "SaleNote": "admin.organizations.sale_notes.show",
        "PurchaseInvoice": "admin.organizations.purchases.show"
      };
    }),

    init() {
      this._super(...arguments);

      Ember.defineProperty(this, 'linkPath', Ember.computed('transaction', 'linkPaths', function () {
        return this.get('linkPaths')[this.get('transaction').get('referenceType')];
      }));
      Ember.defineProperty(this, 'concepts', Ember.computed('transaction', function () {
        const concept = this.get('transaction').get('concept');

        if (concept && concept !== "canceled" && concept !== "payment") {
          let concepts = concept.split("\n");

          if (this.get('transaction').get('referenceType')) {
            concepts = this.setConceptAmounts(this.get('transaction').get('referenceType'), concepts);
          }

          return concepts;
        } else {
          return [];
        }
      }));
    },

    setConceptAmounts: function (type, concepts) {
      let conceptAmountsByType = {
        'SaleNote': concept => this.setSaleNoteAmounts(concept),
        'PurchaseInvoice': concept => this.setPurchaseInvoiceAmounts(concept)
      };
      let conceptAmountsType = conceptAmountsByType[type];
      let updatedConcepts = concepts.map(function (concept) {
        return conceptAmountsType(concept);
      });
      return updatedConcepts;
    },
    setSaleNoteAmounts: function (concept) {
      const amountsRegex = new RegExp(/\d+?\.\d+,\d+?\.\d+$/, "gi");
      const amountMatches = amountsRegex.exec(concept);

      if (!amountMatches) {
        return concept;
      }

      const [price, total] = amountMatches[0].split(",");
      let newConcept = '<br><br>' + '<span> <i class="flaticon-price-tag"></i> Precio: <b>' + (0, _formatMoney.default)(price) + '</b></span><br>' + '<span> <i class="fa fa-money"></i> Total: <b> <i class="fa fa-coins"></i>' + (0, _formatMoney.default)(total) + '</b></span><br><br>';
      return concept.replace(amountsRegex, newConcept);
    },
    setPurchaseInvoiceAmounts: function (concept) {
      const amountsRegex = new RegExp(/\d+?\.\d+,\d+?\.\d+$/, "gi");
      const amountMatches = amountsRegex.exec(concept);

      if (!amountMatches) {
        return concept;
      }

      const [cost, total] = amountMatches[0].split(",");
      let newConcept = '<br><br>' + '<span> <i class="flaticon-price-tag"></i> Costo: <b>' + (0, _formatMoney.default)(cost) + '</b></span><br>' + '<span> <i class="fa fa-money"></i> Total: <b> <i class="fa fa-coins"></i>' + (0, _formatMoney.default)(total) + '</b></span><br><br>';
      return concept.replace(amountsRegex, newConcept);
    }
  });

  _exports.default = _default;
});