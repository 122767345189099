define("stock-control-front/admin/organizations/clients/balance/controller", ["exports", "moment", "stock-control-front/config/environment", "ember-cli-file-saver/mixins/file-saver"], function (_exports, _moment, _environment, _fileSaver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend(_fileSaver.default, {
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    currentUser: Ember.computed.alias('session.currentUser'),
    queryParams: ['date', 'page'],
    page: 1,
    pageSize: 10,
    date: (0, _moment.default)(new Date()).format('YYYY-MM-DD'),
    toDate: new Date(),
    actions: {
      downloadPDF() {
        const organization = this.model.clientBalances.query.organization;
        const toDate = this.model.clientBalances.query.to_date;
        this.get('ajax').request("".concat(_environment.default.host, "/api/clients-balance/pdf?organization=").concat(organization, "&to_date=").concat(toDate), {
          dataType: 'arraybuffer',
          options: {
            arraybuffer: true
          },
          headers: {
            'Content-Type': 'application/vnd.api+json',
            'Authorization': "Bearer ".concat(this.get('session').get('session.content.authenticated.access_token'))
          }
        }).then(content => {
          this.saveFileAs("ClientsBalance.pdf", content, 'application/pdf');
        });
      },

      updateBalanceLimitDate() {
        this.set('date', (0, _moment.default)(this.get('toDate')).format('YYYY-MM-DD'));
      },

      findRecords(params) {
        const organization = this.model.clientBalances.query.organization;
        const toDate = this.model.clientBalances.query.to_date;
        this.get('store').query('client-balance', {
          organization: organization,
          to_date: toDate,
          page: params.page,
          page_size: params.page_size
        }).then(clientBalances => {
          this.set('model.clientBalances', clientBalances);
        });
      }

    }
  });

  _exports.default = _default;
});