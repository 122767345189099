define("stock-control-front/components/top-bar-user", ["exports", "stock-control-front/mixins/click-outside"], function (_exports, _clickOutside) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_clickOutside.default, {
    session: Ember.inject.service(),
    tagName: 'li',
    classNameBindings: ['isOpen:m-dropdown--open'],
    classNames: ['m-nav__item', 'm-topbar__user-profile', 'm-dropdown', 'm-dropdown--medium', 'm-dropdown--arrow', 'm-dropdown--align-right', 'm-dropdown--mobile-full-width', 'm-dropdown--skin-light'],
    isOpen: false,
    currentUser: Ember.computed.alias('session.currentUser'),
    onOutsideClick: function () {
      this.set('isOpen', false);
    },
    actions: {
      toggleUserInfo: function () {
        this.toggleProperty('isOpen');
      },
      logout: function () {
        this.logout();
      }
    }
  });

  _exports.default = _default;
});