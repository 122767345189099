define("stock-control-front/serializers/purchase-invoice", ["exports", "stock-control-front/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    serialize(snapshot) {
      let record = snapshot.record;

      var json = this._super(...arguments);

      json.data.attributes.purchases = [];
      record.get('purchases').forEach(purchase => {
        let productToPurchase = purchase.get('productToPurchase');
        json.data.attributes.purchases.push({
          quantity: purchase.get('quantity'),
          unit_id: purchase.get("unit").get("id"),
          product_to_purchase: {
            quantity: productToPurchase.get('quantity'),
            unit_cost: productToPurchase.get('unitCost'),
            product_id: productToPurchase.get('product').get('id')
          }
        });
      });
      return json;
    }

  });

  _exports.default = _default;
});