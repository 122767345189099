define("stock-control-front/components/login-form", ["exports", "stock-control-front/utils/user-validations", "ember-cp-validations"], function (_exports, _userValidations, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'user.email': _userValidations.email,
    'user.password': _userValidations.password
  });

  var _default = Ember.Component.extend(Validations, {
    intl: Ember.inject.service(),
    actions: {
      doLogin: function () {
        if (this.get('validations.isValid')) {
          this.doLogin();
        } else {
          this.set('didValidate', true);
        }
      }
    }
  });

  _exports.default = _default;
});