define("stock-control-front/components/organization-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    personTypes: Ember.computed('intl.locale', function () {
      return [{
        key: 'natural',
        value: this.intl.t('personType.natural.label')
      }, {
        key: 'legal',
        value: this.intl.t('personType.legal.label')
      }];
    }),
    actions: {
      updatePersonType: function (organization, personType) {
        organization.set('personType', personType);
      }
    }
  });

  _exports.default = _default;
});