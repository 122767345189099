define("stock-control-front/adapters/product-to-sell", ["exports", "stock-control-front/adapters/application", "stock-control-front/config/environment", "ember-simple-auth/mixins/data-adapter-mixin"], function (_exports, _application, _environment, _dataAdapterMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend(_dataAdapterMixin.default, {
    namespace: 'api',
    host: _environment.default.host,
    session: Ember.inject.service('session'),

    authorize(xhr) {
      let {
        access_token
      } = this.get('session.data.authenticated');

      if (access_token != undefined && access_token != "") {
        xhr.setRequestHeader('Authorization', "Bearer ".concat(access_token));
      }
    },

    pathForType: function () {
      return 'products-to-sell';
    }
  });

  _exports.default = _default;
});