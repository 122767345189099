define("stock-control-front/helpers/gt", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.gt = gt;
  _exports.default = void 0;

  function gt(params) {
    let [arg1, arg2] = params;
    return parseInt(arg1) > parseInt(arg2);
  }

  var _default = Ember.Helper.helper(gt);

  _exports.default = _default;
});