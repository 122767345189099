define("stock-control-front/initializers/relationships", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    _emberData.default.Model.reopen({
      getChangedRelationships() {
        let changedRelationships = {};
        this.eachRelationship((name, descriptor) => {
          if (descriptor.kind == 'belongsTo') {
            const id = this.belongsTo(name).id();

            if (this.get('belongsToIdByName')[name] !== id) {
              changedRelationships[name] = id;
            }
          }
        });
        return changedRelationships;
      }

    });
  }

  var _default = {
    name: 'relationships',
    initialize
  };
  _exports.default = _default;
});