define("stock-control-front/models/purchase", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    quantity: {
      validators: [(0, _emberCpValidations.validator)('number', {
        allowString: true,
        gt: 0
      })]
    },
    unit: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('belongs-to')]
    },
    productToPurchase: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('belongs-to')]
    },
    purchaseInvoice: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('belongs-to')]
    }
  });

  var _default = _emberData.default.Model.extend(Validations, {
    quantity: _emberData.default.attr('number'),
    unit: _emberData.default.belongsTo('unit'),
    productToPurchase: _emberData.default.belongsTo('product-to-purchase'),
    purchaseInvoice: _emberData.default.belongsTo('purchase-invoice')
  });

  _exports.default = _default;
});