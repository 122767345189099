define("stock-control-front/components/address-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      updateAddress: function () {
        let address = this.get('address');
        address.validate().then(({
          validations
        }) => {
          if (validations.get('isValid')) {
            address.save().then(() => {
              this.set('editPressed', false);
            });
          }
        });
      },
      cancel: function () {
        this.get('address').rollbackAttributes();
        this.toggleProperty("editPressed");
      }
    },

    willDestroyElement() {
      this.get('address').rollbackAttributes();
    }

  });

  _exports.default = _default;
});