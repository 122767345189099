define("stock-control-front/helpers/wizard-step-class", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.wizardStepClass = wizardStepClass;
  _exports.default = void 0;

  function wizardStepClass(params) {
    let [step, currentStep] = params;

    if (step == currentStep) {
      return "m-wizard__step--current";
    } else if (step < currentStep) {
      return "m-wizard__step--done";
    } else {
      return "";
    }
  }

  var _default = Ember.Helper.helper(wizardStepClass);

  _exports.default = _default;
});