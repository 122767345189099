define("stock-control-front/helpers/person-type-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.personTypeLabel = personTypeLabel;
  _exports.default = void 0;

  function personTypeLabel(params) {
    let [personType] = params;
    const labels = {
      'natural': 'personType.natural.label',
      'legal': 'personType.legal.label'
    };
    return labels[personType];
  }

  var _default = Ember.Helper.helper(personTypeLabel);

  _exports.default = _default;
});