define("stock-control-front/models/product-to-purchase", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    quantity: {
      validators: [(0, _emberCpValidations.validator)('number', {
        allowString: true,
        gt: 0
      })]
    },
    unitCost: {
      validators: [(0, _emberCpValidations.validator)('number', {
        allowString: true,
        gt: 0
      })]
    },
    unitCostCurrency: {
      validators: [(0, _emberCpValidations.validator)('format', {
        regex: /^\$\s([1-9][0-9\.\,]+|[0-9]\.[0-9]*[1-9]+[0-9]*)$/
      })]
    },
    product: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        descriptionKey: 'purchase.product.notFound.message.label'
      })]
    },
    purchase: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('belongs-to')]
    }
  });

  var _default = _emberData.default.Model.extend(Validations, {
    quantity: _emberData.default.attr('number'),
    unitCost: _emberData.default.attr('number'),
    product: _emberData.default.belongsTo('product'),
    purchase: _emberData.default.belongsTo('purchase'),
    unitCostCurrency: Ember.computed('unitCost', {
      get() {
        return this.get('unitCost');
      },

      set(key, value) {
        this.set('unitCost', value.replace(/[^0-9\.-]+/g, ""));
        return value;
      }

    }),
    total: Ember.computed('quantity', 'unitCost', function () {
      return this.get('quantity') * this.get('unitCost');
    })
  });

  _exports.default = _default;
});