define("stock-control-front/admin/organizations/units/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentUser: Ember.computed.alias('session.currentUser'),
    queryParams: ['page', 'page_size'],
    page: 1,
    page_size: 5,
    actions: {
      addNew() {
        let unit = this.get('model').unit;
        unit.save().then(() => {
          this.send('refreshUnitsList');
        }, () => {
          console.log("The Unit of Measure couldn't be saved");
        });
      },

      deleteUnit(unit) {
        unit.destroyRecord().then(() => {
          this.send('refreshUnitsList');
        }).catch(() => {
          unit.rollbackAttributes();
        });
      }

    }
  });

  _exports.default = _default;
});