define("stock-control-front/components/client-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    page: 1,
    page_size: 3,
    searchClientEnabled: false,
    notFound: Ember.computed('clients', function () {
      if (this.get('clients') == undefined || this.get('clients') == null) {
        return false;
      }

      return !this.get('clients').get('length'); //Check this condition
    }),
    actions: {
      enableDisableSearch: function () {
        this.toggleProperty('searchClientEnabled');
      },
      doSearch: function () {
        this._search = Ember.run.debounce(this, this.search, 500);
      },
      findRecords: function (params) {
        params.name = this.get('client');

        if (params.name) {
          const organization = this.get('model').get('organization').get('id');
          this.get('store').query('client', {
            organization: organization,
            name: params.name,
            page_size: params.page_size,
            page: params.page
          }).then(clients => {
            this.set('clients', clients);
          });
        } else {
          this.set('clients', null);
        }
      },
      selectClient: function () {
        const client = this.get('store').peekRecord('client', this.get('selectedClient'));
        this.get('model').set('client', client);
        ['selectedClient', 'client', 'clients'].forEach(item => {
          this.set(item, null);
        });
        this.send('enableDisableSearch');
      }
    },
    search: function () {
      this.set('selectedClient', null);
      this.send('findRecords', {
        name: this.get('client'),
        page: this.get('page'),
        page_size: this.get('page_size')
      });
    }
  });

  _exports.default = _default;
});