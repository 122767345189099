define("stock-control-front/utils/search-dates-validations", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.endDate = _exports.startDate = void 0;
  const startDate = [(0, _emberCpValidations.validator)('presence', {
    presence: true,
    message: function () {
      return '';
    }
  })];
  _exports.startDate = startDate;
  const endDate = [(0, _emberCpValidations.validator)('presence', {
    presence: true,
    message: function () {
      return '';
    }
  })];
  _exports.endDate = endDate;
  var _default = {
    startDate,
    endDate
  };
  _exports.default = _default;
});