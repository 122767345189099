define("stock-control-front/components/sale-note-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    classNames: ['row'],
    actions: {
      showPreview: function () {
        let saleNote = this.get('saleNote');
        saleNote.set('saleDate', new Date());
        saleNote.validate().then(({
          validations
        }) => {
          if (validations.get('isValid')) {
            this.showSaleNotePreview();
          } else {
            this.set('didValidate', true);
          }
        });
      }
    }
  });

  _exports.default = _default;
});