define("stock-control-front/components/supplier-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'form',
    classNames: ['m-form', 'm-form--fit', 'm-form--label-align-right', 'm-form--group-seperator-dashed'],
    personTypes: function () {
      return [];
    },

    init() {
      this._super(...arguments);

      this.set('personTypes', [{
        key: 'natural',
        value: this.get('intl').t('personType.natural.label')
      }, {
        key: 'legal',
        value: this.get('intl').t('personType.legal.label')
      }]);
    },

    actions: {
      updatePersonType: function (supplier, personType) {
        supplier.set('personType', personType);
      },
      updateIdentifier: function (selectedValue) {
        let supplier = this.get('supplier');

        if (selectedValue) {
          supplier.set('taxIdentifier', null);
        } else {
          supplier.set('rfc', null);
        }
      }
    }
  });

  _exports.default = _default;
});