define("stock-control-front/admin/organizations/suppliers/payment/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      refresh: function () {
        this.send('refreshPaymentWidget');
      }
    }
  });

  _exports.default = _default;
});