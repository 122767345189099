define("stock-control-front/components/input-bootstrap-date-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['input-group', 'form-input-group'],
    classNameBindings: ['isValid:has-danger'],
    isActive: false,
    actions: {
      showDatePicker: function (datePickerName) {
        let datePicker = this.$().find("input[name=".concat(datePickerName, "]"));

        if (!this.get('isActive')) {
          datePicker.focus();
          this.set('isActive', true);
        } else {
          this.set('isActive', false);
        }
      },
      dateSelected: function () {
        this.set('isActive', false);
      }
    }
  });

  _exports.default = _default;
});