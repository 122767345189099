define("stock-control-front/translations/es-mx", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "accountStatement": {
      "client": {
        "empty": {
          "message": "El cliente debe ser seleccionado de la lista"
        },
        "search": {
          "label": "Cliente"
        },
        "title": "Estado de Cuenta por cliente"
      },
      "date": {
        "from": {
          "label": "Desde"
        },
        "placeholder": "Selecciona la fecha",
        "to": {
          "label": "Hasta"
        }
      },
      "detail": {
        "header": {
          "balance": {
            "label": "Saldo"
          },
          "concept": {
            "label": "Concepto"
          },
          "credit": {
            "label": "Abono"
          },
          "date": {
            "label": "Fecha"
          },
          "debit": {
            "label": "Cargo"
          },
          "reference": {
            "label": "Referencia"
          }
        }
      },
      "filter": {
        "client": {
          "placeholder": "Selecciona el cliente"
        },
        "supplier": {
          "placeholder": "Selecciona el proveedor"
        }
      },
      "label": "Estado de Cuenta",
      "notFound": {
        "message": "No hay transacciones para el estado de cuenta en el periodo."
      },
      "supplier": {
        "empty": {
          "message": "El proveedor debe ser seleccionado de la lista"
        },
        "search": {
          "label": "Proveedor"
        },
        "title": "Estado de Cuenta por proveedor"
      }
    },
    "address": {
      "city": {
        "label": "Ciudad / Delegación"
      },
      "colony": {
        "label": "Colonia"
      },
      "cp": {
        "label": "C.P",
        "placeholder": {
          "label": "Código postal"
        }
      },
      "list": {
        "empty": {
          "label": "No hay direcciones registradas aún."
        },
        "label": "Direcciones"
      },
      "state": {
        "label": "Estado"
      },
      "street": {
        "label": "Calle"
      },
      "title": {
        "label": "Dirección"
      }
    },
    "balance": {
      "client": {
        "title": "Saldos"
      },
      "export": {
        "all": {
          "pdf": "Exportar todos"
        }
      },
      "label": "Saldo"
    },
    "button": {
      "back": {
        "label": "Atrás"
      },
      "cancel": {
        "label": "Cancelar"
      },
      "confirm": {
        "save": {
          "label": "Confirmar y guardar"
        }
      },
      "continue": {
        "label": "Continuar"
      },
      "delete": {
        "label": "Eliminar"
      },
      "edit": {
        "label": "Editar"
      },
      "filter": {
        "label": "Filtrar"
      },
      "next": {
        "label": "Siguiente"
      },
      "previous": {
        "label": "Regresar"
      },
      "remove": {
        "label": "Quitar"
      },
      "save": {
        "label": "Guardar"
      },
      "search": {
        "label": "Buscar"
      },
      "select": {
        "label": "Seleccionar"
      },
      "show": {
        "label": "Ver"
      },
      "update": {
        "label": "Actualizar"
      }
    },
    "client": {
      "add": {
        "title": "Registrar Cliente"
      },
      "all": {
        "label": "Todos"
      },
      "balance": {
        "label": "Saldos"
      },
      "detail": {
        "label": "Detalle",
        "title": "Detalle del Cliente"
      },
      "email": {
        "label": "Correo electrónico",
        "placeholder": {
          "label": "correo@dominio.com"
        }
      },
      "initialBalance": {
        "amount": {
          "label": "Cantidad",
          "placeholder": "Cantidad"
        },
        "title": "Balance Inicial"
      },
      "list": {
        "empty": {
          "label": "No hay clientes registrados aún."
        },
        "label": "Clientes"
      },
      "menu": {
        "accountStatement": {
          "label": "Estado de Cuenta"
        },
        "add": {
          "label": "Agregar nuevo"
        },
        "balance": {
          "label": "Saldos"
        },
        "list": {
          "label": "Ver todos"
        },
        "payment": {
          "label": "Pagos"
        }
      },
      "modal": {
        "delete": {
          "header": "Eliminar Cliente",
          "text": "¿Estás seguro de que quieres eliminar al cliente {clientName}?"
        }
      },
      "name": {
        "label": "Nombre"
      },
      "new": {
        "label": "Nuevo Cliente"
      },
      "payment": {
        "add": {
          "title": "Registrar Pago"
        },
        "amountAndConcept": {
          "label": "Cantidad y Concepto"
        },
        "concept": {
          "placeholder": "Concepto"
        },
        "label": "Nuevo Pago",
        "saleNote": {
          "date": {
            "label": "Fecha",
            "placeholder": "Selecciona la fecha"
          },
          "label": "Nota de Venta",
          "number": "No. de Nota",
          "search": {
            "period": {
              "message": "Buscar por periodo",
              "notFound": "No hay notas de venta registradas en el periodo."
            }
          }
        },
        "steps": {
          "first": {
            "message": "Selecciona el Cliente"
          },
          "second": {
            "message": "Cantidad y Concepto"
          },
          "third": {
            "message": "Confirmación"
          }
        }
      },
      "personType": {
        "label": "Tipo de Persona"
      },
      "phone": {
        "label": "Teléfono"
      },
      "register": {
        "detail": {
          "accountStatement": {
            "initialBalance": {
              "label": "Balance Inicial"
            },
            "label": "Estado de Cuenta"
          },
          "information": {
            "label": "Información del Cliente",
            "title": "Detalle del Cliente"
          }
        },
        "steps": {
          "first": {
            "message": "Información del Cliente"
          },
          "second": {
            "message": "Estado de Cuenta"
          },
          "third": {
            "message": "Confirmación"
          }
        }
      },
      "rfc": {
        "label": "RFC"
      },
      "title": {
        "label": "Cliente"
      }
    },
    "date": {
      "from": {
        "label": "Desde"
      },
      "placeholder": "Selecciona la fecha",
      "to": {
        "label": "Hasta"
      }
    },
    "errors": {
      "accepted": "{description} must be accepted",
      "after": "{description} must be after {after}",
      "before": "{description} must be before {before}",
      "blank": "{description}",
      "collection": "{description} must be a collection",
      "confirmation": "{description} doesn't match {on}",
      "date": "{description} must be a valid date",
      "description": "This field",
      "email": "{description} must be a valid email address",
      "empty": "{description} can't be empty",
      "equalTo": "{description} must be equal to {is}",
      "even": "{description} must be even",
      "exclusion": "{description} is reserved",
      "greaterThan": "{description} must be greater than {gt}",
      "greaterThanOrEqualTo": "{description} must be greater than or equal to {gte}",
      "inclusion": "{description} is not included in the list",
      "invalid": "{description} is invalid",
      "lessThan": "{description} must be less than {lt}",
      "lessThanOrEqualTo": "{description} must be less than or equal to {lte}",
      "notANumber": "{description} must be a number",
      "notAnInteger": "{description} must be an integer",
      "odd": "{description} must be odd",
      "otherThan": "{description} must be other than {value}",
      "phone": "{description} must be a valid phone number",
      "positive": "{description} must be positive",
      "present": "{description} must be blank",
      "rfc": {
        "unique": "El RFC debe ser único"
      },
      "singular": "{description} can't be a collection",
      "tooLong": "{description} is too long (maximum is {max} characters)",
      "tooShort": "{description} is too short (minimum is {min} characters)",
      "url": "{description} must be a valid url",
      "wrongDateFormat": "{description} must be in the format of {format}",
      "wrongLength": "{description} is the wrong length (should be {is} characters)"
    },
    "home": {
      "label": "Inicio"
    },
    "inventory": {
      "reports": {
        "label": "Inventario",
        "products": {
          "date": {
            "from": {
              "label": "Desde"
            },
            "placeholder": {
              "label": "Selecciona la fecha"
            },
            "to": {
              "label": "Hasta"
            }
          },
          "entry": {
            "label": "Entrada"
          },
          "existence": {
            "label": "Existencia"
          },
          "label": "Productos",
          "output": {
            "label": "Salida"
          },
          "product": {
            "label": "Producto",
            "notFound": {
              "message": {
                "label": "El producto debe ser seleccionado de la lista"
              }
            }
          },
          "record": {
            "date": {
              "label": "Fecha"
            },
            "notFound": {
              "message": {
                "label": "No hay registros de inventario para el producto en el periodo."
              }
            },
            "purchase": {
              "label": "Compra"
            },
            "reference": {
              "label": "Referencia"
            },
            "return": {
              "reference": {
                "label": "Devolución"
              }
            },
            "sale": {
              "label": "Venta"
            }
          },
          "search": {
            "placeholder": {
              "label": "Buscar producto"
            }
          },
          "title": "Inventario por producto",
          "unit": {
            "label": "Unidad"
          }
        },
        "valuation": {
          "cost": {
            "header": {
              "label": "Costo"
            }
          },
          "date": {
            "header": {
              "label": "Fecha"
            }
          },
          "entries": {
            "empty": {
              "label": "No hay entradas del producto con existencias."
            }
          },
          "entry": {
            "quantity": {
              "header": {
                "label": "Entrada"
              }
            }
          },
          "existences": {
            "label": "Existencias"
          },
          "inventoryValue": {
            "header": {
              "label": "Valor de inventario"
            }
          },
          "label": "Valuación de inventario",
          "productExistences": {
            "header": {
              "label": "Existencias"
            }
          },
          "products": {
            "empty": {
              "label": "No hay productos registrados aún."
            },
            "notFound": {
              "label": "No hay resultados para la búqueda."
            }
          },
          "quantity": {
            "label": "Cantidad"
          },
          "title": "Valuación por producto",
          "unit": {
            "header": {
              "label": "Unidad"
            },
            "label": "Unidad"
          },
          "value": {
            "label": "Valor de inventario"
          }
        }
      }
    },
    "login": {
      "box": {
        "button": "Inciar",
        "placeholder": {
          "password": "Contraseña",
          "username": "Usuario"
        },
        "title": "Accede a tu cuenta"
      },
      "email": {
        "error": {
          "label": "Escribir una dirección de correo válida."
        }
      },
      "error": {
        "label": "Ocurrió un error al iniciar sesión"
      },
      "password": {
        "error": {
          "length": {
            "label": "El password debe tener al menos 6 caracteres."
          }
        }
      },
      "user": {
        "notFound": {
          "error": {
            "label": "Usuario o password incorrecto"
          }
        }
      }
    },
    "modal": {
      "accept": {
        "button": {
          "label": "Aceptar"
        }
      },
      "cancel": {
        "button": {
          "label": "Cancelar"
        }
      }
    },
    "organization": {
      "add": {
        "title": "Registrar Organización"
      },
      "all": {
        "label": "Todas"
      },
      "detail": {
        "label": "Detalle",
        "title": "Detalle de la Organización"
      },
      "email": {
        "label": "Correo electrónico",
        "placeholder": {
          "label": "correo@dominio.com"
        }
      },
      "list": {
        "empty": {
          "label": "No hay organizaciones registradas aún."
        },
        "label": "Organizaciones"
      },
      "name": {
        "label": "Nombre"
      },
      "new": {
        "label": "Nueva Organización"
      },
      "personType": {
        "label": "Tipo de Persona"
      },
      "phone": {
        "label": "Teléfono"
      },
      "rfc": {
        "label": "RFC"
      },
      "show": {
        "label": "Ver"
      }
    },
    "payment": {
      "amount": {
        "label": "Cantidad"
      },
      "button": {
        "confirm": "Confirmar"
      },
      "concept": {
        "label": "Concepto"
      },
      "detail": {
        "label": "Detalle de Pago"
      },
      "reference": {
        "label": "Referencia"
      },
      "saved": {
        "message": "El pago ha sido registrado"
      }
    },
    "personType": {
      "legal": {
        "label": "Moral"
      },
      "natural": {
        "label": "Física"
      }
    },
    "product": {
      "add": {
        "title": "Registrar Producto"
      },
      "all": {
        "label": "Todos"
      },
      "constraint": {
        "unique": {
          "name": {
            "organization": "El producto ya existe en la organización."
          }
        }
      },
      "description": {
        "label": "Descripción",
        "placeholder": {
          "label": "Agrega una descripción"
        }
      },
      "list": {
        "empty": {
          "label": "No hay productos registrados aún."
        },
        "label": "Productos"
      },
      "modal": {
        "text": "¿Estás seguro de que quieres eliminar el producto {productName}?"
      },
      "name": {
        "label": "Nombre",
        "placeholder": {
          "label": "Nombre del producto"
        }
      },
      "notFound": {
        "message": {
          "label": "Productos no encontrados."
        }
      },
      "search": {
        "placeholder": {
          "label": "Buscar"
        }
      },
      "show": {
        "label": "Ver"
      },
      "unit": {
        "label": "Unidad",
        "select": {
          "label": "-Seleccionar Unidad-"
        }
      }
    },
    "purchase": {
      "add": {
        "title": "Registrar Compra"
      },
      "all": {
        "label": "Todas"
      },
      "invoice": {
        "detail": {
          "label": "Detalle"
        },
        "label": "Factura de Compra",
        "total": {
          "label": "Total"
        }
      },
      "invoiceDate": {
        "label": "Fecha"
      },
      "invoiceNumber": {
        "label": "Factura No.",
        "placeholder": {
          "label": "No. de factura"
        }
      },
      "list": {
        "empty": {
          "label": "No hay facturas de compra registradas aún."
        },
        "label": "Compras",
        "ribbon": {
          "label": "Lista de Productos"
        }
      },
      "menu": {
        "add": {
          "label": "Aregar nueva"
        },
        "list": {
          "label": "Ver todas"
        }
      },
      "new": {
        "label": "Nueva Compra"
      },
      "product": {
        "add": {
          "button": {
            "label": "Agregar nuevo"
          },
          "message": {
            "label": "Agregar un producto a la compra"
          }
        },
        "label": "Producto",
        "notFound": {
          "message": {
            "label": "El producto debe ser seleccionado de la lista"
          }
        },
        "quantity": {
          "label": "Cantidad",
          "placeholder": {
            "label": "Cant."
          }
        },
        "search": {
          "placeholder": {
            "label": "Escribe el nombre del producto..."
          }
        },
        "unit": {
          "label": "Unidad"
        },
        "unitCost": {
          "label": "Costo Unitario"
        }
      },
      "quantity": {
        "label": "Cantidad de Compra",
        "placeholder": {
          "label": "Cant. de Compra"
        }
      },
      "supplier": {
        "add": {
          "label": "Agregar"
        },
        "label": "Proveedor",
        "notFound": {
          "message": {
            "label": "Proveedor no encontrado"
          }
        },
        "required": {
          "message": {
            "label": "Seleccionar un proveedor"
          }
        },
        "search": {
          "label": "Buscar..."
        }
      },
      "title": {
        "label": "Compra"
      },
      "total": {
        "label": "Total"
      },
      "unit": {
        "label": "Unidad de Compra",
        "notSelected": {
          "label": "-Seleccionar-"
        }
      }
    },
    "purchaseInvoice": {
      "add": {
        "title": "Registrar Factura de Compra"
      },
      "date": {
        "label": "Fecha"
      },
      "filter": {
        "endDate": {
          "label": "Hasta"
        },
        "header": {
          "label": "Filtrar"
        },
        "invoiceNumber": {
          "label": "Factura No.",
          "placeholder": {
            "label": "No. de factura"
          }
        },
        "startDate": {
          "label": "Desde"
        },
        "supplier": {
          "label": "Proveedor",
          "search": {
            "placeholder": {
              "label": "Escribe el nombre del proveedor"
            }
          }
        }
      },
      "list": {
        "notFound": {
          "message": {
            "label": "Facturas de compra no encontradas."
          }
        }
      },
      "number": {
        "label": "Factura No."
      },
      "save": {
        "error": "Ha ocurrido un error al registrar la factura de compra"
      },
      "title": {
        "label": "Factura de Compra"
      }
    },
    "sale": {
      "all": {
        "label": "Todas"
      },
      "list": {
        "label": "Ventas",
        "ribbon": {
          "label": "Productos Vendidos"
        }
      },
      "product": {
        "add": {
          "message": {
            "label": "Agregar un producto a la venta"
          }
        },
        "label": "Producto",
        "notFound": {
          "message": {
            "label": "El producto debe ser seleccionado de la lista"
          }
        },
        "quantity": {
          "label": "Cantidad",
          "placeholder": {
            "label": "Cant."
          }
        },
        "search": {
          "placeholder": {
            "label": "Escribe el nombre del producto..."
          }
        },
        "stockEmpty": {
          "message": {
            "label": "No hay suficiente producto en el inventario"
          }
        },
        "unit": {
          "label": "Unidad"
        },
        "unitPrice": {
          "label": "Precio Unitario"
        }
      },
      "quantity": {
        "label": "Cantidad de Venta",
        "placeholder": {
          "label": "Cant. de Venta"
        }
      },
      "total": {
        "label": "Total"
      },
      "unit": {
        "label": "Unidad de Venta",
        "notSelected": {
          "label": "-Seleccionar-"
        },
        "stockEmpty": {
          "message": {
            "label": "No hay suficientes existencias"
          }
        }
      }
    },
    "saleNote": {
      "add": {
        "title": "Registrar Nota de Venta"
      },
      "cancel": {
        "confirm": {
          "label": "¿Estás seguro de que quieres cancelar la Nota de Venta?"
        },
        "modal": {
          "header": {
            "label": "Cancelar Nota de Venta"
          }
        }
      },
      "client": {
        "add": {
          "label": "Agregar"
        },
        "label": "Cliente",
        "notFound": {
          "message": {
            "label": "Cliente no encontrado"
          }
        },
        "required": {
          "message": {
            "label": "Seleccionar un cliente"
          }
        },
        "search": {
          "label": "Escribe el nombre del cliente"
        }
      },
      "detail": {
        "label": "Detalle"
      },
      "errors": {
        "insufficientStock": "No hay suficientes productos en el inventario."
      },
      "filter": {
        "client": {
          "label": "Cliente",
          "search": {
            "placeholder": {
              "label": "Escribe el nombre del cliente"
            }
          }
        },
        "date": {
          "placeholder": {
            "label": "Selecciona la fecha"
          }
        },
        "endDate": {
          "label": "Hasta"
        },
        "header": {
          "label": "Filtrar"
        },
        "startDate": {
          "label": "Desde"
        }
      },
      "invoiceNumber": {
        "label": "# Nota"
      },
      "list": {
        "empty": {
          "label": "No hay notas de venta registradas aún."
        },
        "label": "Notas Ventas",
        "notFound": {
          "message": {
            "label": "Notas de venta no encontradas."
          }
        }
      },
      "menu": {
        "add": {
          "label": "Agregar nueva"
        },
        "list": {
          "label": "Notas de venta"
        }
      },
      "new": {
        "label": "Nueva Venta"
      },
      "organization": {
        "email": {
          "label": "Email"
        },
        "phone": {
          "label": "Teléfono"
        }
      },
      "product": {
        "add": {
          "button": {
            "label": "Agregar nuevo"
          }
        }
      },
      "saleDate": {
        "label": "Fecha"
      },
      "status": {
        "canceled": {
          "label": "Cancelada"
        },
        "created": {
          "label": "Creada"
        },
        "label": "Estatus"
      },
      "title": {
        "label": "Nota de Venta"
      },
      "total": {
        "label": "Total"
      }
    },
    "searchInput": {
      "loading": {
        "message": "Cargando opciones..."
      },
      "notFound": {
        "message": "No hay resultados"
      },
      "search": {
        "message": "Escribe para buscar"
      }
    },
    "supplier": {
      "add": {
        "title": "Registrar Proveedor"
      },
      "all": {
        "label": "Todos"
      },
      "detail": {
        "label": "Detalle",
        "title": "Detalle del Proveedor"
      },
      "email": {
        "label": "Correo electrónico",
        "placeholder": {
          "label": "correo@dominio.com"
        }
      },
      "initialBalance": {
        "amount": {
          "label": "Cantidad",
          "placeholder": "Cantidad"
        },
        "title": "Balance Inicial"
      },
      "list": {
        "empty": {
          "label": "No hay proveedores registrados aún."
        },
        "label": "Proveedores"
      },
      "menu": {
        "accountStatement": {
          "label": "Estado de Cuenta"
        },
        "add": {
          "label": "Agregar nuevo"
        },
        "list": {
          "label": "Ver todos"
        },
        "payment": {
          "label": "Pagos"
        }
      },
      "modal": {
        "delete": {
          "header": "Eliminar proveedor",
          "text": "¿Estás seguro de que quieres eliminar al proveedor {supplierName}?"
        }
      },
      "name": {
        "label": "Nombre"
      },
      "nationalityType": {
        "foreign": {
          "label": "Extranjero"
        },
        "label": "Tipo de Proveedor",
        "national": {
          "label": "Nacional"
        }
      },
      "new": {
        "label": "Nuevo Proveedor"
      },
      "payment": {
        "add": {
          "title": "Registrar Pago"
        },
        "amountAndConcept": {
          "label": "Cantidad y Concepto"
        },
        "concept": {
          "placeholder": "Concepto"
        },
        "label": "Nuevo Pago",
        "purchaseInvoice": {
          "date": {
            "label": "Fecha",
            "placeholder": "Selecciona la fecha"
          },
          "label": "Factura de Compra",
          "number": "Factura No.",
          "search": {
            "period": {
              "message": "Buscar por periodo",
              "notFound": "No hay facturas de compras registradas en el periodo."
            }
          }
        },
        "steps": {
          "first": {
            "message": "Selecciona el Proveedor"
          },
          "second": {
            "message": "Cantidad y Concepto"
          },
          "third": {
            "message": "Confirmación"
          }
        }
      },
      "personType": {
        "label": "Tipo de Persona"
      },
      "phone": {
        "label": "Teléfono"
      },
      "register": {
        "detail": {
          "accountStatement": {
            "initialBalance": {
              "label": "Balance Inicial"
            },
            "label": "Estado de Cuenta"
          },
          "information": {
            "label": "Información del Proveedor",
            "title": "Detalle del Proveedor"
          }
        },
        "steps": {
          "first": {
            "message": "Información del Proveedor"
          },
          "second": {
            "message": "Estado de Cuenta"
          },
          "third": {
            "message": "Confirmación"
          }
        }
      },
      "rfc": {
        "label": "RFC"
      },
      "show": {
        "label": "Ver"
      },
      "taxId": {
        "label": "Tax Id"
      },
      "title": {
        "label": "Proveedor"
      }
    },
    "transaction": {
      "instance": {
        "canceled": {
          "concept": "Cancelación"
        }
      },
      "payment": "Pago"
    },
    "unit": {
      "add": {
        "title": "Agregar Unidad de Medida"
      },
      "all": {
        "label": "Todas"
      },
      "list": {
        "empty": {
          "label": "No hay unidades de medida registradas aún."
        },
        "label": "Unidades de Medida"
      },
      "menu": {
        "list": {
          "label": "Unidades"
        }
      },
      "modal": {
        "delete": {
          "header": "Eliminar Unidad",
          "text": "¿Estás seguro de que quieres eliminar la unidad {unitName}?"
        }
      },
      "name": {
        "label": "Nombre"
      }
    }
  };
  _exports.default = _default;
});