define("stock-control-front/admin/organizations/sale-notes/add/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Gn++Tq2O",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"page-title\",null,[[\"section\",\"sub-section\"],[[29,\"t\",[\"saleNote.list.label\"],null],[29,\"t\",[\"saleNote.new.label\"],null]]]],false],[0,\"\\n\"],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"m-content\"],[9],[0,\"\\n  \"],[1,[29,\"sale-note-register\",null,[[\"saleNote\",\"units\",\"products\",\"organization\",\"didSave\",\"showLoader\",\"hideLoader\"],[[25,[\"model\",\"saleNote\"]],[25,[\"model\",\"units\"]],[25,[\"model\",\"products\"]],[25,[\"model\",\"organization\"]],[29,\"action\",[[24,0,[]],\"didSave\"],null],[29,\"action\",[[24,0,[]],\"showLoaderComponent\"],null],[29,\"action\",[[24,0,[]],\"hideLoaderComponent\"],null]]]],false],[0,\"\\n\\n\"],[10],[0,\"\\n\"],[0,\"\\n\"],[1,[23,\"outlet\"],false],[0,\"\\n\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "stock-control-front/admin/organizations/sale-notes/add/template.hbs"
    }
  });

  _exports.default = _default;
});