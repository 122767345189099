define("stock-control-front/helpers/sale-note-status-label", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.saleNoteStatusLabel = saleNoteStatusLabel;
  _exports.default = void 0;

  function saleNoteStatusLabel(params
  /*, hash*/
  ) {
    let [status] = params;
    const labels = {
      'created': 'saleNote.status.created.label',
      'canceled': 'saleNote.status.canceled.label'
    };
    return labels[status];
  }

  var _default = Ember.Helper.helper(saleNoteStatusLabel);

  _exports.default = _default;
});