define("stock-control-front/utils/client-account-statement-validations", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.client = void 0;
  const client = [(0, _emberCpValidations.validator)('presence', {
    presence: true,
    message: function () {
      let intl = this.model.get('intl');
      return intl.t('accountStatement.client.empty.message');
    }
  })];
  _exports.client = client;
  var _default = {
    client
  };
  _exports.default = _default;
});