define("stock-control-front/authenticators/stockcontrol", ["exports", "ember-simple-auth/authenticators/oauth2-password-grant", "stock-control-front/config/environment"], function (_exports, _oauth2PasswordGrant, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _oauth2PasswordGrant.default.extend({
    refreshAccessTokens: true,
    serverTokenEndpoint: "".concat(_environment.default.host, "/api/token"),

    makeRequest(url, data) {
      const options = {
        url: url,
        data: JSON.stringify(data),
        type: 'POST',
        headers: {
          'Content-Type': 'application/vnd.api+json',
          'Accept': 'application/vnd.api+json'
        }
      };
      return Ember.$.ajax(options);
    }

  });

  _exports.default = _default;
});