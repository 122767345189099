define("stock-control-front/components/editable-row", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'tr',
    editPressed: false,

    init() {
      this._super(...arguments);

      Ember.defineProperty(this, 'row', Ember.computed(() => {
        return {
          isSimpleRow: true
        };
      }));
      Ember.defineProperty(this, 'editableRow', Ember.computed(() => {
        return {
          isEditableRow: true
        };
      }));
    },

    actions: {
      showDeleteModal: function () {
        this.showDeleteModal(this.get('model'));
      },
      edit: function () {
        this.set('editPressed', true);
      },
      cancelEdit: function (model) {
        model.rollbackAttributes();
        this.set('editPressed', false);
      },
      update: function (model) {
        if (model.get('hasDirtyAttributes') || Object.keys(model.getChangedRelationships()).length > 0) {
          model.validate().then(({
            validations
          }) => {
            if (validations.get('isValid')) {
              this.update(model);
              this.set('editPressed', false);
            }
          });
        } else {
          this.set('editPressed', false);
        }
      }
    },

    willDestroyElement() {
      this._super(...arguments);

      if (this.get('model').get('hasDirtyAttributes') || Object.keys(this.get('model').getChangedRelationships()).length > 0) {
        this.get('model').rollbackAttributes();
      }
    }

  });

  _exports.default = _default;
});