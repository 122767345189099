define("stock-control-front/components/purchases-detail", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    actions: {
      downloadFile: function () {//Will download the file
      }
    },
    totalAmount: Ember.computed('purchases', function () {
      let purchases = this.get('purchases');
      return _emberData.default.PromiseObject.create({
        promise: Ember.RSVP.all(purchases.map(purchase => {
          return purchase.get('productToPurchase').then(productToPurchase => {
            return productToPurchase.get('total');
          });
        })).then(function (result) {
          return result.reduce((prev, current) => {
            return prev + current;
          }, 0);
        })
      });
    })
  });

  _exports.default = _default;
});