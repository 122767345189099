define("stock-control-front/instance-initializers/intl", ["exports", "stock-control-front/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    let intl = appInstance.lookup('service:intl');
    intl.set('locale', calculateLocale(intl.get('locales')));
  }

  var _default = {
    name: 'intl',
    initialize: initialize
  };
  _exports.default = _default;

  function calculateLocale(locales) {
    let language = _environment.default.intl.defaultLocale;

    if (window && window.navigator) {
      language = navigator.languages[0] || navigator.language || navigator.userLanguage;
    }

    let currentLocale = locales.find(locale => {
      return locale.indexOf(language.toLowerCase()) == 0;
    });
    if (currentLocale === undefined) return _environment.default.intl.defaultLocale;else return currentLocale.toLowerCase();
  }
});