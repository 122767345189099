define("stock-control-front/components/sale-notes-list", ["exports", "ember-cp-validations", "stock-control-front/utils/filter-dates-validations", "moment"], function (_exports, _emberCpValidations, _filterDatesValidations, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'filter.startDate': _filterDatesValidations.startDate,
    'filter.endDate': _filterDatesValidations.endDate
  });

  var _default = Ember.Component.extend(Validations, {
    store: Ember.inject.service(),
    page_size: 10,
    didValidate: false,
    hasBeenFiltered: false,
    loadedList: false,

    init() {
      this._super(...arguments);

      this.get('store').query('saleNote', {
        organization: this.get('organization').get('id')
      }).then(saleNotes => {
        this.set('loadedList', true);
        this.set('saleNotes', saleNotes);
      });
      Ember.defineProperty(this, 'filter', Ember.computed(() => {
        return {
          startDate: null,
          endDate: null
        };
      }));
    },

    actions: {
      setClient: function (selectedClient) {
        if (selectedClient) {
          Ember.set(this, 'selectedClient', selectedClient);
          Ember.set(this, 'client', selectedClient);
        } else {
          Ember.set(this, 'client', null);
          Ember.set(this, 'selectedClient', null);
        }
      },
      doSearch: function (term) {
        return new Ember.RSVP.Promise((resolve, reject) => {
          this._search = Ember.run.debounce(this, this._performSearch, term, resolve, reject, 300);
        });
      },
      findClients: function (resolve, reject, params) {
        const organization = this.get('saleNotes').query.organization;
        return this.get('store').query('client', {
          organization: organization,
          name: params.name
        }).then(clients => {
          let clientsList = clients.map(client => {
            return {
              id: client.get('id'),
              name: client.get('name')
            };
          });
          resolve(clientsList);
        }, reject);
      },
      filterSaleNotes: function () {
        if (this.get('validations.isValid')) {
          if (this.get('client')) {
            this.set('client_id', this.get('client').id);
          } else {
            this.set('client_id', null);
          }

          if (this.isValidDate(Ember.get(this, 'filter.startDate')) && this.isValidDate(Ember.get(this, 'filter.endDate'))) {
            this.set('start_date', (0, _moment.default)(Ember.get(this, 'filter.startDate')).format('YYYY-MM-DD'));
            this.set('end_date', (0, _moment.default)(Ember.get(this, 'filter.endDate')).format('YYYY-MM-DD'));
          } else {
            this.setProperties({
              'start_date': null,
              'end_date': null
            });
          }

          this.send('getSaleNotes', {
            organization: this.get('organization').get('id'),
            page: 1,
            page_size: 10
          }, () => {
            Ember.set(this, 'hasBeenFiltered', true);
          });
        } else {
          Ember.set(this, 'didValidate', true);
        }
      },

      findRecords(params, callback) {
        this.send('getSaleNotes', {
          organization: this.get('organization').get('id'),
          page: params.page,
          page_size: params.page_size
        });
      },

      getSaleNotes: function (params, callback) {
        callback = callback || function () {};

        ['client_id', 'start_date', 'end_date'].forEach(key => {
          if (Ember.get(this, key) !== undefined && Ember.get(this, key) !== null && Ember.get(this, key) !== "") {
            params[key] = Ember.get(this, key);
          }
        });
        this.get('store').query('saleNote', params).then(saleNotes => {
          Ember.set(this, 'saleNotes', saleNotes);
          callback();
        });
      }
    },

    _performSearch(term, resolve, reject) {
      this.send('findClients', resolve, reject, {
        name: term
      });
    },

    isValidDate: function (date) {
      return date !== null && date !== undefined;
    }
  });

  _exports.default = _default;
});