define("stock-control-front/helpers/not", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.not = not;
  _exports.default = void 0;

  function not(params
  /*, hash*/
  ) {
    let expression1 = params[0];
    return !expression1;
  }

  var _default = Ember.Helper.helper(not);

  _exports.default = _default;
});