define("stock-control-front/components/page-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    classNames: ["m-grid", "m-grid--hor", "m-grid--root", "m-page"],
    isLeftMenuOpen: false,
    currentUser: Ember.computed.alias('session.currentUser'),
    actions: {
      logout: function () {
        this.logout();
      }
    }
  });

  _exports.default = _default;
});