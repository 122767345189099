define("stock-control-front/components/supplier-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    page: 1,
    page_size: 3,
    notFound: Ember.computed('suppliers', function () {
      if (this.get('suppliers') === undefined || this.get('suppliers') === null) {
        return false;
      }

      return !this.get('suppliers').get('length');
    }),
    actions: {
      enableDisableSearch: function () {
        let searchEnabled = this.get('searchSupplierEnabled');
        this.set('searchSupplierEnabled', !searchEnabled);
      },
      doSearch: function () {
        this._search = Ember.run.debounce(this, this.search, 500);
      },
      findRecords: function (params) {
        params.name = this.get('supplier');

        if (params.name) {
          const organization = this.get('model').get('organization').get('id');
          this.get('store').query('supplier', {
            organization: organization,
            name: params.name,
            page_size: params.page_size,
            page: params.page
          }).then(suppliers => {
            this.set('suppliers', suppliers);
          });
        } else {
          this.set('suppliers', null);
        }
      },
      selectSupplier: function () {
        const supplier = this.get('store').peekRecord('supplier', this.get('selectedSupplier'));
        this.get('model').set('supplier', supplier);
        ['selectedSupplier', 'supplier', 'suppliers'].forEach(item => {
          this.set(item, null);
        });
        this.send('enableDisableSearch');
      }
    },
    search: function () {
      this.set('selectedSupplier', null);
      this.send("findRecords", {
        name: this.get('supplier'),
        page: this.get('page'),
        page_size: this.get('page_size')
      });
    },

    willDestroyElement() {
      this._super(...arguments);

      Ember.run.cancel(this._search);
    }

  });

  _exports.default = _default;
});