define("stock-control-front/components/sale-note-detail", ["exports", "stock-control-front/config/environment", "ember-cli-file-saver/mixins/file-saver"], function (_exports, _environment, _fileSaver) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend(_fileSaver.default, {
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),
    showCancelModal: false,

    init() {
      this._super(...arguments);

      Ember.defineProperty(this, 'address', Ember.computed.alias('saleNote.client.addresses.firstObject'));
      Ember.defineProperty(this, 'organizationAddress', Ember.computed.alias('saleNote.organization.addresses.firstObject'));
    },

    actions: {
      downloadFile: function () {
        this.get('ajax').request("".concat(_environment.default.host, "/api/sale-notes/").concat(this.get('saleNote').get('id'), "/pdf"), {
          dataType: 'arraybuffer',
          options: {
            arraybuffer: true
          },
          headers: {
            'Content-Type': 'application/vnd.api+json',
            'Authorization': "Bearer ".concat(this.get('session').get('session.content.authenticated.access_token'))
          }
        }).then(content => {
          this.saveFileAs("note-".concat(this.get('saleNote').get('id'), ".pdf"), content, 'application/pdf');
        });
      },
      downloadReceipt: function () {
        this.get('ajax').request("".concat(_environment.default.host, "/api/sale-notes/").concat(this.get('saleNote').get('id'), "/receipt"), {
          dataType: 'arraybuffer',
          options: {
            arraybuffer: true
          },
          headers: {
            'Content-Type': 'application/vnd.api+json',
            'Authorization': "Bearer ".concat(this.get('session').get('session.content.authenticated.access_token'))
          }
        }).then(content => {
          this.saveFileAs("note-".concat(this.get('saleNote').get('id'), ".jpg"), content, 'image/jpeg');
        });
      },
      showCancelNoteModal: function () {
        this.set('showCancelModal', true);
      },
      cancelSaleNote: function () {
        this.cancelSaleNote();
      },
      closeCancelModal: function () {
        this.set('showCancelModal', false);
      }
    }
  });

  _exports.default = _default;
});