define("stock-control-front/admin/organizations/inventory/inventory-valuation/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "m0ViF5jh",
    "block": "{\"symbols\":[],\"statements\":[[4,\"is-admin-user\",null,[[\"user\"],[[25,[\"currentUser\"]]]],{\"statements\":[[0,\"  \"],[1,[29,\"page-title\",null,[[\"section\",\"sub-section\"],[[29,\"t\",[\"inventory.reports.label\"],null],[29,\"t\",[\"inventory.reports.valuation.label\"],null]]]],false],[0,\"\\n\"],[0,\"  \\n  \\n\"],[0,\"  \"],[7,\"div\"],[11,\"class\",\"m-content\"],[9],[0,\"\\n    \"],[1,[29,\"inventory-valuation\",null,[[\"inventoryProducts\",\"findInventoryValuation\"],[[25,[\"model\",\"inventoryProducts\"]],[29,\"action\",[[24,0,[]],\"findInventoryValuation\"],null]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[0,\"  \"],[1,[23,\"outlet\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "stock-control-front/admin/organizations/inventory/inventory-valuation/template.hbs"
    }
  });

  _exports.default = _default;
});