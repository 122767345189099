define("stock-control-front/components/filter-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    value: '',
    autocompleteData: () => {
      return [];
    },

    init() {
      this._super(...arguments);

      let label = this.get('label-attr');
      let valueAttribute = this.get('value-attr');
      this.set('autocompleteData', []);

      if (this.get('data')) {
        this.get('data').forEach(record => {
          this.get('autocompleteData').push({
            label: record.get(label),
            value: record.get(valueAttribute)
          });
        });
      }

      Ember.defineProperty(this, "inputName", Ember.computed(() => {
        return "filter-list-input" + (this.get('name') || '');
      }));
    },

    didInsertElement() {
      new autoComplete({
        selector: "input[name=\"".concat(this.get('inputName'), "\"]"),
        minChars: 1,
        source: (term, suggest) => {
          let list = this.get('autocompleteData').filter(item => {
            return item.label.toLowerCase().indexOf(term.toLowerCase()) > -1;
          });
          suggest(list);
        },
        renderItem: (item, search) => {
          search = search.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
          let re = new RegExp("(" + search.split(' ').join('|') + ")", "gi");
          return "<div class=\"autocomplete-suggestion\" data-id=".concat(item.value, " data-val=\"").concat(item.label, "\">") + "".concat(item.label.replace(re, "<b>$1</b>"), "</div>");
        },
        onSelect: (event, term, item) => {
          this.set('value', term);
          this.set('selectedItem', term);
          const itemValue = item.getAttribute('data-id');
          this.selected(this.get('model'), itemValue);
        }
      });
    },

    actions: {
      focusOutAction: function () {
        this.set('showError', true);
        let selectedValue = this.get('autocompleteData').filter(item => {
          return item.label.toLowerCase().trim() === this.get('value').trim().toLowerCase();
        })[0];

        if (selectedValue) {
          this.set('selectedItem', selectedValue.label);
          this.selected(this.get('model'), selectedValue.value);
        } else {
          this.notSelected(this.get('model'));
        }
      }
    }
  });

  _exports.default = _default;
});