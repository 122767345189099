define("stock-control-front/templates/components/link-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HtJL0uX7",
    "block": "{\"symbols\":[],\"statements\":[[7,\"span\"],[11,\"class\",\"m-menu__item-here\"],[9],[10],[0,\"\\n\"],[7,\"i\"],[12,\"class\",[30,[\"m-menu__link-icon \",[25,[\"item\",\"icon_class\"]]]]],[9],[10],[0,\"\\n\"],[7,\"span\"],[11,\"class\",\"m-menu__link-text\"],[9],[1,[29,\"t\",[[25,[\"item\",\"title\"]]],null],false],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "stock-control-front/templates/components/link-content.hbs"
    }
  });

  _exports.default = _default;
});