define("stock-control-front/utils/filter-dates-validations", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.endDate = _exports.startDate = void 0;
  const startDate = [(0, _emberCpValidations.validator)('presence', {
    presence: Ember.computed.notEmpty('model.filter.endDate')
  })];
  _exports.startDate = startDate;
  const endDate = [(0, _emberCpValidations.validator)('presence', {
    presence: Ember.computed.notEmpty('model.filter.startDate')
  })];
  _exports.endDate = endDate;
});