define("stock-control-front/components/products-inventory", ["exports", "stock-control-front/utils/product-inventory-validations", "stock-control-front/utils/search-dates-validations", "ember-cp-validations"], function (_exports, _productInventoryValidations, _searchDatesValidations, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'startDate': _searchDatesValidations.startDate,
    'endDate': _searchDatesValidations.endDate,
    'product': _productInventoryValidations.product
  });

  var _default = Ember.Component.extend(Validations, {
    didValidate: false,

    init() {
      this._super(...arguments);

      Ember.defineProperty(this, 'today', Ember.computed(() => {
        return new Date();
      })), Ember.defineProperty(this, 'widgetPositioning', Ember.computed(() => {
        return {
          horizontal: 'left',
          vertical: 'bottom'
        };
      }));
    },

    actions: {
      selectProductToSearch: function (productInventory, productId) {
        Ember.set(productInventory, "product", productId);
      },
      removeProductToSearch: function (productInventory) {
        Ember.set(productInventory, "product", null);
      },
      searchProductInventory: function () {
        if (this.get('validations.isValid')) {
          this.searchProductInventory({
            startDate: this.get('startDate'),
            endDate: this.get('endDate'),
            product: this.get('product')
          });
        } else {
          this.set('didValidate', true);
        }
      },
      showDatePicker: function (datePickerName) {
        let datePicker = this.$().find("input[name=".concat(datePickerName, "]"));
        datePicker.focus();
      }
    }
  });

  _exports.default = _default;
});