define("stock-control-front/components/search-sale-note", ["exports", "ember-cp-validations", "stock-control-front/utils/search-dates-validations", "moment"], function (_exports, _emberCpValidations, _searchDatesValidations, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'filter.startDate': _searchDatesValidations.startDate,
    'filter.endDate': _searchDatesValidations.endDate
  });

  var _default = Ember.Component.extend(Validations, {
    store: Ember.inject.service('store'),
    isStartDateInvalid: Ember.computed.and('validations.attrs.filter.startDate.isInvalid', 'showDateValidations'),
    isEndDateInvalid: Ember.computed.and('validations.attrs.filter.endDate.isInvalid', 'showDateValidations'),
    selectedSaleNote: null,
    page_size: 10,
    notFound: false,
    saleNote: null,

    init() {
      this._super(...arguments);

      Ember.defineProperty(this, 'searchEnabled', Ember.computed(() => {
        if (this.get('saleNote')) return false;
        return true;
      }));
      Ember.defineProperty(this, 'today', Ember.computed(() => {
        return new Date();
      }));
      Ember.defineProperty(this, 'filter', Ember.computed(() => {
        return {
          startDate: null,
          endDate: null
        };
      }));
    },

    actions: {
      searchSaleNotes: function () {
        if (this.get('validations.isValid')) {
          this.setProperties({
            'start_date': (0, _moment.default)(this.get('filter.startDate')).format('YYYY-MM-DD'),
            'end_date': (0, _moment.default)(this.get('filter.endDate')).format('YYYY-MM-DD')
          });
          this.send('getSaleNotes', {
            page: 1,
            page_size: 10
          });
        } else {
          this.set('showDateValidations', true);
        }
      },
      selectSaleNote: function () {
        const saleNote = this.get('store').peekRecord('saleNote', this.get('selectedSaleNote'));
        this.set('saleNote', saleNote);
        this.selectReference(saleNote);
        ['selectedSaleNote', 'saleNotes'].forEach(key => {
          this.set(key, null);
        });
        this.toggleProperty('searchEnabled');
      },
      removeSaleNote: function () {
        this.removeReference();
        this.set('saleNote', null);
        ['saleNote', 'saleNotes', 'filter.startDate', 'filter.endDate'].forEach(key => {
          this.set(key, null);
        });
        this.set('searchEnabled', true);
      },
      editSaleNote: function () {
        this.toggleProperty('searchEnabled');
      },
      findRecords: function (params) {
        this.send('getSaleNotes', params);
      },
      getSaleNotes: function (params) {
        this.get('store').query('sale-note', {
          organization: this.get('model').get('organization').get('id'),
          client_id: this.get('model').get('client').get('id'),
          start_date: this.get('start_date'),
          end_date: this.get('end_date'),
          page: params.page,
          page_size: params.page_size
        }).then(saleNotes => {
          this.set('saleNotes', saleNotes);

          if (!saleNotes.length) {
            this.set('notFound', true);
          }
        });
      }
    }
  });

  _exports.default = _default;
});