define("stock-control-front/admin/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HBBa+3qk",
    "block": "{\"symbols\":[],\"statements\":[[4,\"page-content\",null,[[\"logout\"],[[29,\"action\",[[24,0,[]],\"logout\"],null]]],{\"statements\":[[0,\"  \"],[1,[23,\"outlet\"],false],[0,\"\\n  \"],[1,[29,\"loading-spinner\",null,[[\"isLoading\"],[[25,[\"isLoading\"]]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "stock-control-front/admin/template.hbs"
    }
  });

  _exports.default = _default;
});