define("stock-control-front/components/unit-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    didValidate: Ember.computed.and('displayValidations', 'isInvalid'),
    displayValidations: false,

    init() {
      this._super(...arguments);

      Ember.defineProperty(this, 'isInvalid', Ember.computed.not('unit.validations.isValid').readOnly());
    },

    actions: {
      addNew: function () {
        let unit = this.get('unit');
        unit.validate().then(({
          validations
        }) => {
          if (validations.get('isValid')) {
            this.addNew();
          } else {
            this.set('displayValidations', true);
          }
        });
      }
    }
  });

  _exports.default = _default;
});