define("stock-control-front/admin/organizations/clients/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1yLOucrG",
    "block": "{\"symbols\":[],\"statements\":[[4,\"is-admin-user\",null,[[\"user\"],[[25,[\"currentUser\"]]]],{\"statements\":[[0,\"  \"],[1,[29,\"page-title\",null,[[\"section\",\"sub-section\"],[[29,\"t\",[\"client.list.label\"],null],[29,\"t\",[\"client.all.label\"],null]]]],false],[0,\"\\n\"],[0,\"  \\n\"],[0,\"  \"],[7,\"div\"],[11,\"class\",\"m-content\"],[9],[0,\"\\n\"],[0,\"    \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n\"],[0,\"      \"],[7,\"div\"],[11,\"class\",\"col-lg-12\"],[9],[0,\"\\n        \"],[1,[29,\"clients-list\",null,[[\"clients\",\"deleteClient\"],[[25,[\"model\"]],[29,\"action\",[[24,0,[]],\"deleteClient\"],null]]]],false],[0,\"\\n      \"],[10],[0,\"\\n\"],[0,\"    \"],[10],[0,\"\\n\"],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "stock-control-front/admin/organizations/clients/template.hbs"
    }
  });

  _exports.default = _default;
});