define("stock-control-front/components/supplier-register", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    initialBalanceCurrency: {
      validators: [(0, _emberCpValidations.validator)('format', {
        regex: /^\$\s([0-9][0-9\.\,]+|[0-9]\.[0-9]*[1-9]+[0-9]*)$|(^0$)/
      })]
    }
  });

  var _default = Ember.Component.extend(Validations, {
    didValidate: Ember.computed.and('displayValidations', 'isInvalid'),
    displayValidations: false,
    startStep: 1,
    currentStep: 1,
    totalSteps: 3,
    progressBarSelector: '.progress-bar',
    initialBalanceCurrency: Ember.computed('supplier', {
      get() {
        return this.get('supplier.initialBalance');
      },

      set(key, value) {
        this.set('supplier.initialBalance', value.replace(/[^0-9\.-]+/g, ""));
        return value;
      }

    }),
    steps: Ember.computed(function () {
      return [{
        step: 1,
        message: this.get('intl').t('supplier.register.steps.first.message'),
        icon: 'icon-moon-truck'
      }, {
        step: 2,
        message: this.get('intl').t('supplier.register.steps.second.message'),
        icon: 'icon-moon-account-statement'
      }, {
        step: 3,
        message: this.get('intl').t('supplier.register.steps.third.message'),
        icon: 'fa fa-check'
      }];
    }),
    pageValidations: Ember.computed(function () {
      return {
        1: () => {
          if (this.get('address').get('validations.isValid')) {
            if (this.get('supplier').get('addresses').length > 0) {
              this.get('supplier').get('addresses').removeAt(0);
            }

            this.get('supplier').get('addresses').addObject(this.get('address'));
            return this.get('supplier').get('validations.isValid');
          } else {
            return false;
          }
        },
        2: () => {
          return this.get('validations.isValid');
        }
      };
    }),

    init() {
      this._super(...arguments);

      Ember.defineProperty(this, 'isInvalid', Ember.computed.not('supplier.validations.isValid').readOnly());
      this.set('initialBalanceCurrency', '0');
    },

    didInsertElement() {
      this.send('updateProgressBar');
    },

    actions: {
      addNew: function () {
        this.addNew();
      },
      //TODO: Move to component
      changeStep: function (step) {
        if (step !== this.get('currentStep')) {
          if (step > this.get('currentStep')) {
            if (this.get('pageValidations')[this.get('currentStep')]()) {
              this.send('goTo', step);
            } else {
              this.set('didValidate', true);
            }
          } else {
            this.send('goTo', step);
          }
        }
      },
      goTo: function (step) {
        this.set('currentStep', step);
        this.set('didValidate', false);
        this.send('updateProgressBar');
      },
      goNext: function () {
        this.send('changeStep', this.getNextStep());
      },
      goBack: function () {
        this.send('goTo', this.getPreviousStep());
      },
      updateProgressBar: function () {
        const width = 100 * (this.get('currentStep') / this.get('totalSteps'));
        $(this.get('progressBarSelector')).css('width', width + '%');
      }
    },
    getNextStep: function () {
      return this.get('currentStep') + 1;
    },
    getPreviousStep: function () {
      return this.get('currentStep') - 1;
    }
  });

  _exports.default = _default;
});