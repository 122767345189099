define("stock-control-front/components/organization-register", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    didValidate: Ember.computed.and('displayValidations', 'isInvalid'),
    displayValidations: false,

    init() {
      this._super(...arguments);

      Ember.defineProperty(this, 'isInvalid', Ember.computed.not('organization.validations.isValid').readOnly());
    },

    actions: {
      addNew: function () {
        this.set('displayValidations', true);
        this.addNew();
      }
    }
  });

  _exports.default = _default;
});