define("stock-control-front/components/purchase-invoice-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    classNames: ['row'],
    today: Ember.computed(() => {
      return new Date();
    }),

    init() {
      this._super(...arguments);
    },

    actions: {
      showPreview: function () {
        let purchaseInvoice = this.get('purchaseInvoice');
        purchaseInvoice.validate().then(({
          validations
        }) => {
          if (validations.get('isValid')) {
            this.showPurchaseInvoicePreview();
          } else {
            this.set('didValidate', true);
          }
        });
      },
      removeProductToPurchase: function (purchase) {
        purchase.get('productToPurchase').set('product', null);
      }
    }
  });

  _exports.default = _default;
});