define("stock-control-front/components/link-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.LinkComponent.extend({
    classNames: ["m-menu__link"]
  });

  _exports.default = _default;
});