define("stock-control-front/admin/organizations/inventory/inventory-valuation/route", ["exports", "stock-control-front/mixins/url-validation"], function (_exports, _urlValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_urlValidation.default, {
    navigationMenuService: Ember.inject.service('navigation-menu'),

    activate() {
      let model = this.modelFor(this.routeName);
      this.get('navigationMenuService').setMenuItems(this.get('routeName'), model.inventoryProducts.query.organization);
    },

    model: function () {
      let organization = this.paramsFor('admin.organizations.inventory').organization_id;
      return Ember.RSVP.hash({
        inventoryProducts: this.store.query('product-inventory-valuation', {
          organization: organization
        }),
        inventoryValuation: {}
      });
    },

    renderTemplate() {
      this.render({
        into: 'admin'
      });
    },

    resetController: function (controller, isExiting, transition) {
      if (isExiting) {
        const path = transition.to.name.replace('.index', '');

        if (this.get('routeName').indexOf(path) !== -1) {
          this.get('navigationMenuService').setMenuItems(transition.to.name);
        }
      }
    }
  });

  _exports.default = _default;
});