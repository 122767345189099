define("stock-control-front/templates/components/loading-spinner", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "n2QrkS0Q",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"isLoading\"]]],null,{\"statements\":[[0,\"  \"],[7,\"div\"],[11,\"class\",\"loading-spinner\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"m-loader m-loader--primary m-loader--lg\"],[9],[10],[0,\"\\n  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "stock-control-front/templates/components/loading-spinner.hbs"
    }
  });

  _exports.default = _default;
});