define("stock-control-front/components/supplier-account-statement", ["exports", "stock-control-front/utils/supplier-account-statement-validations", "stock-control-front/utils/search-dates-validations", "ember-cp-validations", "moment"], function (_exports, _supplierAccountStatementValidations, _searchDatesValidations, _emberCpValidations, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'startDate': _searchDatesValidations.startDate,
    'endDate': _searchDatesValidations.endDate,
    'supplier': _supplierAccountStatementValidations.supplier
  });

  var _default = Ember.Component.extend(Validations, {
    store: Ember.inject.service(),
    notFound: false,
    didValidate: false,
    isStartDateInvalid: Ember.computed.and('validations.attrs.startDate.isInvalid', 'didValidate'),
    isEndDateInvalid: Ember.computed.and('validations.attrs.endDate.isInvalid', 'didValidate'),

    init() {
      this._super(...arguments);

      Ember.defineProperty(this, 'today', Ember.computed(() => {
        return new Date();
      }));
    },

    actions: {
      doSearch: function (term) {
        return new Ember.RSVP.Promise((resolve, reject) => {
          this._search = Ember.run.debounce(this, this._performSearch, term, resolve, reject, 300);
        });
      },
      setSupplier: function (selectedSupplier) {
        if (selectedSupplier) {
          Ember.set(this, 'supplier', selectedSupplier);
        } else {
          Ember.set(this, 'supplier', null);
        }
      },
      findSuppliers: function (resolve, reject, params) {
        const organization = this.get('organization');
        return this.get('store').query('supplier', {
          organization: organization.get('id'),
          name: params.name
        }).then(suppliers => {
          let suppliersList = suppliers.map(supplier => {
            return {
              id: supplier.get('id'),
              name: supplier.get('name')
            };
          });
          resolve(suppliersList);
        }, reject);
      },
      searchSupplierAccountStatement: function () {
        if (this.get('validations.isValid')) {
          this.send('getAccountStatement', {
            supplier_id: this.get('supplier').id,
            start_date: (0, _moment.default)(this.get('startDate')).format('YYYY-MM-DD'),
            end_date: (0, _moment.default)(this.get('endDate')).format('YYYY-MM-DD'),
            page: 1,
            page_size: 10
          });
        } else {
          this.set('didValidate', true);
        }
      },
      getAccountStatement: function (params) {
        this.get('store').query('account_statement', {
          supplier_id: params.supplier_id,
          start_date: params.start_date,
          end_date: params.end_date,
          page: params.page,
          page_size: params.page_size
        }).then(accountStatements => {
          const accountStatement = accountStatements.get('firstObject');

          if (accountStatement.transactions.length) {
            accountStatement.set('meta', accountStatements.meta);
            this.setProperties({
              'accountStatement': accountStatement,
              'supplier_id': params.supplier_id,
              'start_date': params.start_date,
              'end_date': params.end_date
            });
          } else {
            this.set('notFound', true);
          }
        });
      },
      findRecords: function (params) {
        this.send('getAccountStatement', {
          supplier_id: this.get('supplier_id'),
          start_date: this.get('start_date'),
          end_date: this.get('end_date'),
          page: params.page,
          page_size: params.page_size
        });
      }
    },

    _performSearch(term, resolve, reject) {
      this.send('findSuppliers', resolve, reject, {
        name: term
      });
    }

  });

  _exports.default = _default;
});