define("stock-control-front/components/top-bar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'header',
    classNames: ['m-grid__item', 'm-header'],
    actions: {
      logout: function () {
        this.logout();
      },
      showTopBar: function () {
        Ember.$("body").toggleClass("m-topbar--on");
      },
      showLeftMenu: function () {
        this.set('isLeftMenuOpen', true);

        if (this.get('isLeftMenuOpen')) {
          Ember.$("body").addClass("m-aside-left--on");
        } else {
          Ember.$("body").removeClass("m-aside-left--on");
        }
      }
    }
  });

  _exports.default = _default;
});