define("stock-control-front/admin/organizations/add/route", ["exports", "stock-control-front/mixins/url-validation"], function (_exports, _urlValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_urlValidation.default, {
    model: function () {
      return Ember.RSVP.hash({
        organization: this.store.createRecord('organization', {
          personType: 'natural'
        }),
        address: this.store.createRecord('address')
      });
    },

    renderTemplate() {
      this.render({
        into: 'admin'
      });
    }

  });

  _exports.default = _default;
});