define("stock-control-front/admin/organizations/products/route", ["exports", "stock-control-front/mixins/url-validation"], function (_exports, _urlValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_urlValidation.default, {
    navigationMenuService: Ember.inject.service('navigation-menu'),

    activate() {
      var model = this.modelFor(this.routeName);
      this.get('navigationMenuService').setMenuItems(this.get('routeName'), model.products.query.organization);
    },

    model: function (params) {
      let organization = this.store.peekRecord('organization', params.organization_id);
      return Ember.RSVP.hash({
        products: this.store.query('product', {
          organization: params.organization_id,
          page: params.page,
          page_size: params.page_size
        }),
        units: this.store.query('unit', {
          organization: params.organization_id
        }),
        product: this.store.createRecord('product', {
          unit: this.store.createRecord('unit'),
          organization: organization
        }),
        organization: organization
      });
    },
    actions: {
      updateItem: function (model) {
        model.save().then(() => {
          this.refresh();
        });
      },
      findProducts: function (params) {
        const organization = this.currentModel.products.query.organization;
        this.store.query('product', {
          organization: organization,
          page: params.page,
          page_size: params.page_size
        }).then(products => {
          Ember.set(this.currentModel, 'products', products);
        });
      },
      refreshProductsList: function () {
        this.refresh();
      }
    },

    renderTemplate() {
      this.render({
        into: 'admin'
      });
    },

    resetController: function (controller, isExiting, transition) {
      if (isExiting) {
        let routePath = transition.to.name.replace(".index", "").split(".");

        if (routePath[routePath.length - 1] == "organizations") {
          this.get('navigationMenuService').setMenuItems(transition.to.name);
        }
      }
    }
  });

  _exports.default = _default;
});