define("stock-control-front/helpers/is-empty", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isEmptyHelper = isEmptyHelper;
  _exports.default = void 0;

  function isEmptyHelper(params
  /*, hash*/
  ) {
    var expression1 = params[0];
    return Ember.isEmpty(expression1);
  }

  var _default = Ember.Helper.helper(isEmptyHelper);

  _exports.default = _default;
});