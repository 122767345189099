define("stock-control-front/models/product-to-sell", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    quantity: {
      validators: [(0, _emberCpValidations.validator)('number', {
        allowString: true,
        gt: 0
      })]
    },
    unitPrice: {
      validators: [(0, _emberCpValidations.validator)('number', {
        allowString: true,
        gt: 0
      })]
    },
    unitPriceCurrency: {
      validators: [(0, _emberCpValidations.validator)('format', {
        regex: /^\$\s([1-9][0-9\.\,]+|[0-9]\.[0-9]*[1-9]+[0-9]*)$/
      })]
    },
    product: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        descriptionKey: 'sale.product.notFound.message.label'
      }), (0, _emberCpValidations.validator)('product-available', {
        dependentKeys: ['model.sale.productTotalQuantity'],
        debounce: 300
      })]
    },
    sale: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('belongs-to')]
    }
  });

  var _default = _emberData.default.Model.extend(Validations, {
    quantity: _emberData.default.attr('number'),
    unitPrice: _emberData.default.attr('number'),
    product: _emberData.default.belongsTo('product'),
    sale: _emberData.default.belongsTo('sale'),
    unitPriceCurrency: Ember.computed('unitPrice', {
      get() {
        return this.get('unitPrice');
      },

      set(key, value) {
        this.set('unitPrice', value.replace(/[^[0-9\.-]+/g, ""));
        return value;
      }

    }),
    total: Ember.computed('quantity', 'unitPrice', function () {
      return this.get('quantity') * this.get('unitPrice');
    })
  });

  _exports.default = _default;
});