define("stock-control-front/templates/components/input-bootstrap-date-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "c5dE4mGc",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"bootstrap-datepicker\",null,[[\"autoclose\",\"orientation\",\"placeholder\",\"todayHighlight\",\"class\",\"format\",\"readonly\",\"endDate\",\"value\",\"name\",\"changeDate\",\"forceParse\"],[true,\"bottom\",[25,[\"placeholder\"]],true,\"form-control m-input date-picker-input\",\"dd/mm/yyyy\",true,[25,[\"endDate\"]],[25,[\"value\"]],[25,[\"name\"]],[29,\"action\",[[24,0,[]],\"dateSelected\"],null],false]]],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"input-group-append\"],[9],[0,\"\\n  \"],[7,\"span\"],[11,\"class\",\"input-group-text mobile-button\"],[9],[0,\"\\n    \"],[7,\"i\"],[11,\"class\",\"fa fa-calendar\"],[9],[10],[0,\"\\n  \"],[3,\"action\",[[24,0,[]],\"showDatePicker\",[25,[\"name\"]]]],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "stock-control-front/templates/components/input-bootstrap-date-picker.hbs"
    }
  });

  _exports.default = _default;
});