define("stock-control-front/templates/components/unit-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "tODNqF/o",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"m-portlet\"],[9],[0,\"\\n\"],[0,\"  \"],[7,\"form\"],[11,\"class\",\"m-form\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"m-portlet__body\"],[9],[0,\"\\n\"],[0,\"      \"],[7,\"div\"],[11,\"class\",\"row m--margin-bottom-10\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"col-lg-8 offset-lg-2 col-md-10 offset-md-1\"],[9],[0,\"\\n          \"],[7,\"h4\"],[9],[1,[29,\"t\",[\"unit.add.title\"],null],false],[10],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\"],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"m-form__section m-form__section--first\"],[9],[0,\"\\n\"],[0,\"        \"],[7,\"div\"],[11,\"class\",\"form-group m-form__group row\"],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"col-lg-5 offset-lg-2 col-md-5 offset-md-1\"],[9],[0,\"\\n            \"],[7,\"div\"],[11,\"class\",\"input-group m-input-group form-input-group\"],[9],[0,\"\\n              \"],[1,[29,\"validated-input\",null,[[\"model\",\"model-attr\",\"value\",\"placeholder\",\"autocomplete\",\"maxlength\",\"didValidate\"],[[25,[\"unit\"]],\"name\",[25,[\"unit\",\"name\"]],[29,\"t\",[\"unit.name.label\"],null],\"off\",\"300\",[25,[\"didValidate\"]]]]],false],[0,\" \\n\\n\"],[0,\"              \"],[7,\"div\"],[11,\"class\",\"input-group-append\"],[9],[0,\"\\n                \"],[7,\"button\"],[11,\"id\",\"save-unit\"],[11,\"class\",\"btn btn-primary\"],[11,\"type\",\"submit\"],[9],[0,\"\\n                  \"],[1,[29,\"t\",[\"button.save.label\"],null],false],[0,\"\\n                \"],[3,\"action\",[[24,0,[]],\"addNew\"]],[10],[0,\"\\n              \"],[10],[0,\"\\n\"],[0,\"            \"],[10],[0,\"\\n            \\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n\"],[0,\"      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "stock-control-front/templates/components/unit-form.hbs"
    }
  });

  _exports.default = _default;
});