define("stock-control-front/models/organization", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    email: {
      validators: [(0, _emberCpValidations.validator)('format', {
        type: 'email',
        allowBlank: true
      })]
    },
    phone: {
      validators: [(0, _emberCpValidations.validator)('format', {
        regex: /^((\([0-9]{2,3}\))?[0-9\-\s]+)$/,
        allowBlank: true
      })]
    },
    personType: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    rfc: {
      validators: [(0, _emberCpValidations.validator)('valid-rfc', {
        dependentKeys: ['model.personType']
      }), (0, _emberCpValidations.validator)('unique-rfc', {
        queryPath: 'organization'
      })]
    },
    addresses: (0, _emberCpValidations.validator)('has-many')
  }, {
    debounce: 200,
    disabled: Ember.computed(function () {
      if (!this.get('model').get('id')) {
        return false;
      }

      if (['rfc'].indexOf(this.get('attribute')) === -1) {
        return this.get('model').changedAttributes()[this.get('attribute')] === undefined;
      } else {
        return false;
      }
    }).volatile()
  });

  var _default = _emberData.default.Model.extend(Validations, {
    name: _emberData.default.attr('string'),
    personType: _emberData.default.attr('string'),
    rfc: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    units: _emberData.default.hasMany('unit'),
    addresses: _emberData.default.hasMany('address'),
    products: _emberData.default.hasMany('product'),
    clients: _emberData.default.hasMany('client'),
    user: _emberData.default.belongsTo('user')
  });

  _exports.default = _default;
});