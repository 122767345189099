define("stock-control-front/admin/organizations/clients/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentUser: Ember.computed.alias('session.currentUser'),
    actions: {
      deleteClient: function (client) {
        client.destroyRecord().then(() => {
          this.send('refreshClients');
        }).catch(function () {
          client.rollbackAttributes();
        });
      }
    }
  });

  _exports.default = _default;
});