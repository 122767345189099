define("stock-control-front/components/purchase-invoices-search", ["exports", "ember-cp-validations", "stock-control-front/utils/search-dates-validations", "moment"], function (_exports, _emberCpValidations, _searchDatesValidations, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    'filter.startDate': _searchDatesValidations.startDate,
    'filter.endDate': _searchDatesValidations.endDate
  });

  var _default = Ember.Component.extend(Validations, {
    store: Ember.inject.service('store'),
    isStartDateInvalid: Ember.computed.and('validations.attrs.filter.startDate.isInvalid', 'showDateValidations'),
    isEndDateInvalid: Ember.computed.and('validations.attrs.filter.endDate.isInvalid', 'showDateValidations'),
    selectedPurchaseInvoice: null,
    page_size: 10,
    notFound: false,
    purchaseInvoice: null,

    init() {
      this._super(...arguments);

      Ember.defineProperty(this, 'searchEnabled', Ember.computed(() => {
        if (this.get('purchaseInvoice')) return false;
        return true;
      }));
      Ember.defineProperty(this, 'today', Ember.computed(() => {
        return new Date();
      }));
      Ember.defineProperty(this, 'filter', Ember.computed(() => {
        return {
          startDate: null,
          endDate: null
        };
      }));
    },

    actions: {
      searchPurchaseInvoices: function () {
        if (this.get('validations.isValid')) {
          this.setProperties({
            'start_date': (0, _moment.default)(this.get('filter.startDate')).format('YYYY-MM-DD'),
            'end_date': (0, _moment.default)(this.get('filter.endDate')).format('YYYY-MM-DD')
          });
          this.send('getPurchaseInvoices', {
            page: 1,
            page_size: 10
          });
        } else {
          this.set('showDateValidations', true);
        }
      },
      selectPurchaseInvoice: function () {
        const purchaseInvoice = this.get('store').peekRecord('purchaseInvoice', this.get('selectedPurchaseInvoice'));
        this.set('purchaseInvoice', purchaseInvoice);
        this.selectReference(purchaseInvoice);
        ['selectedPurchaseInvoice', 'purchaseInvoices'].forEach(key => {
          this.set(key, null);
        });
        this.toggleProperty('searchEnabled');
      },
      removePurchaseInvoice: function () {
        this.removeReference();
        this.set('purchaseInvoice', null);
        ['purchaseInvoice', 'purchaseInvoices', 'filter.startDate', 'filter.endDate'].forEach(key => {
          this.set(key, null);
        });
        this.send('enableSearch');
      },
      enableSearch: function () {
        this.setProperties({
          'searchEnabled': true,
          'notFound': false
        });
      },
      findRecords: function (params) {
        this.send('getPurchaseInvoices', params);
      },
      getPurchaseInvoices: function (params) {
        this.get('store').query('purchase-invoice', {
          organization: this.get('model').get('organization').get('id'),
          supplier_id: this.get('model').get('supplier').get('id'),
          start_date: this.get('start_date'),
          end_date: this.get('end_date'),
          page: params.page,
          page_size: params.page_size
        }).then(purchaseInvoices => {
          this.set('purchaseInvoices', purchaseInvoices);

          if (!purchaseInvoices.length) {
            this.set('notFound', true);
          }
        });
      }
    }
  });

  _exports.default = _default;
});