define("stock-control-front/components/organization-detail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    editPressed: false,
    didValidate: Ember.computed.and('displayValidations', 'isInvalid'),
    displayValidations: false,

    init() {
      this._super(...arguments);

      Ember.defineProperty(this, 'isInvalid', Ember.computed.not('organization.validations.isValid').readOnly());
    },

    actions: {
      showOrHideEditForm: function () {
        this.toggleProperty('editPressed');
      },
      cancelEdition: function () {
        this.get('organization').rollbackAttributes();
        this.send('showOrHideEditForm');
      },
      updateOrganization: function () {
        let organization = this.get('organization');
        organization.validate().then(({
          validations
        }) => {
          if (validations.get('isValid')) {
            organization.save().then(() => {
              this.send('showOrHideEditForm');
            });
          } else {
            this.set("displayValidations", true);
          }
        });
      }
    },

    willDestroyElement() {
      this.get('organization').rollbackAttributes();
    }

  });

  _exports.default = _default;
});