define("stock-control-front/admin/organizations/add/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "SnxMmp1D",
    "block": "{\"symbols\":[],\"statements\":[[4,\"is-admin-user\",null,[[\"user\"],[[25,[\"currentUser\"]]]],{\"statements\":[[0,\"  \"],[1,[29,\"page-title\",null,[[\"section\",\"sub-section\"],[[29,\"t\",[\"organization.list.label\"],null],[29,\"t\",[\"organization.new.label\"],null]]]],false],[0,\"\\n\"],[0,\"  \\n  \"],[1,[29,\"organization-register\",null,[[\"organization\",\"address\",\"addNew\"],[[25,[\"model\",\"organization\"]],[25,[\"model\",\"address\"]],[29,\"action\",[[24,0,[]],\"addNew\"],null]]]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "stock-control-front/admin/organizations/add/template.hbs"
    }
  });

  _exports.default = _default;
});