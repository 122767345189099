define("stock-control-front/components/clients-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    showDeleteModal: false,

    init() {
      this._super(...arguments);
    },

    actions: {
      findRecords: function (params) {
        const organization = this.get('clients').query.organization;
        this.get('store').query('client', {
          organization: organization,
          page: params.page,
          page_size: params.page_size
        }).then(clients => {
          this.set('clients', clients);
        });
      },
      showDeleteClientModal: function (client) {
        this.set('showDeleteModal', true);
        this.set('clientToDelete', client);
      },
      closeDeleteModal: function () {
        this.set('showDeleteModal', false);
        this.set('clientToDelete', null);
      },
      deleteClient: function () {
        this.deleteClient(this.get('clientToDelete'));
        this.send('closeDeleteModal');
      }
    }
  });

  _exports.default = _default;
});