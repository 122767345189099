define("stock-control-front/templates/components/page-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Z1tTh4v8",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[29,\"top-bar\",null,[[\"logout\",\"isLeftMenuOpen\"],[[29,\"action\",[[24,0,[]],\"logout\"],null],[25,[\"isLeftMenuOpen\"]]]]],false],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body\"],[9],[0,\"\\n  \"],[1,[29,\"left-navigation-menu\",null,[[\"isOpen\"],[[25,[\"isLeftMenuOpen\"]]]]],false],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"m-grid__item m-grid__item--fluid m-wrapper page-main-container\"],[9],[0,\"\\n    \"],[15,1],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[1,[23,\"page-footer\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "stock-control-front/templates/components/page-content.hbs"
    }
  });

  _exports.default = _default;
});