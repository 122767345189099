define("stock-control-front/admin/organizations/sale-notes/show/route", ["exports", "stock-control-front/mixins/url-validation"], function (_exports, _urlValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_urlValidation.default, {
    navigationMenuService: Ember.inject.service('navigation-menu'),

    activate() {
      let model = this.modelFor(this.get('routeName'));
      this.get('navigationMenuService').setMenuItems('admin.organizations.sale_notes', model.saleNote.get('organization').get('id'));
    },

    model: function (params) {
      return Ember.RSVP.hash({
        saleNote: this.store.findRecord('sale-note', params.sale_note_id, {
          reload: true
        })
      });
    },

    renderTemplate() {
      this.render({
        into: 'admin'
      });
    },

    resetController: function (controller, isExiting, transition) {
      if (isExiting) {
        const path = transition.to.name;

        if (path.substr(path.indexOf("organizations"), path.length) == "organizations") {
          this.get('navigationMenuService').setMenuItems(path);
        }
      }
    }
  });

  _exports.default = _default;
});