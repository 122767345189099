define("stock-control-front/helpers/and", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.and = and;
  _exports.default = void 0;

  function and(params
  /*, hash*/
  ) {
    let [arg1, arg2] = params;
    return arg1 && arg2;
  }

  var _default = Ember.Helper.helper(and);

  _exports.default = _default;
});