define("stock-control-front/models/payment", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    amount: {
      validators: [(0, _emberCpValidations.validator)('number', {
        allowString: true,
        gt: 0
      })]
    },
    amountCurrency: {
      validators: [(0, _emberCpValidations.validator)('format', {
        regex: /^\$\s([1-9][0-9\.\,]+|[0-9]\.[0-9]*[1-9]+[0-9]*)$/
      })]
    },
    concept: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        disabled: Ember.computed.and('model.referenceId', 'model.referenceType')
      })]
    }
  });

  var _default = _emberData.default.Model.extend(Validations, {
    amount: _emberData.default.attr('number'),
    concept: _emberData.default.attr('string'),
    referenceId: _emberData.default.attr('string'),
    referenceType: _emberData.default.attr('string'),
    organization: _emberData.default.belongsTo('organization'),
    amountCurrency: Ember.computed('amount', {
      get() {
        return this.get('amount');
      },

      set(key, value) {
        this.set('amount', value.replace(/[^[0-9\.-]+/g, ""));
        return value;
      }

    })
  });

  _exports.default = _default;
});