define("stock-control-front/admin/organizations/purchases/add/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentUser: Ember.computed.alias('session.currentUser'),
    actions: {
      didSave() {
        let purchaseInvoice = this.get('model').purchaseInvoice;
        purchaseInvoice.get('purchases').clear();
        this.send('refreshPurchases');
        this.transitionToRoute('admin.organizations.purchases');
      },

      save() {
        let purchaseInvoice = this.get('model').purchaseInvoice;
        return purchaseInvoice.save();
      }

    }
  });

  _exports.default = _default;
});