define("stock-control-front/admin/organizations/sale-notes/add/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      currentUser: Ember.computed.alias('session.currentUser'),

      didSave() {
        this.send('refreshSaleNotes');
        this.transitionToRoute('admin.organizations.sale_notes');
      },

      showLoaderComponent: function () {
        this.send('showLoader');
      },
      hideLoaderComponent: function () {
        this.send('hideLoader');
      }
    }
  });

  _exports.default = _default;
});