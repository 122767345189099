define("stock-control-front/models/product", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        message: function () {
          return '';
        }
      }), (0, _emberCpValidations.validator)('unique-product-name', {
        debounce: 300
      })]
    },
    unit: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('belongs-to')]
    }
  });

  var _default = _emberData.default.Model.extend(Validations, {
    name: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    unit: _emberData.default.belongsTo('unit'),
    organization: _emberData.default.belongsTo('organization'),
    existences: _emberData.default.hasMany('product-existence')
  });

  _exports.default = _default;
});