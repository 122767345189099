define("stock-control-front/components/units-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    showDeleteModal: false,
    actions: {
      findRecords: function (params) {
        this.get('store').query('unit', {
          organization: this.get('organization').get('id'),
          page: params.page,
          page_size: params.page_size
        }).then(units => {
          this.set('units', units);
        });
      },
      updateUnit: function (model) {
        model.save().catch(function () {
          console.log('The unit could n\' be saved');
        });
      },
      showDeleteUnitModal: function (unit) {
        this.set('showDeleteModal', true);
        this.set('unitToDelete', unit);
      },

      closeDeleteModal() {
        this.set('showDeleteModal', false);
        this.set('unitToDelete', null);
      },

      deleteUnit() {
        this.deleteUnit(this.get('unitToDelete'));
        this.send('closeDeleteModal');
      }

    }
  });

  _exports.default = _default;
});