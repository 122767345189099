define("stock-control-front/components/products-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    showDeleteModal: false,
    actions: {
      findRecords: function (params) {
        this.findRecords({
          page: params.page,
          page_size: params.page_size
        });
      },
      getProducts: function (callback) {
        this.get('store').query('product', {
          organization: this.get('organization').id,
          name: this.get('searchTerm'),
          page_size: 5,
          page: 1
        }).then(products => {
          this.set('products', products);
          callback();
        });
      },
      searchProducts: function () {
        this.send("getProducts", () => {
          this.set('hasBeenSearched', this.get('searchTerm') !== undefined && this.get('searchTerm') !== '');
        });
      },
      updateProductUnit: function (product, unitId) {
        if (unitId === undefined || unitId === "") {
          product.set('unit', null);
        } else {
          let unit = this.get('store').peekRecord('unit', unitId);
          product.set('unit', unit);
        }
      },
      updateItem: function (model) {
        model.save().catch(() => {
          console.log('The product could n\' be saved');
        });
      },
      showDeleteProductModal: function (product) {
        this.set('showDeleteModal', true);
        this.set('productToDelete', product);
      },

      deleteProduct() {
        this.deleteProduct(this.get('productToDelete'));
        this.send('closeDeleteModal');
      },

      closeDeleteModal() {
        this.set('showDeleteModal', false);
        this.set('productToDelete', null);
      }

    }
  });

  _exports.default = _default;
});