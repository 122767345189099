define("stock-control-front/admin/organizations/suppliers/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentUser: Ember.computed.alias('session.currentUser'),
    actions: {
      deleteSupplier(supplier) {
        supplier.destroyRecord().then(() => {
          this.send('refreshSuppliers');
        }).catch(() => {
          supplier.rollbackAttributes();
        });
      }

    }
  });

  _exports.default = _default;
});