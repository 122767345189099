define("stock-control-front/utils/product-inventory-validations", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.product = void 0;
  const product = [(0, _emberCpValidations.validator)('presence', {
    presence: true,
    message: function () {
      let intl = this.model.get('intl');
      return intl.t('inventory.reports.products.product.notFound.message.label');
    }
  })];
  _exports.product = product;
  var _default = {
    product
  };
  _exports.default = _default;
});