define("stock-control-front/components/inventory-valuation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),

    init() {
      this._super(...arguments);
    },

    actions: {
      findInventoryValuation: function (params, callback) {
        this.findInventoryValuation(params).then(inventoryProducts => {
          this.set('inventoryProducts', inventoryProducts);

          if (callback !== undefined) {
            callback();
          }
        });
      },
      findRecords: function (params) {
        params.organization = this.get('inventoryProducts').query.organization;

        if (this.get('search')) {
          params.name = this.get('search');
        }

        this.send('findInventoryValuation', params);
      },
      filterProducts: function () {
        const organization = this.get('inventoryProducts').query.organization;
        this.set('search', this.get('searchTerm'));
        this.send('findInventoryValuation', {
          name: this.get('searchTerm'),
          organization: organization
        }, () => {
          this.set('hasBeenSearched', true);
        });
      }
    }
  });

  _exports.default = _default;
});