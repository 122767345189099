define("stock-control-front/components/product-valuation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    valuationEntries: () => {
      return [];
    },
    isCollapsed: false,

    didReceiveAttrs() {
      this._super(...arguments);

      const productId = this.get('inventoryProduct').get('id');
      this.get('store').query('valuation-entry', {
        filter: {
          product: productId,
          page: 1,
          page_size: 10
        }
      }).then(entries => {
        this.set('valuationEntries', entries);
      });
    },

    actions: {
      toggle: function () {
        this.$().find('.m-portlet__body').toggle();
        this.toggleProperty('isCollapsed');
      },
      findRecords: function (params) {
        const productId = this.get('inventoryProduct').get('id');
        this.get('store').query('valuation-entry', {
          filter: {
            product: productId,
            page: params.page,
            page_size: params.page_size
          }
        }).then(entries => {
          this.set('valuationEntries', entries);
        });
      }
    }
  });

  _exports.default = _default;
});