define("stock-control-front/auth/login/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "PlcXdnqX",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"m-grid m-grid--hor m-grid--root m-page\"],[9],[0,\"\\n\"],[0,\"  \"],[7,\"div\"],[11,\"class\",\"m-grid__item m-grid__item--fluid m-grid m-grid--desktop m-grid--ver-desktop m-grid--hor-tablet-and-mobile m-login m-login--6\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"m-grid__item m-grid__item--fluid  m-grid__item--order-tablet-and-mobile-1  m-login__wrapper\"],[9],[0,\"\\n      \"],[1,[29,\"login-form\",null,[[\"title\",\"loginErrorMessage\",\"doLogin\",\"user\"],[[29,\"t\",[\"login.box.title\"],null],[25,[\"loginErrorMessage\"]],[29,\"action\",[[24,0,[]],\"doLogin\"],null],[25,[\"model\"]]]]],false],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"],[0,\"\\n\"],[1,[23,\"outlet\"],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "stock-control-front/auth/login/template.hbs"
    }
  });

  _exports.default = _default;
});