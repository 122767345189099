define("stock-control-front/serializers/sale-note", ["exports", "stock-control-front/serializers/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _application.default.extend({
    serialize(snapshot) {
      let record = snapshot.record;

      var json = this._super(...arguments);

      json.data.attributes.sales = [];
      record.get('sales').forEach(sale => {
        let productToSell = sale.get('productToSell');
        let saleData = {
          quantity: sale.get('quantity'),
          unit_id: sale.get('unit').get('id'),
          product_to_sell: {
            quantity: productToSell.get('quantity'),
            unit_price: productToSell.get('unitPrice'),
            product_id: productToSell.get('product').get('id')
          }
        };

        if (sale.get('id') !== undefined) {
          saleData.id = sale.get('id');
          saleData.product_to_sell.id = sale.get('productToSell').get('id');
        }

        json.data.attributes.sales.push(saleData);
      });
      return json;
    }

  });

  _exports.default = _default;
});