define("stock-control-front/models/purchase-invoice", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    supplier: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('belongs-to')]
    },
    organization: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    purchases: [(0, _emberCpValidations.validator)('has-many'), (0, _emberCpValidations.validator)('length', {
      min: 1
    })],
    invoiceDate: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('date', {
        format: 'dd/mm/yyyy'
      })]
    },
    invoiceNumber: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    }
  });

  var _default = _emberData.default.Model.extend(Validations, {
    supplier: _emberData.default.belongsTo('supplier'),
    purchases: _emberData.default.hasMany('purchase', {
      async: false
    }),
    organization: _emberData.default.belongsTo('organization'),
    invoiceDate: _emberData.default.attr('date'),
    invoiceNumber: _emberData.default.attr('string')
  });

  _exports.default = _default;
});