define("stock-control-front/components/menu-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: 'li',
    classNames: ['m-menu__item'],
    classNameBindings: ['hasSubMenus:m-menu__item--submenu', 'isActive', 'isActiveAndHasItems:m-menu__item--open'],
    isActive: Ember.computed('item', function () {
      return this.get('item').isActive ? 'm-menu__item--active' : '';
    }),
    hasSubMenus: Ember.computed('item', function () {
      return this.get('item').subMenus !== undefined && Object.keys(this.get('item').subMenus).length > 0;
    }),
    isActiveAndHasItems: Ember.computed.and('isActive', 'hasSubMenus'),
    slideSpeed: 200,
    actions: {
      showSubItems: function () {
        let menuLink = this.$();
        let subMenu = menuLink.find(".m-menu__submenu");
        let menu = menuLink.closest(".m-menu__nav, .m-menu__subnav");
        let openedMenus = menu.children(".m-menu__item.m-menu__item--open.m-menu__item--submenu");
        openedMenus.each((index, openedMenu) => {
          let openedSubMenu = Ember.$(openedMenu).children(".m-menu__submenu");

          if (openedSubMenu) {
            openedSubMenu.slideUp(this.get('slideSpeed'), () => {
              Ember.$(openedMenu).removeClass("m-menu__item--open");
            });
          }
        });

        if (menuLink.hasClass('m-menu__item--open')) {
          subMenu.slideUp(this.get('slideSpeed'));
          menuLink.removeClass('m-menu__item--open');
        } else {
          subMenu.slideDown(this.get('slideSpeed'));
          menuLink.addClass('m-menu__item--open');
        }
      }
    }
  });

  _exports.default = _default;
});