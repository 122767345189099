define("stock-control-front/validators/valid-rfc", ["exports", "ember-cp-validations/validators/base"], function (_exports, _base) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  const ValidRfc = _base.default.extend({
    validate(value, options, model) {
      let regex;

      if (model.get('personType') === 'natural') {
        regex = /^[A-Za-z]{4}\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])[A-Za-z\d]{3}$/;
      } else {
        regex = /^[A-Za-z]{3}\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])[A-Za-z\d]{3}$/;
      }

      return regex.test(value);
    }

  });

  ValidRfc.reopenClass({
    getDependentsFor() {
      return [];
    }

  });
  var _default = ValidRfc;
  _exports.default = _default;
});