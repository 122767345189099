define("stock-control-front/admin/organizations/suppliers/payment/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "WCwnBcAi",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"page-title\",null,[[\"section\",\"sub-section\"],[[29,\"t\",[\"supplier.list.label\"],null],[29,\"t\",[\"supplier.payment.label\"],null]]]],false],[0,\"\\n\"],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"m-content\"],[9],[0,\"\\n  \"],[1,[29,\"supplier-payment-register\",null,[[\"payment\",\"refresh\"],[[25,[\"model\",\"payment\"]],[29,\"action\",[[24,0,[]],\"refresh\"],null]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "stock-control-front/admin/organizations/suppliers/payment/template.hbs"
    }
  });

  _exports.default = _default;
});