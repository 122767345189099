define("stock-control-front/auth/login/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    loginErrorMessage: '',
    session: Ember.inject.service('session'),
    intl: Ember.inject.service('intl'),
    actions: {
      doLogin() {
        const user = this.get('model');
        this.get('session').authenticate('authenticator:stockcontrol', user.email, user.password).catch(response => {
          const {
            errors
          } = response;

          if (errors.mapBy('status').indexOf(401) >= 0) {
            this.set('loginErrorMessage', this.get('intl').t('login.user.notFound.error.label'));
          } else {
            this.set('loginErrorMessage', this.get('intl').t('login.error.label'));
          }
        });
      }

    }
  });

  _exports.default = _default;
});