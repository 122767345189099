define("stock-control-front/components/purchases-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    actions: {
      selectProduct: function (purchase, productId) {
        let product = this.get('store').peekRecord('product', productId);
        purchase.get('productToPurchase').set('product', product);
        purchase.set('unit', product.get('unit'));

        if (purchase.get('productToPurchase').get('quantity') !== undefined) {
          purchase.set('quantity', purchase.get('productToPurchase').get('quantity'));
        }
      },
      removeProduct: function (purchase) {
        this.removeProduct(purchase);
      },
      setPurchaseQuantity: function (productToPurchase, quantity) {
        let purchase = productToPurchase.get('purchase');
        let purchaseUnit = productToPurchase.get('purchase').get('unit');
        let purchaseProduct = productToPurchase.get('product');

        if (purchaseProduct.get('id') && purchaseUnit.get('id')) {
          if (purchaseUnit.get('id') === purchaseProduct.get('unit').get('id')) {
            purchase.set('quantity', quantity);
          }
        }
      },
      selectPurchaseUnit: function (purchase, unitId) {
        if (unitId) {
          let unit = this.get('units').filterBy('id', unitId).get('firstObject');
          let productUnitId = purchase.get('productToPurchase').get('product').get('unit').get('id');

          if (unit.id == productUnitId) {
            let quantity = purchase.get('productToPurchase').get('quantity');
            purchase.set('quantity', quantity);
          } else {
            purchase.set('quantity', null);
          }

          purchase.set('unit', unit);
        } else {
          purchase.set('quantity', null);
          purchase.set('unit', null);
        }
      },
      addNewPurchase: function () {
        this.set('didValidate', false);
        let purchase = this.get('store').createRecord('purchase', {
          productToPurchase: this.get('store').createRecord('product-to-purchase')
        });
        this.get('purchases').addObject(purchase);
      },
      removePurchase: function (purchase) {
        this.get('purchases').removeObject(purchase);
      }
    }
  });

  _exports.default = _default;
});