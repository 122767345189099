define("stock-control-front/admin/organizations/clients/show/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ypfLAfV2",
    "block": "{\"symbols\":[\"address\"],\"statements\":[[4,\"is-admin-user\",null,[[\"user\"],[[25,[\"currentUser\"]]]],{\"statements\":[[0,\"  \"],[1,[29,\"page-title\",null,[[\"section\",\"sub-section\"],[[29,\"t\",[\"client.list.label\"],null],[29,\"t\",[\"client.detail.label\"],null]]]],false],[0,\"\\n  \\n\"],[0,\"  \"],[7,\"div\"],[11,\"class\",\"m-content\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"m-portlet\"],[9],[0,\"\\n      \"],[1,[29,\"client-detail\",null,[[\"client\"],[[25,[\"model\"]]]]],false],[0,\" \\n    \"],[10],[0,\"\\n  \\n\"],[0,\"    \"],[7,\"div\"],[11,\"class\",\"m-portlet\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"m-portlet__body\"],[9],[0,\"\\n        \"],[7,\"h3\"],[11,\"class\",\"form-section\"],[9],[1,[29,\"t\",[\"address.list.label\"],null],false],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n\"],[4,\"each\",[[25,[\"model\",\"addresses\"]]],null,{\"statements\":[[0,\"            \"],[7,\"div\"],[11,\"class\",\"col-lg-4 col-md-4 col-sm-6\"],[9],[0,\"\\n              \"],[1,[29,\"address-view\",null,[[\"address\"],[[24,1,[]]]]],false],[0,\"\\n            \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[0,\"  \"],[10],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "stock-control-front/admin/organizations/clients/show/template.hbs"
    }
  });

  _exports.default = _default;
});