define("stock-control-front/admin/organizations/units/route", ["exports", "stock-control-front/mixins/url-validation"], function (_exports, _urlValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_urlValidation.default, {
    navigationMenuService: Ember.inject.service('navigation-menu'),

    activate() {
      let model = this.modelFor(this.routeName);
      this.get('navigationMenuService').setMenuItems(this.get('routeName'), model.organization.get('id'));
    },

    model: function (params) {
      let organization = this.store.peekRecord('organization', params.organization_id);
      return Ember.RSVP.hash({
        units: this.store.query('unit', {
          organization: params.organization_id,
          page: params.page,
          page_size: params.page_size
        }),
        organization: organization,
        unit: this.store.createRecord('unit', {
          organization: organization
        })
      });
    },
    actions: {
      showDeleteModal: function (name, unit) {
        this.currentModel.units.set('item', unit);
        this.render('modal/delete-modal', {
          into: this.get('routeName'),
          outlet: 'modal'
        });
      },
      deleteModal: function () {
        this.disconnectOutlet({
          outlet: 'modal',
          parentView: this.get('routeName')
        });
      },
      refreshUnitsList: function () {
        this.refresh();
      }
    },

    renderTemplate() {
      this.render({
        into: 'admin'
      });
    },

    resetController: function (controller, isExiting, transition) {
      if (isExiting) {
        let routePath = transition.to.name.replace(".index", "").split(".");

        if (routePath[routePath.length - 1] == "organizations") {
          this.get('navigationMenuService').setMenuItems(transition.to.name);
        }
      }
    }
  });

  _exports.default = _default;
});