define("stock-control-front/admin/organizations/suppliers/show/route", ["exports", "stock-control-front/mixins/url-validation"], function (_exports, _urlValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_urlValidation.default, {
    model: function (params) {
      return this.store.findRecord('supplier', params.supplier_id);
    },

    renderTemplate() {
      this.render({
        into: 'admin'
      });
    },

    resetController: function (controller, isExiting) {
      if (isExiting) {
        ['didValidate', 'editPressed'].forEach(item => {
          controller.set(item, false);
        });
      }
    }
  });

  _exports.default = _default;
});