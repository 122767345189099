define("stock-control-front/components/left-navigation-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    tagName: "",
    navigationMenuService: Ember.inject.service('navigation-menu'),
    menu: Ember.computed.alias("navigationMenuService.menuItems"),
    actions: {
      toggleSideBar: function () {
        this.toggleProperty('isOpen');

        if (this.get('isOpen')) {
          Ember.$("body").addClass("m-aside-left--on");
        } else {
          Ember.$("body").removeClass("m-aside-left--on");
        }
      }
    }
  });

  _exports.default = _default;
});