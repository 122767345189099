define("stock-control-front/admin/organizations/products/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "Je34ET5X",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"page-title\",null,[[\"section\",\"sub-section\"],[[29,\"t\",[\"product.list.label\"],null],[29,\"t\",[\"product.all.label\"],null]]]],false],[0,\"\\n\"],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"m-content\"],[9],[0,\"\\n  \"],[1,[29,\"product-form\",null,[[\"product\",\"units\",\"addNew\"],[[25,[\"model\",\"product\"]],[25,[\"model\",\"units\"]],[29,\"action\",[[24,0,[]],\"addNew\"],null]]]],false],[0,\"\\n\\n\\n  \"],[1,[29,\"products-list\",null,[[\"products\",\"units\",\"findRecords\",\"deleteProduct\",\"organization\"],[[25,[\"model\",\"products\"]],[25,[\"model\",\"units\"]],[29,\"action\",[[24,0,[]],\"findProducts\"],null],[29,\"action\",[[24,0,[]],\"deleteProduct\"],null],[25,[\"model\",\"organization\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "stock-control-front/admin/organizations/products/template.hbs"
    }
  });

  _exports.default = _default;
});