define("stock-control-front/utils/supplier-account-statement-validations", ["exports", "ember-cp-validations"], function (_exports, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.supplier = void 0;
  const supplier = [(0, _emberCpValidations.validator)('presence', {
    presence: true,
    message: function () {
      let intl = this.model.get('intl');
      return intl.t('accountStatement.supplier.empty.message');
    }
  })];
  _exports.supplier = supplier;
  var _default = {
    supplier
  };
  _exports.default = _default;
});