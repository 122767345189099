define("stock-control-front/admin/organizations/suppliers/account-statement/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "V45dsRf0",
    "block": "{\"symbols\":[],\"statements\":[[1,[29,\"page-title\",null,[[\"section\",\"sub-section\"],[[29,\"t\",[\"supplier.list.label\"],null],[29,\"t\",[\"accountStatement.label\"],null]]]],false],[0,\"\\n\\n\"],[7,\"div\"],[11,\"class\",\"m-content\"],[9],[0,\"\\n  \"],[1,[29,\"supplier-account-statement\",null,[[\"organization\"],[[25,[\"model\",\"organization\"]]]]],false],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "stock-control-front/admin/organizations/suppliers/account-statement/template.hbs"
    }
  });

  _exports.default = _default;
});