define("stock-control-front/models/supplier", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    name: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    personType: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    rfc: {
      validators: [(0, _emberCpValidations.validator)('valid-rfc', {
        dependentKeys: ['model.personType'],
        disabled: Ember.computed.not('model.isNational')
      })]
    },
    taxIdentifier: {
      validators: [(0, _emberCpValidations.validator)('presence', {
        presence: true,
        disabled: Ember.computed.readOnly('model.isNational')
      })]
    },
    email: {
      validators: [(0, _emberCpValidations.validator)('format', {
        type: 'email',
        allowBlank: true
      })]
    },
    phone: {
      validators: [(0, _emberCpValidations.validator)('format', {
        regex: /^((\([0-9]{2,3}\))?[0-9\-\s]+)$/,
        allowBlank: true
      })]
    },
    addresses: (0, _emberCpValidations.validator)('has-many')
  }, {
    debounce: 200
  });

  var _default = _emberData.default.Model.extend(Validations, {
    name: _emberData.default.attr('string'),
    personType: _emberData.default.attr('string', {
      defaultValue: "natural"
    }),
    rfc: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    taxIdentifier: _emberData.default.attr('string'),
    isNational: _emberData.default.attr('boolean', {
      defaultValue: true
    }),
    addresses: _emberData.default.hasMany('address'),
    organization: _emberData.default.belongsTo('organization'),
    initialBalance: _emberData.default.attr('number', {
      defaultValue: 0
    })
  });

  _exports.default = _default;
});