define("stock-control-front/admin/organizations/clients/payment/route", ["exports", "stock-control-front/mixins/url-validation"], function (_exports, _urlValidation) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend(_urlValidation.default, {
    navigationMenuService: Ember.inject.service('navigation-menu'),

    activate() {
      let model = this.modelFor('admin.organizations.clients');
      this.get('navigationMenuService').setMenuItems(this.get('routeName'), model.query.organization);
    },

    model: function () {
      const organizationId = this.paramsFor('admin.organizations.clients').organization_id;
      const organization = this.store.peekRecord('organization', organizationId);
      return Ember.RSVP.hash({
        payment: this.store.createRecord('client-payment', {
          organization: organization
        })
      });
    },
    actions: {
      refreshPaymentWidget: function () {
        this.refresh();
      }
    },
    resetController: function (controller, isExiting, transition) {
      if (isExiting) {
        const path = transition.to.name.replace('.index', '');

        if (this.get('routeName').indexOf(path) !== -1) {
          let model = this.modelFor('admin.organizations.clients');
          this.get('navigationMenuService').setMenuItems(transition.to.name, model.query.organization);
        }
      }
    },

    renderTemplate() {
      this.render({
        into: 'admin'
      });
    }

  });

  _exports.default = _default;
});