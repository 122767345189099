define("stock-control-front/mixins/reset-scroll", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    afterModel: function () {
      if (window && window.navigator) {
        window.scrollTo(0, 0);
      }
    }
  });

  _exports.default = _default;
});