define("stock-control-front/services/navigation-menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    links: Ember.inject.service('menu-link'),
    session: Ember.inject.service('session'),
    menuItems: function () {
      return [];
    },
    admin_menu: function () {
      let currentUser = this.get('session').get('currentUser');

      if (currentUser.get('isAdmin')) {
        return {
          admin: this.get('links').adminLink(),
          organizations: this.get('links').organizationsIndexLink()
        };
      } else if (currentUser.get('isSeller')) {
        return {
          organizations: this.get('links').organizationsIndexLink()
        };
      }
    },
    admin_organization_menu: function (model) {
      let currentUser = this.get('session').get('currentUser');

      if (currentUser.get('isAdmin')) {
        return {
          admin: this.get('links').adminLink(),
          organizations: this.get('links').organizationsIndexLink(model),
          clients: this.get('links').clientsIndexLink(model),
          sale_notes: this.get('links').saleNotesIndexLink(model),
          products: this.get('links').productsLink(model),
          units: this.get('links').unitsLink(model),
          suppliers: this.get('links').suppliersIndexLink(model),
          purchases: this.get('links').purchasesIndexLink(model),
          inventory: this.get('links').inventoryIndexLink(model)
        };
      } else {
        return {
          organizations: this.get('links').organizationsIndexLink(model),
          sale_notes: this.get('links').saleNotesIndexLink(model)
        };
      }
    },
    sales_organization_menu: function () {
      return {
        organization: this.get('links').organizationsIndexLink(),
        sale_notes: this.get('links').saleNotesIndexLink()
      };
    },
    setMenuItems: function (routeName, model) {
      let routePath = routeName.replace('.index', '').split('.');
      let lastPosition = routePath.length - 1;
      let menu = model === undefined ? this.admin_menu() : this.admin_organization_menu(model);

      if (menu[routePath[lastPosition]] === undefined) {
        while (menu[routePath[lastPosition]] === undefined && lastPosition > 0) {
          lastPosition--;
          let parentMenu = menu[routePath[lastPosition]];

          if (parentMenu.subMenus !== undefined) {
            let subMenu = routePath[lastPosition + 1];

            if (parentMenu.subMenus[subMenu] !== undefined) {
              parentMenu.subMenus[subMenu].isActive = true;
            }
          }
        }
      } else {
        let subMenus = menu[routePath[lastPosition]].subMenus;

        if (subMenus !== undefined && subMenus["index"] !== undefined) {
          subMenus["index"].isActive = true;
        }
      }

      menu[routePath[lastPosition]].isActive = true;
      Ember.set(this, 'menuItems', menu);
    }
  });

  _exports.default = _default;
});