define("stock-control-front/admin/organizations/suppliers/add/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    actions: {
      addNew() {
        let address = this.get('model').address;
        let supplier = this.get('model').supplier;
        address.save().then(() => {
          supplier.save().then(() => {
            this.send('refreshSuppliers');
            this.transitionToRoute('admin.organizations.suppliers');
          }, () => {
            console.log('Supplier could n\'t be saved');
          });
        }, () => {
          console.log('Address could n\'t be saved');
        });
      }

    }
  });

  _exports.default = _default;
});