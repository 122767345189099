define("stock-control-front/templates/components/output-columns", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "8Fq4P1uv",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"td\"],[11,\"class\",\"text-center\"],[9],[0,\"\\n\"],[4,\"if\",[[29,\"eq\",[[25,[\"productRecord\",\"recordSubtype\"]],\"Sale\"],null]],null,{\"statements\":[[4,\"link-to\",[\"admin.organizations.sale_notes.show\",[25,[\"productRecord\",\"recordUuid\"]]],null,{\"statements\":[[0,\"      \"],[1,[25,[\"productRecord\",\"recordReference\"]],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"parameters\":[]},null],[10],[0,\"\\n\"],[7,\"td\"],[11,\"class\",\"text-center\"],[9],[10],[0,\"\\n\"],[7,\"td\"],[11,\"class\",\"text-center\"],[9],[10],[0,\"\\n\"],[7,\"td\"],[11,\"class\",\"text-center\"],[9],[10],[0,\"\\n\"],[7,\"td\"],[11,\"class\",\"text-center\"],[9],[1,[29,\"format-number\",[[25,[\"productRecord\",\"recordQuantity\"]]],[[\"precision\"],[\"2\"]]],false],[10],[0,\"\\n\"],[7,\"td\"],[11,\"class\",\"text-center\"],[9],[1,[25,[\"productRecord\",\"recordUnit\"]],false],[10],[0,\"\\n\"],[7,\"td\"],[11,\"class\",\"text-center\"],[9],[1,[29,\"format-number\",[[25,[\"productRecord\",\"quantity\"]]],[[\"precision\"],[\"2\"]]],false],[10],[0,\"\\n\"],[15,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "stock-control-front/templates/components/output-columns.hbs"
    }
  });

  _exports.default = _default;
});