define("stock-control-front/admin/organizations/add/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentUser: Ember.computed.alias('session.currentUser'),
    actions: {
      addNew() {
        let organization = this.get('model').organization;
        let address = this.get('model').address;
        organization.get('addresses').addObject(address);
        organization.validate().then(({
          validations
        }) => {
          if (validations.get('isValid')) {
            address.save().then(() => {
              organization.save().then(() => {
                let owner = Ember.getOwner(this);
                let route = owner.lookup("route:admin.organizations");
                route.refresh();
                this.transitionToRoute('admin.organizations');
              }, function () {
                //TODO: Implement error message window
                console.log('Organization could n\'t be saved');
              });
            }, function () {
              //TODO: Implement error message window
              console.log('Addres could n\'t be saved');
            });
          }
        });
      }

    }
  });

  _exports.default = _default;
});