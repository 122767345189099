define("stock-control-front/components/sales-detail", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    session: Ember.inject.service(),
    actions: {
      downloadFile: function () {
        this.downloadFile();
      },
      downloadReceipt: function () {
        this.downloadReceipt();
      },
      cancelNote: function () {
        this.showCancelNoteModal();
      }
    },
    totalAmount: Ember.computed('sales', function () {
      let sales = this.get('sales');
      return _emberData.default.PromiseObject.create({
        promise: Ember.RSVP.all(sales.map(sale => {
          return sale.get('productToSell').then(productToSell => {
            return productToSell.get('total');
          });
        })).then(function (result) {
          return result.reduce((prev, curr) => {
            return prev + curr;
          }, 0);
        })
      });
    })
  });

  _exports.default = _default;
});