define("stock-control-front/models/sale-note", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    client: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('belongs-to')]
    },
    organization: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    user: {
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    sales: [(0, _emberCpValidations.validator)('has-many'), (0, _emberCpValidations.validator)('length', {
      min: 1
    })]
  });

  var _default = _emberData.default.Model.extend(Validations, {
    client: _emberData.default.belongsTo('client'),
    sales: _emberData.default.hasMany('sale', {
      async: false
    }),
    organization: _emberData.default.belongsTo('organization'),
    saleDate: _emberData.default.attr('date'),
    folio: _emberData.default.attr('number'),
    status: _emberData.default.attr('string'),
    user: _emberData.default.belongsTo('user')
  });

  _exports.default = _default;
});