define("stock-control-front/mixins/url-validation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    beforeModel: function () {
      this.store.queryRecord('requestmap', {
        filter: {
          request_path: this.get('routeName')
        }
      }).catch(response => {
        const {
          errors
        } = response;
        const error_status = errors.mapBy('status');

        if (error_status.indexOf(401) >= 0 || error_status.indexOf(403) >= 0) {
          this.transitionTo('admin');
        }
      });
    }
  });

  _exports.default = _default;
});