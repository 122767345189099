define("stock-control-front/models/address", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    street: {
      description: 'Street',
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    colony: {
      description: 'Colony',
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    city: {
      description: 'City',
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    state: {
      description: 'State',
      validators: [(0, _emberCpValidations.validator)('presence', true)]
    },
    cp: {
      description: 'CP',
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('length', {
        min: 5,
        max: 10
      })]
    }
  }, {
    debounce: 200
  });

  var _default = _emberData.default.Model.extend(Validations, {
    street: _emberData.default.attr('string'),
    colony: _emberData.default.attr('string'),
    city: _emberData.default.attr('string'),
    state: _emberData.default.attr('string'),
    cp: _emberData.default.attr('string')
  });

  _exports.default = _default;
});