define("stock-control-front/admin/organizations/products/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    queryParams: ['page', 'page_size'],
    page: 1,
    page_size: 5,
    actions: {
      addNew() {
        let product = this.get('model').product;
        product.validate().then(({
          validations
        }) => {
          if (validations.get('isValid')) {
            product.save().then(() => {
              this.send('refreshProductsList');
            }, function () {
              //TODO: Implement error message
              console.log('The product could n\'t be saved');
            });
          }
        });
      },

      findProducts(params) {
        const organization = this.model.products.query.organization;
        this.store.query('product', {
          organization: organization,
          page: params.page,
          page_size: params.page_size
        }).then(products => {
          Ember.set(this.model, 'products', products);
        });
      },

      deleteProduct(product) {
        product.destroyRecord().then(() => {
          this.send('refreshProductsList');
        }).catch(() => {
          product.rollbackAttributes();
        });
      }

    }
  });

  _exports.default = _default;
});