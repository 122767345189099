define("stock-control-front/models/user", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    email: _emberData.default.attr('string'),
    password: _emberData.default.attr('string'),
    organizations: _emberData.default.hasMany('organizations'),
    roles: _emberData.default.hasMany('roles'),
    isAdmin: Ember.computed('roles', function () {
      return this.get('roles').any(role => role.get('authority') == "ROLE_ADMIN");
    }),
    isSeller: Ember.computed('roles', function () {
      return this.get('roles').any(role => role.get('authority') == "ROLE_SELLER");
    }),
    isAuthorized: true
  });

  _exports.default = _default;
});