define("stock-control-front/components/sales-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    actions: {
      selectProductToSell: function (sale, productId) {
        let product = this.get('products').filterBy('id', productId).get('firstObject');
        sale.get('productToSell').set('product', product);
        sale.set('unit', product.get('unit'));
        product.set('existences', []);
        this.get('store').query('product-existence', {
          filter: {
            product: product.get('id')
          }
        }).then(existences => {
          let unitIds = [];
          existences.forEach(existence => {
            product.get('existences').pushObject(existence);
            unitIds.push(parseInt(existence.get('unit').get('id')));
          });
          let saleUnits = this.get('units').filter(unit => unitIds.indexOf(parseInt(unit.get('id'))) >= 0);
          this.set('saleUnits', saleUnits);
        }, () => {
          product.set('existences', [this.get('store').createRecord('product-existence', {
            existence: 0,
            unit: product.get('unit')
          })]);
          this.set('saleUnits', this.get('units').filterBy('id', product.get('unit').get('id')));
        });
      },
      removeProductToSell: function (sale) {
        sale.get('productToSell').set('product', null);
      },
      selectSaleUnit: function (sale, unitId) {
        if (unitId) {
          let unit = this.get('units').filterBy('id', unitId).get('firstObject');
          let productUnitId = sale.get('productToSell').get('product').get('unit').get('id');

          if (unit.id == productUnitId) {
            let quantity = sale.get('productToSell').get('quantity');
            sale.set('quantity', quantity);
          } else {
            sale.set('quantity', null);
          }

          sale.set('unit', unit);
        } else {
          sale.set('quantity', null);
          sale.set('unit', null);
        }
      },
      setSaleQuantity: function (productToSell, quantity) {
        let sale = productToSell.get('sale');
        let saleUnit = sale.get('unit');
        let product = productToSell.get('product');

        if (product.get('id') && saleUnit.get('id')) {
          if (saleUnit.get('id') === product.get('unit').get('id')) {
            sale.set('quantity', quantity);
          }
        }
      },
      addNewSale: function () {
        this.set('didValidate', false);
        let sale = this.get('store').createRecord('sale', {
          productToSell: this.get('store').createRecord('product-to-sell')
        });
        this.get('sales').addObject(sale);
      },
      removeSale: function (sale) {
        this.get('sales').removeObject(sale);
      }
    }
  });

  _exports.default = _default;
});