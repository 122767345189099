define("stock-control-front/validators/unique-rfc", ["exports", "stock-control-front/validators/valid-rfc"], function (_exports, _validRfc) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _validRfc.default.extend({
    store: Ember.inject.service('store'),
    intl: Ember.inject.service('intl'),

    validate(value, options, model) {
      let isRfc = this._super(...arguments);

      if (isRfc === true) {
        return this.get('store').query(options.queryPath, {
          filter: {
            rfc: value.trim()
          }
        }).then(results => {
          if (results !== undefined && results.length === 0) {
            return true;
          } else {
            let isSameOrganization = results.any(organization => {
              return organization.id === model.id;
            });

            if (isSameOrganization) {
              return true;
            }

            return this.get('intl').t('errors.rfc.unique');
          }
        });
      } else {
        return isRfc;
      }
    }

  });

  _exports.default = _default;
});