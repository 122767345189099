define("stock-control-front/admin/organizations/units/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "ieBAeEb0",
    "block": "{\"symbols\":[],\"statements\":[[4,\"if\",[[25,[\"currentUser\",\"isAuthorized\"]]],null,{\"statements\":[[0,\"  \"],[1,[29,\"page-title\",null,[[\"section\",\"sub-section\"],[[29,\"t\",[\"unit.list.label\"],null],[29,\"t\",[\"unit.all.label\"],null]]]],false],[0,\"\\n\"],[0,\"  \\n  \"],[7,\"div\"],[11,\"class\",\"m-content\"],[9],[0,\"\\n    \"],[1,[29,\"unit-form\",null,[[\"unit\",\"organization\",\"addNew\"],[[25,[\"model\",\"unit\"]],[25,[\"model\",\"organization\"]],[29,\"action\",[[24,0,[]],\"addNew\"],null]]]],false],[0,\"\\n  \\n    \"],[1,[29,\"units-list\",null,[[\"units\",\"deleteUnit\",\"organization\"],[[25,[\"model\",\"units\"]],[29,\"action\",[[24,0,[]],\"deleteUnit\"],null],[25,[\"model\",\"organization\"]]]]],false],[0,\"\\n  \\n    \"],[1,[29,\"outlet\",[\"modal\"],null],false],[0,\"\\n  \"],[10],[0,\"\\n  \"],[1,[23,\"outlet\"],false],[0,\"\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "stock-control-front/admin/organizations/units/template.hbs"
    }
  });

  _exports.default = _default;
});