define("stock-control-front/templates/components/page-footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "z4pBVlgT",
    "block": "{\"symbols\":[],\"statements\":[[7,\"footer\"],[11,\"class\",\"m-grid__item m-footer\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"m-container m-container--fluid m-container--full-height m-page__container\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"m-stack m-stack--flex-tablet-and-mobile m-stack--ver m-stack--desktop\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"m-stack__item m-stack__item--left m-stack__item--middle m-stack__item--last\"],[9],[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\"m-footer__copyright\"],[9],[1,[23,\"currentYear\"],false],[0,\" © MainInventory\"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "stock-control-front/templates/components/page-footer.hbs"
    }
  });

  _exports.default = _default;
});