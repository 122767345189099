define("stock-control-front/serializers/application", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.JSONAPISerializer.extend({
    normalizeQueryResponse(store, primaryModelClass, payload) {
      const result = this._super(...arguments);

      result.meta = result.meta || {};

      if (payload.links) {
        result.meta.pagination = this.createMetaForLink(payload.links);
      }

      return result;
    },

    createMetaForLink(data) {
      let meta = {};
      Object.keys(data).forEach(type => {
        const link = data[type];
        meta[type] = {};
        let params = link.split('?')[1];
        params.split('&').forEach(pairs => {
          const [parameter, value] = pairs.split('=');

          if (parameter === 'page[number]') {
            meta[type].number = parseInt(value);
          }

          if (parameter === 'page[size]') {
            meta[type].size = parseInt(value);
          }
        });
      });
      return meta;
    }

  });

  _exports.default = _default;
});