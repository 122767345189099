define("stock-control-front/admin/organizations/inventory/inventory-valuation/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Controller.extend({
    currentUser: Ember.computed.alias('session.currentUser'),
    actions: {
      findInventoryValuation(params) {
        return this.store.query('product-inventory-valuation', params);
      }

    }
  });

  _exports.default = _default;
});