define("stock-control-front/templates/components/page-title", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "3vN7FrOy",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"m-subheader\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"d-flex align-items-center\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"mr-auto\"],[9],[0,\"\\n      \"],[7,\"h3\"],[11,\"class\",\"m-subheader__title m-subheader__title--separator\"],[9],[1,[23,\"section\"],false],[10],[0,\"\\n      \"],[7,\"ul\"],[11,\"class\",\"m-subheader__breadcrumbs m-nav m-nav--inline\"],[9],[0,\"\\n        \"],[7,\"li\"],[11,\"class\",\"m-nav__item\"],[9],[0,\"\\n          \"],[7,\"div\"],[11,\"class\",\"m-nav__link\"],[9],[0,\"\\n            \"],[7,\"span\"],[11,\"class\",\"m-nav__link-text\"],[9],[1,[23,\"sub-section\"],false],[10],[0,\"\\n          \"],[10],[0,\"  \\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "stock-control-front/templates/components/page-title.hbs"
    }
  });

  _exports.default = _default;
});