define("stock-control-front/templates/components/login-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "d4HDEsPk",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"input-icon\"],[9],[0,\"\\n  \"],[7,\"i\"],[12,\"class\",[23,\"icon-class\"]],[9],[10],[0,\"\\n\\n  \"],[1,[29,\"input\",[[29,\"-input-type\",[[25,[\"type\"]]],null]],[[\"value\",\"type\",\"name\",\"placeholder\",\"class\",\"onchange\",\"autocomplete\"],[[25,[\"value\"]],[25,[\"type\"]],[25,[\"name\"]],[25,[\"placeholder\"]],\"form-control m-input\",[29,\"action\",[[24,0,[]],[29,\"mut\",[[25,[\"value\"]]],null]],[[\"value\"],[\"target.value\"]]],\"current-password\"]]],false],[0,\"\\n\"],[10],[0,\"\\n\\n\"],[7,\"span\"],[11,\"class\",\"help-block\"],[9],[0,\"\\n\"],[4,\"if\",[[25,[\"hasErrors\"]]],null,{\"statements\":[[0,\"    \"],[1,[23,\"_errorMessages\"],false],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "stock-control-front/templates/components/login-input.hbs"
    }
  });

  _exports.default = _default;
});