define("stock-control-front/mixins/click-outside", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Mixin.create({
    onOutsideClick: function () {},
    handleClick: function (event) {
      let element = this.$();
      let target = Ember.$(event.target);

      if (!target.closest(element).length) {
        this.onOutsideClick();
      }
    },
    setOutsideClickListener: Ember.on('didInsertElement', function () {
      let clickHandler = this.get('handleClick').bind(this);
      return Ember.$(document).on('click touchEnd', clickHandler);
    }),
    removeClickListener: Ember.on('willDestroyElement', function () {
      let clickHandler = this.get('handleClick').bind(this);
      return Ember.$(document).off('click touchEnd', clickHandler);
    })
  });

  _exports.default = _default;
});