define("stock-control-front/initializers/reset-scroll", ["exports", "stock-control-front/mixins/reset-scroll"], function (_exports, _resetScroll) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize() {
    Ember.Route.reopen(_resetScroll.default);
  }

  var _default = {
    initialize
  };
  _exports.default = _default;
});