define("stock-control-front/components/client-detail", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    editPressed: false,
    isUpdating: false,
    didValidate: Ember.computed.and('displayValidations', 'isInvalid'),
    displayValidations: false,

    init() {
      this._super(...arguments);

      Ember.defineProperty(this, 'isInvalid', Ember.computed.not('client.validations.isValid').readOnly());
    },

    actions: {
      showOrHideEditForm: function () {
        let buttonPressed = this.get('editPressed');
        this.set('editPressed', !buttonPressed);
      },
      cancelEdition: function () {
        this.get('client').rollbackAttributes();
        this.send('showOrHideEditForm');
      },
      updateClient: function () {
        let client = this.get('client');

        if (client.get('hasDirtyAttributes')) {
          this.set('isUpdating', true);
          client.validate().then(({
            validations
          }) => {
            if (validations.get('isValid')) {
              client.save().then(() => {
                this.set('isUpdating', false);
                this.send('showOrHideEditForm');
              }, () => {
                this.set('isUpdating', false);
              });
            } else {
              this.set('displayValidations', true);
              this.set('isUpdating', false);
            }
          });
        } else {
          this.send('showOrHideEditForm');
        }
      }
    },

    willDestroyElement() {
      this.get('client').rollbackAttributes();
    }

  });

  _exports.default = _default;
});