define("stock-control-front/models/client-balance", ["exports", "ember-data", "stock-control-front/models/client", "ember-cp-validations"], function (_exports, _emberData, _client, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _client.default.extend({
    balance: _emberData.default.attr('number')
  });

  _exports.default = _default;
});