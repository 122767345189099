define("stock-control-front/instance-initializers/session-injection", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.initialize = initialize;
  _exports.default = void 0;

  function initialize(appInstance) {
    appInstance.inject('route', 'session', 'service:session');
    appInstance.inject('controller', 'session', 'service:session');
  }

  var _default = {
    name: 'session-injection',
    after: 'ember-simple-auth',
    initialize
  };
  _exports.default = _default;
});