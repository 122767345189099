define("stock-control-front/models/sale", ["exports", "ember-data", "ember-cp-validations"], function (_exports, _emberData, _emberCpValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Validations = (0, _emberCpValidations.buildValidations)({
    quantity: {
      validators: [(0, _emberCpValidations.validator)('number', {
        allowString: true,
        gt: 0,
        message: function () {
          return '';
        }
      }), (0, _emberCpValidations.validator)('existences-for-sale', {
        dependentKeys: ['model.saleTotalQuantity'],
        debounce: 300
      })]
    },
    unit: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('belongs-to')]
    },
    productToSell: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('belongs-to')]
    },
    saleNote: {
      validators: [(0, _emberCpValidations.validator)('presence', true), (0, _emberCpValidations.validator)('belongs-to')]
    }
  });

  var _default = _emberData.default.Model.extend(Validations, {
    quantity: _emberData.default.attr('number'),
    unit: _emberData.default.belongsTo('unit'),
    saleNote: _emberData.default.belongsTo('sale-note'),
    productToSell: _emberData.default.belongsTo('product-to-sell'),
    productSaleQuantity: Ember.computed.alias('productToSell.quantity'),
    saleProduct: Ember.computed.alias('productToSell.product'),
    productTotalQuantity: Ember.computed('saleNote.sales.@each.{saleProduct,productSaleQuantity}', function () {
      const productId = this.get('saleProduct').get('id');
      let sales = this.get('saleNote').get('sales').filterBy('saleProduct.id', productId);
      let quantity = 0;
      sales.forEach(function (sale) {
        const productQuantity = parseFloat(sale.get('productToSell').get('quantity'));

        if (!isNaN(productQuantity)) {
          quantity += productQuantity;
        }
      });
      return quantity;
    }),
    saleTotalQuantity: Ember.computed('saleNote.sales.@each.{saleProduct,unit,quantity}', function () {
      const productId = this.get('saleProduct').get('id');
      const unitId = this.get('unit').get('id');
      let sales = [];

      if (this.get('saleNote').get('sales') !== undefined) {
        sales = this.get('saleNote').get('sales').filter(sale => {
          return sale.get('saleProduct').get('id') == productId && sale.get('unit').get('id') == unitId;
        });
      }

      let quantity = 0;
      sales.forEach(function (sale) {
        const saleQuantity = parseFloat(sale.get('quantity'));

        if (!isNaN(saleQuantity)) {
          quantity += saleQuantity;
        }
      });
      return quantity;
    })
  });

  _exports.default = _default;
});