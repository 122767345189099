define("stock-control-front/components/client-payment-register", ["exports", "sweetalert"], function (_exports, _sweetalert) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Component.extend({
    startStep: 1,
    currentStep: 1,
    totalSteps: 3,
    progressBarSelector: '.progress-bar',
    disableSaveButton: false,
    intl: Ember.inject.service(),
    reference: null,
    steps: Ember.computed('steps', function () {
      return [{
        step: 1,
        message: this.get('intl').t('client.payment.steps.first.message')
      }, {
        step: 2,
        message: this.get('intl').t('client.payment.steps.second.message')
      }, {
        step: 3,
        message: this.get('intl').t('client.payment.steps.third.message')
      }];
    }),
    pageValidations: Ember.computed(function () {
      return {
        1: () => {
          return this.get('payment').get('validations.attrs.client.isValid');
        },
        2: () => {
          return this.get('payment').get('validations.isValid');
        }
      };
    }),

    didInsertElement() {
      this.send('updateProgressBar');
    },

    actions: {
      changeStep: function (step) {
        if (step != this.get('currentStep')) {
          if (step > this.get('currentStep')) {
            if (this.get('pageValidations')[this.get('currentStep')]()) {
              this.send('goTo', step);
            } else {
              this.set('didValidate', true);
            }
          } else {
            this.send('goTo', step);
          }
        }
      },
      goTo: function (step) {
        this.set('currentStep', step);
        this.set('didValidate', false);
        this.send('updateProgressBar');
      },
      goBack: function () {
        this.send('goTo', this.getPreviousStep());
      },
      goNext: function () {
        if (this.get('pageValidations')[this.get('currentStep')]()) {
          this.send('goTo', this.getNextStep());
        } else {
          this.set('didValidate', true);
        }
      },
      updateProgressBar: function () {
        const width = 100 * (this.get('currentStep') / this.get('totalSteps'));
        Ember.$(this.get('progressBarSelector')).css('width', width + '%');
      },
      selectReference: function (reference) {
        this.set('reference', reference);
        this.get('payment').setProperties({
          'referenceId': reference.get('id'),
          'referenceType': 'SaleNote'
        });
      },
      removeReference: function () {
        this.set('reference', null);
        this.get('payment').setProperties({
          'referenceId': '',
          'referenceType': ''
        });
      },
      savePayment: function () {
        this.set('disableSaveButton', true);
        this.get('payment').save().then(() => {
          (0, _sweetalert.default)("", this.get('intl').t('payment.saved.message'), "success").then(() => {
            this.refresh();
            this.send('goTo', this.get('startStep'));
          });
        }).finally(() => {
          this.send('removeReference');
        });
      }
    },
    getNextStep: function () {
      return this.get('currentStep') + 1;
    },
    getPreviousStep: function () {
      return this.get('currentStep') - 1;
    }
  });

  _exports.default = _default;
});