define("stock-control-front/templates/components/masked-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "1L+bMXmv",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[29,\"validated-input\",null,[[\"model\",\"model-attr\",\"disabledIcons\",\"placeholder\",\"maxlength\",\"class\",\"didValidate\"],[[25,[\"model\"]],[25,[\"model-attr\"]],[25,[\"disabledIcons\"]],[25,[\"placeholder\"]],[25,[\"maxlength\"]],[25,[\"class\"]],[25,[\"didValidate\"]]]]],false],[0,\" \\n\"],[15,1],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "stock-control-front/templates/components/masked-input.hbs"
    }
  });

  _exports.default = _default;
});